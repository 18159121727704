/**
 * @name 사이트목록
 * @description
 */
import {App} from 'template';
import {Panel} from 'components';
import {useSiteListQuery, useSiteListStore, useSystemStore} from 'pages/site-manage/model';
// contents
import {Navigation, Header} from './common';
import {Main} from './contents';

const Index: React.FC = () => {
  const {currentPage} = useSiteListStore((state) => state);
  const {data: listInfo, isLoading} = useSiteListQuery(currentPage, 10);

  return (
    <App>
      {/* 헤더 */}
      <Panel header={<Header />}>
        {/* 필터,검색,네비 */}
        {!isLoading && listInfo && <Navigation {...listInfo} />}
        {/* 메인 */}
        {!isLoading && listInfo && <Main {...listInfo} />}
      </Panel>
    </App>
  );
};
export default Index;
