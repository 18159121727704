/**
 * @name Form
 * @description
 */
import React from 'react';
import {CSS} from 'types';
// contents
import Submit from './submit';
import FormInput from './input';
import FormDropDown from './drop-down';
import FormSearch from './search';

type Props = {
  className?: string
  children?: React.ReactNode
  style?: CSS //----------------------------- 아이콘스타일
};
function Form({style, className, children}: Props) {
  return (
    <div className={`form ${className}`} style={style}>
      {children}
    </div>
  );
}
//*--------------------------------------------------*
Form.Submit = Submit;
Form.Input = FormInput;
Form.DropDown = FormDropDown;
Form.Search = FormSearch;

export default Form;

/********************************************************
[사용법]

*********************************************************/
