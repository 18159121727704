/**
 * @name store
 * @description react-query, zustand
 */
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {create} from 'zustand';
import {API, LIST_MAX} from 'config';
import {fetchData} from 'api';
import {
  useSiteListStoreType,
  SitesResponseType,
  useSiteAddStoreType,
  SitesSiteIdDataType,
  useSystemStoreType,
  ResponseType
} from './type';
import {siteBanksItemProps, siteBanksSiteBankNoProps} from './type.system';

// ListStore
export const useSiteListStore = create<useSiteListStoreType>((set) => ({
  currentPage: 0, // 초기 상태 값 설정
  setCurrentPage: (num: number) => set({currentPage: num})
}));

/**
 * @name 사이트등록
 */
export const useSiteAddStore = create<useSiteAddStoreType>((set) => ({
  // edit,view모드
  editMode: 'VIEW',
  setEditMode: (value: 'EDIT' | 'VIEW') => set({editMode: value}),

  // 사이트정보,사이트설정,계통구성도
  tabSelected: 0,
  setTabSelected: (value: number) => {
    set({tabSelected: value});
    //== 하위페이지초기화
    set({deviceSelected: -1}); //장비,모듈,설치초기화
  },
  //[설비] 사이트디바이스번호
  siteDeviceNo: null,
  setSiteDeviceNo: (value: number | null) => set({siteDeviceNo: value}),

  // 사이트상세보기,편집
  siteId: null,
  setSiteId: (value: string | null) => set({siteId: value}),

  // [장치마스터] 장비,모듈,설비
  deviceSelected: -1,
  setDeviceSelected: (value: number) => {
    set({deviceSelected: value});
    set({itemSelected: null});
    //== 하위페이지초기화
  },

  // [장치마스터] 리스트개별아이템
  itemSelected: null,
  setItemSelected: (value: {} | null) => set({itemSelected: value})
}));

/**
 * @name 계통구성도
 */
export const useSystemStore = create<useSystemStoreType>((set) => ({
  // 뱅크
  bank: [],
  setBank: (value: siteBanksItemProps[] | null) => set({bank: value}),

  // 뱅크index
  selectBank: 0,
  setSelectBank: (value: number) => set({selectBank: value}),

  siteBankNo: null,
  setSiteBankNo: (value: number | null) => set({siteBankNo: value}),

  // 뱅크index
  selectDeviceNo: 0,
  setSelectDeviceNo: (value: number) => set({selectDeviceNo: value})
}));

/**
 * @name react-query
 * @param currentPage
 * @returns
 */
export const useSiteListQuery = (currentPage: number, size: number): UseQueryResult<SitesResponseType> => {
  const _size = size || LIST_MAX;
  return useQuery<SitesResponseType>({
    queryKey: ['sites_list', currentPage],
    queryFn: () => fetchData(`${API}/sites?page=${currentPage}&size=${_size}`, {method: 'GET'})
  });
};

export const useSiteViewQuery = (siteId: string | null): UseQueryResult<SitesSiteIdDataType, Error> => {
  return useQuery<SitesSiteIdDataType, Error>({
    queryKey: ['sites_view', siteId],
    queryFn: async (): Promise<SitesSiteIdDataType> => {
      const response: ResponseType<SitesSiteIdDataType> = await fetchData(`${API}/sites/${siteId}`, {method: 'GET'});
      return response.data;
    },
    // staleTime: 1000 * 5,
    enabled: siteId !== null && siteId !== undefined
  });
};
/**
 * @name 왼쪽차트,오른쪽그리드
 * @returns
 */
//## 계통구성도-뱅크
export const useSiteBanksQuery = () => {
  const {siteId} = useSiteAddStore();
  const {siteBankNo} = useSystemStore();
  return useQuery<siteBanksSiteBankNoProps>({
    queryKey: ['grid_devices'],
    queryFn: async (): Promise<siteBanksSiteBankNoProps> => {
      const res: ResponseType<siteBanksSiteBankNoProps> = await fetchData(
        `${API}/site/banks/${siteBankNo}?siteId=${siteId}`
      );
      return res.data;
    },
    enabled: !!siteBankNo && !!siteId
  });
};
