/**
 * @name 사이트등록>사이트정보
 * @description
 */
import {Events, Flex, Text, debug, useAxios, useContext, useObject, useRoutes} from 'lib';
import {API, MENU} from 'config';
import {Color} from 'styles';
import styled from 'styled-components';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {SitesSiteIdDataType} from 'pages/site-manage/model/type';
import {useSiteAddStore} from 'pages/site-manage/model';
// contents
import {CheckBox, Form, Title, Upload} from './contents';

type Props = {
  data?: SitesSiteIdDataType;
};
const Contents = ({data}: Props): JSX.Element => {
  // hooks
  const {setEditMode} = useSiteAddStore();
  const [error, setError] = useObject({
    status: 'normal',
    message: '',
    cause: []
  });
  const {editMode, siteId, setTabSelected} = useSiteAddStore();
  const {navigate} = useRoutes();
  const [info, setInfo] = useObject(data || {});
  const {form} = useAxios();
  const queryClient = useQueryClient();

  const createSite = async () => {
    const res = await form(`${API}/sites`, info);
    if (res?.success) return res?.data;
    else {
      setError({status: 'error', message: res?.error?.message, cause: res?.error?.cause});
    }
    throw new Error(res?.error?.message);
  };
  const updateSite = async () => {
    const res = await form(`${API}/sites/${siteId}`, info, 'PUT');
    if (res?.success) return res?.data;
    else {
      setError({status: 'error', message: res?.error?.message, cause: res?.error?.cause});
    }
    throw new Error(res?.error?.message);
  };
  const {mutate} = useMutation({
    mutationFn: siteId ? updateSite : createSite,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['sites_list']});
      queryClient.invalidateQueries({queryKey: ['sites_view']});
      navigate(MENU.SITE_LIST);
    },
    onError: (error) => {
      Events.emit('toast', error.message);
    }
  });

  // submit
  const onSubmit = () => {
    mutate();
  };

  return (
    <Content>
      {/*=====================================기본정보 */}
      <Title title="기본정보" />
      <Form
        error={error}
        required
        mode="NOT_KOR"
        title="사이트 ID"
        name="siteId"
        value={data?.siteId}
        placeholder="사이트 ID를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        error={error}
        required
        title="사이트 이름"
        name="name"
        value={data?.name}
        placeholder="사이트 이름을 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        error={error}
        title="수요기업 이름"
        name="demandCompany"
        value={data?.demandCompany}
        placeholder="수요기업 이름을 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        error={error}
        title="사이트 주소"
        name="siteAddress"
        value={data?.siteAddress}
        placeholder="주소를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        title="사이트 주소 위도"
        mode="NUMBER"
        name="siteLatitude"
        value={data?.siteLatitude}
        placeholder="위도를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        title="사이트 주소 경도"
        mode="NUMBER"
        name="siteLongitude"
        value={data?.siteLongitude}
        placeholder="경도를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <CheckBox value={data?.businessType} onChange={({name, value}) => setInfo({[name]: value})} />
      {/*===================================== 담당자정보 */}
      <Title title="담당자 정보" />
      <Form
        title="담당자 이름"
        name="managerName"
        value={data?.managerName}
        placeholder="이름을 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        title="담당자 이메일"
        name="managerEmail"
        value={data?.managerEmail}
        placeholder="이메일을 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Form
        title="담당자 연락처"
        name="managerPhone"
        value={data?.managerPhone}
        placeholder="연락처를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <div style={{marginBottom: 40}} />
      {/*===================================== 대시보드정보 */}
      <Title title="대시보드 정보" />
      <Form
        modifyed={editMode === 'EDIT' ? true : false}
        error={error}
        required
        title="대시보드 로그인 ID"
        name="userId"
        value={data?.userId}
        placeholder="대시보드 계정 ID를 입력하세요."
        onChange={({name, value}) => setInfo({[name]: value})}
      />
      <Upload
        value={data?.ciFile}
        onChange={({name, value}) => {
          setInfo({[name]: value});
        }}
      />
      {/*===================================== 취소,저장 */}
      {/* 편집모드 */}
      {editMode === 'EDIT' ? (
        <Flex className="control">
          <button
            style={{marginRight: 8}}
            className="cancel"
            onClick={() => {
              navigate(MENU.SITE_LIST);
            }}>
            <Text label="취소" color={Color.blue30} />
          </button>
          <button
            style={{marginLeft: 8}}
            className={`submit`}
            onClick={() => {
              onSubmit();
            }}>
            <Text label="저장" color={Color.white} />
          </button>
        </Flex>
      ) : (
        <Flex className="control">
          {/* view모드 */}
          <button
            style={{marginRight: 8}}
            className="cancel"
            onClick={() => {
              setEditMode('EDIT');
              navigate(`/site/view/${siteId}`);
            }}>
            <Text label="편집" color={Color.blue30} />
          </button>
          <button
            style={{marginLeft: 8}}
            className={`submit`}
            onClick={() => {
              setTabSelected(1);
            }}>
            <Text label="사이트 장치 설정" color={Color.white} />
          </button>
        </Flex>
      )}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  width: 640px;
  margin: 32px auto 120px;
  /* control */
  .control {
    margin-top: 40px;
    padding: 40px 0;
    display: flex;
    justify-content: flex-end;
    button {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 8px;
      &.cancel {
        border: 1px solid ${Color.blue30};
      }
      &.submit {
        background-color: ${Color.blue30};
      }
    }
  }
`;
