/**
 * @name Array
 * @description 배열 관련 유틸리티
 */

import {Dayjs} from 'lib/events';

/*========================================================
* 시간을 JSON으로 변환
* {debug(setDateJson(new Date('2023-06-29:15:00:00.000Z')))}
=======================================================*/
export const setDateJson = (date?: Date) => {
  const _date = date || new Date();
  const _year = _date.getFullYear();
  const _month = String(_date.getMonth() + 1).padStart(2, '0');
  const _day = String(_date.getDate()).padStart(2, '0');
  const _week = String(_date.getDay());
  const _hour = String(_date.getHours()).padStart(2, '0');
  const _min = String(_date.getMinutes()).padStart(2, '0');
  const _sec = String(_date.getSeconds()).padStart(2, '0');
  const _time = _date.getTime();

  return {
    date: _date,
    year: _year,
    month: _month,
    day: _day,
    week: _week,
    hour: _hour,
    min: _min,
    sec: _sec,
    timestamp: _time,
  };
};
/**
 *
 * @param 타임존변경
 * @returns
 */
export const setTimezone = (str: string | number | any) => {
  Dayjs.tz.setDefault('America/Toronto');
  return Dayjs.tz(str, 'America/Toronto').format();
};
/**
 *
 * @param 분당하루시간배열만들기(nivo 차트)
 * @returns
 */
export const makeMinuteHourlyArray = () => {
  const startTime = new Date('2023-01-01T15:00:00.000Z'); // 시작 시간 설정
  const hourlyArray = [];

  for (let i = 0; i < 24; i++) {
    const currentHour = new Date(startTime.getTime() + i * 60 * 60 * 1000); // 1시간마다 증가
    for (let j = 0; j < 60; j++) {
      const currentTime = new Date(currentHour.getTime() + j * 60 * 1000); // 1분마다 증가
      hourlyArray.push(currentTime.toISOString());
    }
  }
  return hourlyArray;
};
