/**
 * @name 테이블리스트
 * @description
 */
import {Events, Flex, Form, addComma, useObject} from 'lib';
import React from 'react';
// contents
import {useAppDispatch} from 'model';
import {CancelButton, CapacityUnit, CsvMapFile, DataType, EditButton, SaveButton} from 'pages/common';
import {Store, _action, _event} from '..';

type Props = {
  item: any;
  index: number;
};
export default function Contents({item, index}: Props) {
  // hooks
  const dispatch = useAppDispatch();
  const {mode, selected_id} = Store();
  const originName = item?.name;
  const [info, setInfo] = useObject(item);
  //*--------------------------------------------------*
  const onCancel = () => {
    dispatch(_action.setSelectId(-1));
  };
  //*--------------------------------------------------*
  return (
    <React.Fragment key={`${item}_${index}`}>
      {mode === 'EDIT' && selected_id === item?.idx ? (
        <tr>
          <td>{item?.idx}</td>
          <td>{item?.name}</td>
          <td>
            {/* 국문 */}
            <Form.Input
              maxLength={20}
              name="korName"
              value={info?.korName}
              style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
              onChange={({name, value}) => setInfo({[name]: value})}
            />
          </td>
          <td>
            {/* 데이터타입 */}
            <DataType
              name="dataType"
              onChange={({name, value}) => {
                setInfo({[name]: value});
              }}
            />
          </td>
          {/* 모델번호 */}
          <td>
            <Form.Input
              maxLength={30}
              name="modelId"
              value={info?.modelId}
              style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
              onChange={({name, value}) => {
                // const filteredValue = value.replace(/[^0-9]/g, '');
                setInfo({[name]: value});
              }}
            />
          </td>
          {/* 최대용량 */}
          <td>
            <Flex.Center>
              <Form.Input
                maxLength={10}
                name="maximumCapacity"
                value={info?.maximumCapacity}
                style={{width: 100, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
                onChange={({name, value}) => {
                  const filteredValue = value.replace(/[^0-9]/g, '');
                  setInfo({[name]: filteredValue});
                }}
              />
              <CapacityUnit
                name="maximumCapacityUnit"
                onChange={({name, value}) => {
                  setInfo({[name]: value});
                }}
              />
            </Flex.Center>
          </td>
          {/* 정격용량 */}
          <td>
            <Flex.Center>
              <Form.Input
                maxLength={10}
                name="ratedCapacity"
                value={info?.ratedCapacity}
                style={{width: 100, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
                onChange={({name, value}) => setInfo({[name]: value})}
              />
              <CapacityUnit name="ratedCapacityUnit" onChange={({name, value}) => setInfo({[name]: value})} />
            </Flex.Center>
          </td>
          <td>
            <CsvMapFile info={item} type="map" mode="EDIT" />
          </td>
          <td>
            <CsvMapFile info={item} type="statusMap" mode="EDIT" />
          </td>
          {/* auto */}
          <td></td>
          <td style={{textAlign: 'left'}}>
            <SaveButton
              style={{marginRight: 8}}
              onClick={() => {
                Events.emit(_event.MODIFY_SUBMIT, {
                  ...info,
                  originName: originName,
                  deviceType: 'module',
                  standalone: true,
                  id: item?.deviceId
                });
                onCancel();
              }}
            />
            <CancelButton style={{marginRight: 8}} onClick={() => onCancel()} />
          </td>
        </tr>
      ) : (
        <tr>
          <td>{item?.idx}</td>
          <td>{item?.name}</td>
          <td>{item?.korName}</td>
          <td>{item?.korDataType}</td>
          <td>{item?.modelId}</td>
          <td>
            <span>{item?.maximumCapacity ? addComma(item?.maximumCapacity) : '-'}</span>
            <span>{item?.maximumCapacity ? item?.maximumCapacityUnit : ''}</span>
          </td>
          <td>
            <span>{item?.ratedCapacity ? addComma(item?.ratedCapacity) : '-'}</span>
            <span>{item?.ratedCapacity ? item?.ratedCapacityUnit : ''}</span>
          </td>
          {/* 데이터맵파일 */}
          <td>
            <CsvMapFile info={item} type="map" mode="LIST" />
          </td>
          {/* 상태맵파일 */}
          <td>
            <CsvMapFile info={item} type="statusMap" mode="LIST" />
          </td>
          <td></td>
          <td style={{textAlign: 'left'}}>
            <EditButton onClick={() => dispatch(_action.setSelectId(item?.idx))} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
