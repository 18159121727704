/**
 * @name List
 * @description 리스트 나열
 */
import {debug} from 'lib';
import styled from 'styled-components';
import {COLOR} from 'styles';
import {CSS, RenderItem} from 'types';

type Props = {
  info: any[]
  renderItem?: RenderItem
  style?: CSS
};
export default function Tabs({style, renderItem, info}: Props) {
  // const
  const _style = Object.assign({}, style);
  //*--------------------------------------------------*
  return (
    <Element style={_style}>
      {renderItem === undefined && debug(info)}
      {!!renderItem && info?.map((item, index) => !!renderItem && renderItem({item: item, index: index}))}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLOR.GRAY5};
  background-color: ${COLOR.WHITE};
`;
/********************************************************
[사용법]
 
*********************************************************/
