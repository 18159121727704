import React from 'react';
import {SVGProps} from '../type';

// 화살표다음
export const IconPlus: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ic">
        <path
          id="Path"
          d="M12 5V19"
          stroke={fill || 'white'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M5 12H19"
          stroke={fill || 'white'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
// indent
export const IconIndent: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0V20C1 22.2091 2.79086 24 5 24H19" stroke={fill || '#080808'} />
    </svg>
  );
};

/***
[사용법]
    <IconArrowNext />
    <IconArrowDown fill={Color.red10} />
    <IconArrowPrev fill={Color.blue10} />
    <IconArrowUp />
 */
