/**
 * @name SVG로더
 * @url https://www.npmjs.com/package/external-svg-loader/v/1.6.10
 * @returns
 */
import {CSS} from 'types';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'external-svg-loader';

type Props = {
  src: string;
  width?: string;
  height?: string;
  style?: CSS;
  fill?: string;
};
const SVG = ({src, width, height, fill, style}: Props): JSX.Element => {
  return <svg data-src={src} fill={fill || 'currentColor'} width={width} height={height} style={style} />;
};
export default SVG;

/**
 *
  [사용법1]
    <svg
    data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/heart.svg"
    data-js="enabled"
    onclick="alert('clicked')"
    width="50"
    height="50"
    fill="red">
    </svg>
 */
