/**
 * @name api
 */

import axios, {AxiosRequestConfig} from 'axios';
import {SESSION_STORAGE} from 'lib';

export interface fetchOptionType extends AxiosRequestConfig {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: {};
  params?: {};
}

export const fetchData = async <T>(url: string, option?: fetchOptionType): Promise<T> => {
  const config: AxiosRequestConfig = Object.assign(
    {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      url: url,
      method: option?.method || 'GET',
      timeout: 1000 * 10,
      async: true,
      crossDomain: true,
      withCredentials: false,
      validateStatus: function (status: number) {
        // 400번대 에러도 무시
        return status >= 200 && status < 600;
      }
    },
    option as fetchOptionType
  );
  const token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios<T>(config);
  //*----REST성공
  if (res?.status === 200 || res?.status === 201 || res?.status === 400) {
    return res?.data;
  } else {
    return res?.data;
  }
};
