/**
 * @name 장치마스터
 * @description
 */
import styled from 'styled-components';
import {useSiteAddStore} from 'pages/site-manage/model';
import {Text} from 'lib';
import {Color} from 'styles';

const Contents = (): JSX.Element => {
  const navList = ['장비', '모듈', '설비'];
  const {deviceSelected, setDeviceSelected} = useSiteAddStore();

  return (
    <Content>
      {navList.map((item, index) => (
        <button
          key={index}
          className={`item${index} ${deviceSelected === index ? 'selected' : ''}`}
          onClick={() => {
            setDeviceSelected(index);
          }}>
          <Text mode="Btn_M" label={item} />
        </button>
      ))}
    </Content>
  );
};
export default Contents;

const Content = styled.nav`
  padding: 8px 16px;
  border-bottom: 2px solid ${Color.gray80};
  > button {
    margin-right: 8px;
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid ${Color.white};
    color: ${Color.gray40};
    &.selected {
      color: ${Color.white};
      &.item0 {
        background-color: ${Color.green40};
      }
      &.item1 {
        background-color: ${Color.yellow40};
      }
      &.item2 {
        background-color: ${Color.blue30};
      }
    }
    &:hover {
      border: 1px solid ${Color.gray70};
    }
    &:active {
      border-color: ${Color.blue90};
      background: ${Color.blue90};
    }
  }
`;
