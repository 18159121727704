/**
 * @name 장치마스터>설비
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
export const sliceName = 'facilities'; //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  // 모드
  mode: 'ADD' | 'EDIT' | 'DEFAULT';
  // EDIT모드일때 선택된ID
  selected_id: number;
  // 가장최근ID
  latest_id: number;
  board: {
    page: number;
    data: any;
    meta: any;
  } | null;
  [key: string]: any;
};
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  mode: 'DEFAULT',
  selected_id: -1,
  board: null,
  latest_id: 0
};
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // setBoard
    setMode(state: StateType, action: PayloadAction<'ADD' | 'EDIT' | 'DEFAULT'>) {
      state.mode = action.payload;
    },
    // setSelectId
    setSelectId(state: StateType, action: PayloadAction<number>) {
      state.selected_id = action.payload;
      state.mode = 'EDIT';
    },
    // setBoard
    setBoard(state: StateType, action: PayloadAction<any>) {
      state.board = action.payload;
    },
    // 가장최근ID설정
    setLatestId(state: StateType, action: PayloadAction<any>) {
      state.latest_id = action.payload;
    }
  }
});
//*--------------------------------------------------*
// export
export const EVENT = {
  // submit
  FETCH: 'FACILITIES/FETCH',
  ADD_SUBMIT: 'FACILITIES/ADD/SUBMIT',
  ERROR_SUBMIT: 'FACILITIES/ERROR/SUBMIT',
  MODIFY_SUBMIT: 'FACILITIES/MODIFY/SUBMIT',
  ADD: 'FACILITIES/ADD',
  CANCEL: 'FACILITIES/CANCEL',
  EDIT: 'FACILITIES/EDIT'
};

export default slice.reducer;
export const nameSpace = sliceName;
export const actions = slice.actions;
