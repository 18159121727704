/**
 * @name Content
 * @description
 */
import {useEffect} from 'react';
import {Events, Flex, Table, Text, uuid} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
// contents
import {SiteListItemType, SitesResponseType} from 'pages/site-manage/model/type';
import MainItem from './main-item';

const SiteList = ({data}: SitesResponseType): JSX.Element => {
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Content>
      <Table>
        {/* 테이블헤더 */}
        <Table.Header>
          <tr>
            {/* NO */}
            <th style={{width: 86}}>
              <Text color={Color.gray30} mode="Body_SR" label="No" />
            </th>
            {/* 사이트ID */}
            <th style={{width: 114}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="사이트 ID" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            {/* 사이트이름 */}
            <th style={{width: 114}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="사이트 이름" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            {/* 수요기업이름 */}
            <th style={{width: 114}}>
              <Text color={Color.gray30} mode="Body_SR" label="수요기업 이름" />
            </th>
            {/* 사이트주소 */}
            <th style={{width: 240}}>
              <Text color={Color.gray30} mode="Body_SR" label="사이트 주소" />
            </th>
            {/* 위도/경도 */}
            <th style={{width: 176}}>
              <Text color={Color.gray30} mode="Body_SR" label="위도/경도" />
            </th>
            {/* 사업유형 */}
            <th style={{width: 176}}>
              <Text color={Color.gray30} mode="Body_SR" label="사업 유형" />
            </th>
            {/* 담당자 */}
            <th style={{width: 120}}>
              <Text color={Color.gray30} mode="Body_SR" label="담당자" />
            </th>
            {/* 담당자연락처 */}
            <th style={{width: 176}}>
              <Text color={Color.gray30} mode="Body_SR" label="담당자 연락처" />
            </th>
            {/* 계통구성도 */}
            <th style={{width: 88}}>
              <Text color={Color.gray30} mode="Body_SR" label="계통 구성도" />
            </th>
            {/* dummy */}
            <th style={{width: 'auto'}} />
            {/* 편집 */}
            <th style={{width: 94, textAlign: 'left'}}></th>
          </tr>
        </Table.Header>
        {/* 테이블바디 */}
        <Table.Body>
          {/* 리스트 */}
          {data && data.map((item: any, index: number) => <MainItem key={uuid()} item={item} index={index} />)}
        </Table.Body>
      </Table>
    </Content>
  );
};
export default SiteList;

//*--------------------------------------------------*
const Content = styled.div`
  /* padding-top: 20px; */
  /* padding: 20px; */
  /* border: '1px solid #ccc'; */
  /**-------본문--*/
`;
