/**
 * @name Form>Input
 * @description
 * @toto 추후개발
 */
import React, {useState} from 'react';
import styled from 'styled-components';
import {Color} from 'styles/color';
import {FormProps} from 'types';
import {search_default, search_disabled, search_hover} from 'assets/svg';
// contents
import Input from '../text/input';
import Button from '../button/button';

function FormSearch({onChange, style, onSearch, defaultValue, value, placeholder}: FormProps) {
  // hooks
  const [query, setQuery] = useState<string | null>(defaultValue || '');
  const [isFocus, setFocus] = useState<boolean>(false);
  //*--------------------------------------------------*
  return (
    <React.Fragment>{''}</React.Fragment>
    // <Content style={style} className={isFocus ? 'focus' : ''}>
    //   <Input
    //     style={{height: 38, padding: 0, margin: 0, borderRadius: 0, border: 'none'}}
    //     placeholder={placeholder || '설비 이름을 검색하세요.'}
    //     defaultValue={defaultValue}
    //     value={value}
    //     className="form-input"
    //     onFocus={() => setFocus(true)}
    //     onBlur={() => setFocus(false)}
    //     onSubmit={(str: string) => {
    //       onSearch?.(str)
    //     }}
    //     onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
    //       const name = event.target.name
    //       const value = event.target.value
    //       setQuery(value)
    //       onChange?.({event, name, value})
    //     }}
    //   />
    //   <Button.Image
    //     themes="none"
    //     style={{marginLeft: 5}}
    //     src={isFocus ? search_default : search_disabled}
    //     hover_src={search_hover}
    //     onClick={() => {
    //       onSearch?.(query?.toString() || '')
    //     }}
    //   />
    // </Content>
  );
}
export default FormSearch;
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid ${Color.gray70};
  border-radius: 8px;
  outline: 0;
  align-items: center;
  flex-shrink: 0;
  outline: 0;
  /**-------플레이스홀더-- */
  &.focus {
    border-color: ${Color.blue30};
  }
  &:hover {
    border-color: ${Color.blue30};
  }
  /**-------포커스인-- */
  &:focus {
    border-color: ${Color.blue30};
    outline: 0;
  }
  input {
    color: ${Color.gray30};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
    &::placeholder {
      color: ${Color.gray70};
    }
  }
`;

/********************************************************
[사용법]
  
  const [info, setInfo] = useObject({power: 'default'})

  <Form.Input name="power" placeholder={info?.power} onChange={({name, value}) => setInfo({[name]: value})} />

*********************************************************/
