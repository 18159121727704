/**
 * @name 대시보드
 * @description
 */
import styled from 'styled-components';
// contents
import {Popup} from './common';
import {App} from 'template';
import {Widget} from './contents';

export default function Presenter() {
  return (
    <App>
      <Content>
        <Widget />
        {/* 모달팝업창 */}
        <Popup />
      </Content>
    </App>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /* 본문 */
`;
