/**
 * @name 마크표시(초록색,오른제,파란색)
 * @description
 */
import styled from 'styled-components';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  type: 'device' | 'module' | 'facility' | string;
  style?: CSS;
};
const Contents = ({type, style}: Props): JSX.Element => {
  return <Content style={style} type={type}></Content>;
};
export default Contents;

const getColor = (type: Props['type']): string => {
  switch (type) {
    case 'device':
      return Color.green40;
    case 'module':
      return Color.yellow40;
    case 'facility':
      return Color.blue40;
    default:
      return 'transparent';
  }
};

const Content = styled.div<{type: Props['type']}>`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({type}) => getColor(type)};
`;
