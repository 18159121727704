/**
 * @name 타이틀+(텍스트필드)
 * @description
 */
import styled from 'styled-components';
import {Flex, Form, Image, Text, useContext} from 'lib';
import {Color} from 'styles';
import {useState} from 'react';
import {exclamation_red} from 'assets/svg';
import {useSiteAddStore} from 'pages/site-manage/model';

type Props = {
  mode?: 'NOT_KOR' | 'NUMBER';
  value?: string;
  name: string;
  title?: string;
  required?: boolean;
  modifyed?: boolean;
  placeholder?: string;
  onChange?: (e: any) => void;
  error?: {
    status: string;
    message: string;
    cause: string[];
  };
};
const Contents = ({mode, title, onChange, required, value, modifyed, placeholder, name, error}: Props): JSX.Element => {
  const [_value, setValue] = useState(value);
  const {editMode} = useSiteAddStore();

  return (
    <Content>
      <Text style={{width: 178, marginRight: 16}} label={title || '타이틀'} color={Color.gray50} mode="Title_MB">
        {required && <Text style={{marginLeft: 3}} label={required ? '*' : ''} color={Color.red30} mode="Title_MB" />}
      </Text>
      <div className="component">
        {/* 컴포넌트없을때 기본Input */}
        {editMode === 'EDIT' && modifyed !== false ? (
          <Form.Input
            name={name}
            value={_value}
            placeholder={placeholder}
            onChange={({name, value}) => {
              //==== 한글막기
              if (mode === 'NOT_KOR') {
                const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
                if (regx.test(value)) return;
              }
              // 숫자만 입력 허용
              if (mode === 'NUMBER') {
                const regxNum = /^[0-9.]*$/;
                if (!regxNum.test(value)) return;
              }
              setValue(value);
              onChange && onChange({name, value});
            }}
          />
        ) : (
          <Text mode={'Body_LM'} label={_value} color={Color.gray40} />
        )}
        {/* 에러일때메시지 */}
        {error?.cause && error.cause.includes(name) ? (
          <Flex.Start style={{marginTop: 4, marginBottom: 4}}>
            <Image style={{width: 14, height: 14, marginRight: 6}} src={exclamation_red} />
            <Text style={{whiteSpace: 'pre-wrap'}} mode={'Caption_MB'} label={error?.message} color={Color.red30} />
          </Flex.Start>
        ) : (
          ''
        )}
      </div>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  /* height: 56px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* component */
  .component {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* width: 100%; */
  }
`;
