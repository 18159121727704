/**
 * @name Content
 * @description
 */
import {Events, Flex, Table, Text, uuid} from 'lib';
import {useEffect} from 'react';
import styled from 'styled-components';
import {Color} from 'styles';
// contents
import {useAppDispatch} from 'model';
import {EmptyListAddDevice} from 'pages/common';
import {MainAddItem, MainItem} from '.';
import {Store, _action, _event} from '..';

export default function Contents() {
  // hooks
  const {board, mode} = Store();
  const dispatch = useAppDispatch();

  //*--------------------------------------------------*
  const onCancel = () => {
    dispatch(_action.setMode('DEFAULT'));
  };
  const onAdd = () => {
    dispatch(_action.setSelectId(-1));
    dispatch(_action.setMode('ADD'));
  };
  useEffect(() => {
    Events.addListener(_event.ADD, onAdd);
    Events.addListener(_event.CANCEL, onCancel);
    return () => {
      Events.removeListener(_event.ADD, onAdd);
      Events.removeListener(_event.CANCEL, onCancel);
    };
  }, [board]);
  //*--------------------------------------------------*
  return (
    <Content>
      <Table>
        {/* 테이블헤더 */}
        <Table.Header>
          <tr>
            <th style={{width: 88}}>
              <Text color={Color.gray30} mode="Body_SR" label="No" />
            </th>
            <th style={{width: 200}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="장비ID" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            <th style={{width: 200, maxWidth: 200}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="장비명" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            <th style={{width: 176}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="데이터 타입" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            <th style={{width: 176}}>
              <Text color={Color.gray30} mode="Body_SR" label="모델 번호" />
            </th>
            <th style={{width: 240}}>
              <Text color={Color.gray30} mode="Body_SR" label="최대 용량" />
            </th>
            <th style={{width: 240}}>
              <Text color={Color.gray30} mode="Body_SR" label="정격 용량" />
            </th>
            <th style={{width: 120}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="데이터 맵 파일" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            <th style={{width: 120}}>
              <Text color={Color.gray30} mode="Body_SR" label="상태 맵 파일" />
            </th>
            <th style={{width: 'auto'}} />
            <th style={{width: 94, textAlign: 'left'}}></th>
          </tr>
        </Table.Header>
        {/* 테이블바디 */}
        <Table.Body>
          {/* 추가(add) */}
          {mode === 'ADD' && <MainAddItem />}
          {/* 리스트가없을때 */}
          {!!board && board?.data.length === 0 && (
            <EmptyListAddDevice name="장비" onClick={() => Events.emit(_event.ADD)} />
          )}
          {/* 리스트 */}
          {board?.data.map((item: any, index: number) => <MainItem key={uuid()} item={item} index={index} />)}
        </Table.Body>
      </Table>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
`;
