/**
 * @name Images
 */

/**---------------------------------- @name Common */
export const logo = require('./common/acelo.png');
export const auth_logo = require('./common/auth_logo.png');
export const crocus = require('./common/crocus.png');
export const acelo = require('./common/acelo.png');

/**---------------------------------- @name Icon */
export const map_marker_black = require('./icon/map_marker_black.png');
export const map_marker1 = require('./icon/map_marker1.png');
export const map_marker2 = require('./icon/map_marker.png');
export const small_location = require('./icon/small_location.png');
export const small_phone = require('./icon/small_phone.png');
export const green_checked = require('./icon/green_checked.png');
export const green_checked_on = require('./icon/green_checked_on.png');

/**---------------------------------- @name Pages */
export const sp_link = require('./pages/sp_link.png');
export const sp_logout = require('./pages/sp_logout.png');
export const signin_img = require('./pages/signin_img.png');
