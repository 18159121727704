/**
 * @name Header
 * @description
 */
import {Events, Flex, Text} from 'lib';
import {useAppSelector} from 'model';
import {AddButton} from 'pages/common';
import React from 'react';
import {Color} from 'styles';
import {_event} from '../..';

export default function Contents() {
  // hooks
  const {mode} = useAppSelector((state) => state.facilities);
  //*--------------------------------------------------*
  return (
    <Flex.SpaceBetween
      style={{height: 80, marginTop: 16, padding: '0 24px', borderBottom: `1px solid ${Color.blue100}`}}>
      {/* 타이틀 */}
      <React.Fragment>
        <Text mode="Headline_MB" label="설비 목록" color={Color.gray30} />
        {/* <span style={{fontSize: 10}}>{googleToken + ''}</span> */}
      </React.Fragment>
      {/* 버튼 */}
      <AddButton
        label="설비 추가"
        disabled={mode === 'ADD' ? true : false}
        onClick={() => {
          Events.emit(_event.ADD);
        }}
      />
    </Flex.SpaceBetween>
  );
}
