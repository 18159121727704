/**
 * @name 컬러색상
 * @description
 */

export const Color = {
  transparent: 'transparent',
  /**------------------------------------[BLACK,WHITE] */
  white: '#ffffff',
  black: '#000000',
  /**------------------------------------[gray] */
  gray10: '#080808',
  gray20: '#202020',
  gray30: '#373737',
  gray40: '#5F5F5F',
  gray50: '#8A8A8A',
  gray60: '#B2B2B2',
  gray70: '#D8D8D8',
  gray80: '#E9E9E9',
  gray90: '#F4F4F4',
  /**------------------------------------[blue ] */
  blue10: '#1146CB',
  blue20: '#1850DD',
  blue30: '#2B64F5',
  blue40: '#4073F4',
  blue50: '#6690FB',
  blue60: '#82A5ED',
  blue70: '#BED0F5',
  blue80: '#BED0F5',
  blue90: '#E9F0FE',
  blue100: '#F7FAFF',
  /**------------------------------------[red ] */
  red10: '#ED3A3C',
  red20: '#F44142',
  red30: '#FF4B4D',
  red40: '#FF5B5C',
  red50: '#FF6D6E',
  red60: '#FF8686',
  red70: '#FCC4C4',
  red80: '#FBD6D6',
  red90: '#FFEAEA',
  red100: '#FFF6F6',
  /**------------------------------------[yellow ] */
  yellow10: '#E79921',
  yellow20: '#EBA12F',
  yellow30: '#F1A93A',
  yellow40: '#F6AE3F',
  yellow50: '#FDC061',
  yellow60: '#FFCF86',
  yellow70: '#FBE4C1',
  yellow80: '#FBECD6',
  yellow90: '#FFF3E1',
  yellow100: '#FFFCF6',
  /**------------------------------------[green ] */
  green10: '#13B279',
  green20: '#1EBB82',
  green30: '#2CC28C',
  green40: '#40C997',
  green50: '#6CD5AF',
  green60: '#8DE8CC',
  green70: '#BDF0E4',
  green80: '#CEF8F0',
  green90: '#E2FEF6',
  green100: '#F6FFFC',
};

/*===================================================================[컬러값] */
export const COLOR = {
  TRANSPARENT: 'TRANSPARENT',
  /**------------------------------------[BLACK,WHITE] */
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  /**------------------------------------[BLACK] */
  BLACK1: '#080808',
  BLACK2: '#202020',
  BLACK3: '#373737',
  /**------------------------------------[GRAY] */
  GRAY1: '#5F5F5F',
  GRAY2: '#8A8A8A',
  GRAY3: '#B2B2B2',
  GRAY4: '#D8D8D8',
  GRAY5: '#EAEAEA',
  GRAY6: '#F4F4F4',
  /**------------------------------------[BLUEGRAY] */
  BLUE_GRAY1: '#363849',
  BLUE_GRAY2: '#474A62',
  BLUE_GRAY3: '#525678',
  BLUE_GRAY4: '#677196',
  BLUE_GRAY5: '#7D86A9',
  BLUE_GRAY6: '#959CB8',
  BLUE_GRAY7: '#A9AEC6',
  BLUE_GRAY8: '#CBCEE0',
  BLUE_GRAY9: '#F6FAFF',
  /**------------------------------------[BLUE] */
  BLUE0: '#0F3FB7',
  BLUE1: '#1146CB',
  BLUE2: '#1850DD',
  BLUE3: '#2B64F5',
  BLUE4: '#4073F4',
  BLUE5: '#6690FB',
  BLUE6: '#82A5ED',
  BLUE7: '#BED0F5',
  BLUE8: '#D6E2FB',
  BLUE9: '#E9F0FE',
  /**------------------------------------[YELLOW] */
  YELLOW1: '#FDC061',
  YELLOW2: '#F6AE3F',
  YELLOW3: '#EBA12F',
  /**------------------------------------[RED] */
  RED1: '#FF6D6E',
  RED2: '#FF5B5C',
  RED3: '#F44142',
  RED4: '#F2BDBD',
  RED5: '#FFEAEA',
  /**------------------------------------[GREEN] */
  GREEN1: '#6CD5AF',
  GREEN2: '#40C997',
  GREEN3: '#1EBB82',
};

/*===================================================================[테마설정] */

export const FONT = {
  MAIN: 'Pretendard',
  SUB: 'Spoqa Han Sans Neo',
};
