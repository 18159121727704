/**
 * @name HOME
 * @description 로그인되어있는지 체크이후 대시보드로 이동
 */
import {MENU} from 'config';
import {isEmptyObjectString, useContext, useRoutes} from 'lib';
import {useAppDispatch} from 'model';
import {signIn} from 'pages/common';
import {useEffect} from 'react';

export default function Index() {
  // hooks
  const dispatch = useAppDispatch();
  const {navigate} = useRoutes();
  const {query} = useContext();
  //*--------------------------------------------------*
  // 초기화
  async function onInit() {
    // console.log('process.env.REACT_APP_ENV_MODE : ' + process.env.REACT_APP_ENV_MODE);
    if (!isEmptyObjectString(query)) {
      // 로그아웃
      await sessionStorage.clear();
      const login = await signIn({
        token: query?.token as string,
        dispatch: dispatch,
        callback: async () => {
          console.log(
            `%c${process.env.REACT_APP_ENV_MODE}`,
            `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:pink;color:black;`
          );
          console.log(
            `%cHOME>ROUTES`,
            `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:tomato;color:black;`
          );
        }
      });
      if (login?.success) {
        navigate(MENU.FACILITIES);
        return;
      }
    }
    // 로그인페이지로 이동
    navigate(MENU.SIGN_IN, {state: query});
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit();
    return () => {};
  }, []);

  return <div>HOME</div>;
}
