/**
 * @name 패널(Header,스크롤)
 * @description
 */
import {Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  title?: string;
  style?: CSS;
  children?: React.ReactNode;
};
const Contents = ({title, style, children}: Props): JSX.Element => {
  return (
    <Content style={style}>
      <header>
        <Text mode="Body_LM" color={Color.gray30} label={title} />
      </header>
      {children}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* header */
  header {
    padding: 8px 16px;
    background-color: ${Color.gray90};
  }
`;
