/**
 * @name List
 * @description 리스트 나열
 */
import React from 'react';
import styled from 'styled-components';
import {debug, uuid} from 'lib';
import {ClassName, CSS} from 'types';

type Props = {
  info: any[] | undefined
  row?: boolean // 가로정렬,세로정렬
  renderItem?: (props: {item: any; index: number}) => React.ReactNode // 렌더링할 컴포넌트(리턴값)
  style?: CSS
  itemStyle?: CSS
  containerClassName?: ClassName // 클래스적용
  className?: ClassName // 클래스적용
};
export default function List({style, containerClassName, className, itemStyle, row, renderItem, info}: Props) {
  // const
  const _row: React.CSSProperties = row ? {flexDirection: 'row'} : {flexDirection: 'column'};
  const _style = Object.assign({}, _row, style);
  const _itemStyle = Object.assign({}, itemStyle);
  return (
    <Element style={_style} className={containerClassName}>
      {/* renderItem없을경우디버그 */}
      {renderItem === undefined && debug(info)}
      {!!info &&
        info?.length > 0 &&
        info.map((item, index) => {
          return (
            <li className={className} style={_itemStyle} key={uuid()}>
              {!!renderItem && renderItem({item: item, index: index})}
            </li>
          );
        })}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.ul`
  width: 100%;
`;
/********************************************************
[사용법]
  <List row className="list" info={checkList} renderItem={({item, index}) => <label>{item}</label>} />

*********************************************************/
