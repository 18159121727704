/**
 * @name Debug
 * @description
 */

import {debug} from 'lib';

function Debug({data}: any) {
  return debug(data);
}

export default Debug;
