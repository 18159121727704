/**
 * @name 계통구성도삭제팝업
 * @description 삭제하시겠습니까?
 */
import {Flex, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
};
const Contents = ({onCancel, onSubmit}: Props): JSX.Element => {
  return (
    <Content>
      <div className="wrap">
        <Text
          mode="Headline_SB"
          style={{marginBottom: 16}}
          label="연결을 해지 하시겠습니까??"
          align="center"
          color={Color.gray10}
        />
        <Text align="center" mode="Body_LM" label={`연결을 해지하시면, 하위에 연결된 장치들도 `} color={Color.gray40}>
          <br />
          함께삭제(연결해지) 되어집니다.
        </Text>
        <Flex.Row gap={16} className="submit" alignItems="center" style={{marginTop: 32}}>
          <button onClick={() => onCancel()}>
            <Text mode="Body_LM" label="취소" color={Color.gray30} />
          </button>
          <button onClick={() => onSubmit()} style={{borderColor: Color.red30, background: Color.red30}}>
            <Text mode="Body_LM" label="삭제" color={Color.white} />
          </button>
        </Flex.Row>
      </div>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  position: absolute;
  right: 10%;
  top: 30%;
  z-index: 10;
  transform: translate(-50%, 0);
  /* 레이아웃 */
  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 24px;
    border-radius: 16px;
    background: ${Color.white};
    /* drop_shadow */
    box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
    .submit {
      button {
        width: 170px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid ${Color.gray60};
        background: ${Color.white};
      }
    }
  }
`;
