/**
 * @name 테이블리스트
 * @description
 */
import {Events, Form, useObject} from 'lib';
import {useAppDispatch} from 'model';
import React from 'react';
import {Store, _action, _event} from '..';
// contents
import {CancelButton, EditButton, SaveButton} from 'pages/common';

type Props = {
  item: any;
  index: number;
};
export default function Contents({item, index}: Props) {
  // hooks
  const {mode, selected_id} = Store();
  const dispatch = useAppDispatch();
  const originName = item?.name;
  const [info, setInfo] = useObject(item);
  //*--------------------------------------------------*
  const onCancel = () => {
    dispatch(_action.setSelectId(-1));
  };
  //*--------------------------------------------------*
  return (
    <React.Fragment key={`fe_${item}_${index}`}>
      {mode === 'EDIT' && selected_id === item?.idx ? (
        <tr>
          <td>{item?.idx}</td>
          <td>{item?.name}</td>
          <td>
            <Form.Input
              placeholder="설비명(국문)을 입력하세요."
              name="korName"
              maxLength={20}
              value={info?.korName}
              style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
              onChange={({name, value}) => setInfo({[name]: value})}
            />
          </td>
          <td />
          <td style={{textAlign: 'left'}}>
            <SaveButton
              style={{marginRight: 8}}
              onClick={() => {
                Events.emit(_event.MODIFY_SUBMIT, {
                  ...info,
                  originName: originName,
                  facilityId: item?.facilityId
                });
                onCancel();
              }}
            />
            <CancelButton style={{marginRight: 8}} onClick={() => onCancel()} />
          </td>
        </tr>
      ) : (
        <tr>
          <td>{item?.idx}</td>
          <td>{item?.name}</td>
          <td>{item?.korName}</td>
          <td />
          <td style={{textAlign: 'left'}}>
            <EditButton
              onClick={() => {
                dispatch(_action.setSelectId(item?.idx));
              }}
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
