/**
 * @name Table
 * @description 리스트 나열
 */
import React from 'react';
import {COLOR} from 'styles';
import styled from 'styled-components';
import {uuid} from 'lib';
import {CSS} from 'types';

type Props = {
  info: any
  hover?: string
  bgcolor?: string
  bgcolor_even?: string
  className?: string //-------------------------- 클래스네임
};
type THProps = {
  title?: string
  width?: string
  backgroundColor?: string
  style?: CSS
  align?: 'left' | 'right' | 'center'
};
type TDProps = {
  hover?: CSS
  title?: string
  width?: string
  align?: 'left' | 'right' | 'center'
  style?: CSS
};
export default function TableGrid({info, className, bgcolor, bgcolor_even, hover}: Props) {
  // const
  // const _style = Object.assign({}, style)

  //*--------------------------------------------------*
  return (
    <Content className={className} hover={hover} bgcolor={bgcolor} bgcolor_even={bgcolor_even}>
      {/* 헤더영역 */}
      {!!info?.header?.data && (
        <thead>
          <tr key={uuid()}>
            {info.header.data.map((item: THProps, index: number) => {
              const _config = info?.header?.config;
              return (
                <TH style={_config?.style} width={_config.width[index]} align={_config.align[index]} key={uuid()}>
                  {item.toString()}
                </TH>
              );
            })}
          </tr>
        </thead>
      )}
      {/* 본문내용 */}
      <tbody>
        {info?.body?.data &&
          info.body?.data.map((item: any, index: number) => {
            const _config = info?.body?.config;
            return (
              <tr key={uuid()}>
                {Object.keys(item).map((key: string, index: number) => {
                  const _align = _config.align[index];
                  const _style = Object.assign({}, _config?.style, {textAlign: _align}) as CSS;
                  return (
                    <TD style={_style} key={uuid()}>
                      {item[key]}
                    </TD>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.table<any>`
  width: 100%;
  tr {
    background-color: ${({bgcolor}) => bgcolor || COLOR.WHITE};
    &:nth-child(even) {
      background-color: ${({bgcolor_even}) => bgcolor_even || COLOR.WHITE};
    }
    &:hover {
      background-color: ${({hover}) => hover || 'transparent'};
    }
  }
`;
/* <!--table > th--> */
const TH = styled.th<THProps>`
  width: ${({width}) => width || 'auto'};
  height: 48px;
  background-color: ${COLOR.BLUE8};
  text-align: ${({align}) => align || 'left'};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY8};
  vertical-align: middle;
  color: ${COLOR.BLACK3};
  font-size: 15px;
  line-height: 24px;
`;
/* <!--table > td--> */
const TD = styled.td<TDProps>`
  height: 64px;
  vertical-align: middle;
  border-bottom: 1px solid ${COLOR.GRAY5};
  text-align: ${({align}) => align || 'left'};
`;
/********************************************************
[사용법]
!! hover 사용시 config의 style background는 사용하지않는것을추천

  // hooks
  const info = {
    header: {
      config: {
        style: {height: '48px', padding: '0 10px', backgroundColor: COLOR.BLUE8},
        width: ['50px', '100px', '150px', 'auto', '130px'],
        align: ['left', 'left', 'left', 'center', 'right'],
      },
      data: ['순번', '코드', '이름', '설명', '사용량'],
    },
    body: {
      config: {
        style: {padding: '0 10px'},
        align: ['center', 'left', 'left', 'left', 'right'],
      },
      data: [
        {id: 1, code: 'RX11', name: 'YESHTML5', desc: '설명글1 설명글1', usage: <div style={{color: 'red'}}>test</div>},
        {id: 3, code: 'CTQM', name: 'contents', desc: '내용1', usage: <Button label="컴포넌트" />},
        {id: 2, code: 'RT#$x', name: 'text ', desc: '텍스트텍스트', usage: 'usage1'},
      ],
    },
  } as any

  <TableGrid info={info} className="sample" bgColor={COLOR.WHITE} bgColor_even={COLOR.GRAY6} hover={COLOR.GRAY5} />

// 필요시 스타일 수정
const Content = styled.div`
  table.sample {
    tr {
    }
  }
`
*********************************************************/
