/**
 * @name 저장버튼
 * @description
 */
import {Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  disabled?: boolean;
  style?: CSS;
  onClick?: (payload?: any) => void;
};
export default function Contents({disabled, style, onClick}: Props) {
  return (
    <Content
      className={disabled ? 'disabled' : ''}
      style={style}
      onClick={() => {
        !disabled && onClick?.();
      }}>
      <Text mode="Btn_M" label="저장" />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.button`
  color: ${Color.blue30};

  &:hover {
    color: ${Color.blue40};
  }
  &:active {
    color: ${Color.blue20};
  }
  &.disabled {
    color: ${Color.blue70};
    pointer-events: none;
  }
`;
