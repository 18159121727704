/**
 * @name 취소버튼
 * @description
 */
import {Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  style?: CSS
  onClick?: (payload?: any) => void
};
export default function Contents({style, onClick}: Props) {
  return (
    <Content
      style={style}
      onClick={() => {
        onClick?.();
      }}>
      <Text mode="Btn_M" label="취소" />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.button`
  color: ${Color.gray30};
  &:hover {
    color: ${Color.gray40};
  }
  &:active {
    color: ${Color.gray20};
  }
`;
