/**
 * @name Routes
 */
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {RoutesConfig} from 'config';
import {RoutesType} from 'types';

const Router: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {RoutesConfig.map((item: RoutesType, index: number) => {
          return <Route key={`${item.key}_${index}`} path={item.path} element={item.component} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
