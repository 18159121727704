/**
 * @name Button
 * @description 버튼
 */
import {COLOR} from 'styles/color';
import React from 'react';
import styled from 'styled-components';
import {CSS} from 'types';
import Image from '../image/image';

type Props = {
  params?: {}
  icon?: any // 이미지 아이콘
  iconStyle?: CSS //----------------------------- 아이콘스타일
  className?: string //-------------------------- 클래스네임
  disabled?: boolean //-------------------------- 비활성화
  style?: CSS //--------------------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
};
export default function Button({params, icon, iconStyle, className, disabled, style, onClick}: Props) {
  // hooks
  const _style = Object.assign({}, !!style && style) as React.CSSProperties;
  const _iconStyle = Object.assign({}, {width: 20, height: 20}, !!iconStyle && iconStyle);
  //*--------------------------------------------------*
  return (
    <Element
      className={className}
      disabled={disabled}
      style={_style}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        !!onClick && onClick(Object.assign({}, params, event));
      }}>
      {!!icon && <Image src={icon} style={_iconStyle} />}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.button`
  /* display: inline-block; */
  display: flex;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 8px;
  border-color: ${COLOR.BLUE4};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${COLOR.WHITE};
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -3.5%;
  box-shadow: 1px 1px 3px rgba(56, 65, 74, 0.4);
  background-color: ${COLOR.BLUE4};
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
    /* background-color: ${COLOR.BLUE2}; */
  }
  &:active {
    filter: brightness(0.9);
    /* filter: contrast(150%); */
    /* background-color: ${COLOR.BLUE0}; */
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${COLOR.BLUE_GRAY8};
  }
  label: {
    color: ${COLOR.WHITE};
  }
`;
/********************************************************
[사용법]

  <IconButton
    icon={icon_add_member_white}
    onClick={() => {
      dispatch(_action.setMode(_mode.REGISTER))
    }}
  />
*********************************************************/
