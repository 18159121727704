/**
 * @name useContext
 * @description 글로벌공통으로 사용할수있는 변수,상수,함수를 관리합니다.
 */

import {useAppDispatch, useAppSelector} from 'model';
import {useNavigate} from 'react-router-dom';
import {useLocation, useParams} from 'react-router';
import queryString from 'query-string';

interface MenuItem {
  name: string
  path: string
  children?: MenuItem[]
}

type GnbMenuType = MenuItem[];

interface DepthInfo {
  depth1: number
  depth2: number | null
}

export const useContext = () => {
  // hooks
  const auth = useAppSelector(state => state.auth);
  const googleToken = auth?.googleToken;
  const user = auth?.info || {};
  const login = auth?.login || false;
  const {location, sitemap, navi, menu} = useAppSelector(state => state.gnb);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {state} = useLocation() as any; // navigate(MENU.TEST, {state: payload}) 사용
  const params = useParams() as any; // 라우팅할때 '/:id' 이런식으로 사용
  const query = queryString.parse(window.location.search); // http://192.168.1.45:3000/?intive=code&type=email
  // const
  const env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
  const findDepth = (path: string, menuItems: GnbMenuType): DepthInfo | null => {
    for (let i = 0; i < menuItems.length; i++) {
      const menuItem = menuItems[i];
      // 자식 메뉴가 있는 경우
      if (menuItem.children) {
        for (let j = 0; j < menuItem.children.length; j++) {
          const childItem = menuItem.children[j];
          // 하위 메뉴에서 찾았을 경우
          if (childItem.path === path) return {depth1: i, depth2: j};
        }
      }
      // 자식 메뉴가 없는 경우 (최상위 메뉴)
      if (menuItem.path === path) return {depth1: i, depth2: null};
    }
    return null; // 경로에 해당하는 메뉴 항목을 찾지 못한 경우
  };
  // *-------------------------------------------------------------------*
  return {findDepth, googleToken, query, menu, login, env, navi, navigate, dispatch, state, user, params, sitemap, location};
};
/********************************************************
[사용법]
const {lang}=useContext()
const {state} = useContext()
const {params} = useContext()
const {query} = useContext() // queryString


*********************************************************/
