/**
 * @name 뱅크선택
 * @description
 */
import styled from 'styled-components';
import {useSystemStore} from 'pages/site-manage/model';
import {Text} from 'lib';
import {Color} from 'styles';
import {useEffect} from 'react';

const Contents: React.FC = () => {
  const {bank, setSelectBank, setSiteBankNo} = useSystemStore();

  useEffect(() => {
    // 뱅크가1개일떄
    if (bank?.length === 1) {
      // 뱅크index선택
      setSelectBank(0);
      setSiteBankNo(bank[0]?.siteBankNo);
      //
    }
  }, []);

  return (
    <Content>
      {bank?.map((item, index) => (
        <button
          key={`${item?.name}_${index}`}
          onClick={() => {
            // alert(index);
          }}>
          <Text label={item?.name} mode="Title_MB" color={Color.gray30} />
        </button>
      ))}
    </Content>
  );
};
export default Contents;

const Content = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-bottom: 2px solid ${Color.gray80};
  padding: 0 4px;
`;
