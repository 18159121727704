/**
 * @name Layer
 * @description 레이어팝업(외부영역 클릭시 닫힌다.)
 */
import {useClickOutside} from 'lib';
import {useRef} from 'react';
import styled from 'styled-components';

type Props = {
  visible?: boolean
  setVisible?: (bool: boolean) => void | undefined
  children: React.ReactNode
};
export default function Layer({children, visible = false, setVisible}: Props) {
  //hooks
  const ref = useRef(null);
  // 레이어다른영역 클릭시닫힘
  useClickOutside(ref, () => {
    !!setVisible && setVisible(false);
    // console.log("clicked outside");
  });
  //*--------------------------------------------------*
  return visible ? <Content ref={ref}>{children}</Content> : null;
}
//*--------------------------------------------------*
const Content = styled.article``;
/********************************************************
[사용법]
import { Layer } from "components";

const [visible, setVisible] = React.useState(true)

<Layer visible={isBool} setVisible={setIsBool}>
  <div>내용</div>
</Layer>


*********************************************************/
