/**
 * @name Panel
 * @description
 */
import styled from 'styled-components';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  style?: CSS; //-------------------------------------- 스타일시트
  header?: React.ReactNode; //------------------------- Header
  footer?: React.ReactNode; //------------------------- Footer
  children?: React.ReactNode; //----------------------- Children
  className?: string; //------------------------------- 클래스네임
  margin?: string; //--------------------------------- 여백
  padding?: string; //--------------------------------- 여백
  border?: string; //---------------------------------- 테두리
  borderRadius?: string; //---------------------------- 곡률
  [key: string]: any;
};
export default function Panel({
  children,
  header,
  footer,
  className,
  style,
  border,
  borderRadius,
  margin,
  padding
}: Props) {
  return (
    <Content
      style={style}
      border={border}
      margin={margin}
      padding={padding}
      borderRadius={borderRadius}
      className={className}>
      {/* 헤더영역 */}
      {!!header && <header>{header}</header>}
      {/* 본문영역 */}
      <main className="panel_main">{children}</main>
      {/* 푸터영역 */}
      {!!footer && <footer>{footer}</footer>}
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.section<Props>`
  overflow: hidden;
  flex-direction: column;
  border: ${({border}) => border || '0'};
  border-radius: ${({borderRadius}) => borderRadius || '16px'};
  background: ${Color.white};
  /* 본문영역 */
  > main.panel_main {
    overflow: auto;
    min-height: 100px;
    height: calc(100vh - 96px - 60px - 24px - 48px);
    /* margin: ${({margin}) => margin || '0 24px 0'}; */
    padding: ${({padding}) => padding || '0 24px 16px'};
  }
`;
/********************************************************
[사용법]
<Panel borderRadius={'0px'}
  header={
    <div>
      <h1>Panel 타이틀 TEST</h1>
    </div>
  }>
  내용들
</Panel>


*********************************************************/
