/**
 * @name TableHead
 * @description 버튼
 */
import styled from 'styled-components';
import {TableProps} from 'types';
// contents

function TableHeader({style, border, children, props}: TableProps) {
  return (
    <Element {...props} style={style} border={border}>
      {children || (
        <tr>
          <td>Table Header</td>
        </tr>
      )}
    </Element>
  );
}

export default TableHeader;

//*--------------------------------------------------*
const Element = styled.thead<TableProps>`
  color: ${({color}) => color};
  > tr {
    border: ${({border}) => border};
  }
`;
/********************************************************
[사용법]
  
*********************************************************/
