/**
 * @name Content
 * @description
 */
import styled from 'styled-components';
import {Button, Flex} from 'lib';
import {COLOR} from 'styles';

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex justifyContent="center" alignItems="center">
        <Button
          backgroundcolor={COLOR.RED1}
          style={{margin: 10}}
          label="CANCEL"
          onClick={async () => {
            console.log('CANCEL');
          }}
        />
        <Button
          style={{margin: 10}}
          label="SUBMIT"
          onClick={async () => {
            console.log('SUBMIT');
          }}
        />
        <Button
          backgroundcolor={COLOR.GREEN1}
          style={{margin: 10}}
          label="TEST"
          onClick={async () => {
            console.log('SUBMIT');
          }}
        />
      </Flex>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  background-color: ${COLOR.GRAY6};
  /**-------본문--*/
  /* padding: 20px; */
  /* border-top: 1px dashed ${COLOR.GRAY4}; */
`;
