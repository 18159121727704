/**
 * @name TableBody
 * @description
 */
import styled from 'styled-components';
import {TableProps} from 'types';
// contents

function TableBody({style, hovercolor, children}: TableProps) {
  return <Element hovercolor={hovercolor}>{children}</Element>;
}

export default TableBody;

//*--------------------------------------------------*
const Element = styled.tbody<TableProps>`
  color: ${({color}) => color};
  > tr {
    &:hover {
      /* cursor: pointer; */
      background-color: ${({hovercolor}) => !!hovercolor && hovercolor};
    }
  }
`;
/********************************************************
[사용법]
  
*********************************************************/
