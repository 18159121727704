/**
 * @name Content
 * @description
 */
import {useRef} from 'react';
import {Button, Events, Form, Text, uuid} from 'lib';
import {Color} from 'styles';
// contents
import {Store, _event} from '..';

export default function Contents() {
  // hooks
  const nameRef = useRef(null) as any; // 장비명
  const descRef = useRef(null) as any; // 설명
  const {latest_id} = Store();
  //*--------------------------------------------------*
  return (
    <tr key={uuid()}>
      <td>{latest_id + 1}</td>
      <td>
        <Form.Input
          placeholder="설비명을 입력하세요."
          style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({value}) => {
            nameRef.current = value;
          }}
        />
      </td>
      <td>
        <Form.Input
          placeholder="설명을 입력하세요."
          style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({value}) => {
            descRef.current = value;
          }}
        />
      </td>
      <td></td>
      <td>
        <button
          style={{marginRight: 8}}
          onClick={() => {
            Events.emit(_event.ADD_SUBMIT, {name: nameRef.current, description: descRef.current});
          }}>
          <Text color={Color.blue30} mode="Btn_M" label="저장" />
        </button>
        <button
          style={{marginRight: 8}}
          onClick={() => {
            Events.emit(_event.CANCEL);
          }}>
          <Text color={Color.gray30} mode="Btn_M" label="취소" />
        </button>
      </td>
    </tr>
  );
}
