/**
 * @name Drop-Down
 * @description
 */
import {DropDown} from 'lib';
import styled from 'styled-components';
import {CSS} from 'types';

type DropDownProps = {
  label?: string
  info?: any
  props?: any
  name?: string
  style?: CSS
  selected?: number
  onChange?: (data: any) => void
};

export default function FormDropDown({props, selected, style, info, name, onChange}: DropDownProps) {
  return (
    <Content style={style}>
      <DropDown
        {...props}
        info={info}
        selected={selected}
        onChange={data => {
          onChange?.(Object.assign({}, data, {name: name}));
        }}
      />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.section<DropDownProps>`
  position: relative;

  /* min-width: 140px; */
  /* width: 200px; */
`;
/********************************************************
[사용법]

  <Form.DropDown
    name="gender"
    info={[
      {label: '남자', value: 'male'},
      {label: '여자', value: 'female'},
    ]}
    onChange={({name, value}) => {
      setInfo({[name]: value})
    }}
  />

*********************************************************/
