/**
 * Pages Index
 * ========================================
 * This module exports the components related to different pages within the application.
 * It includes home, dashboard, authentication, device management, site management, and guides.
 */

import * as Page from 'pages';
import {GnbMenuType, MenuType, RoutesType} from 'types';

// 라우터설정
// ----------------------------------------
export const RoutesConfig: RoutesType[] = [
  /** ------------------------------------------------------- @name General */
  {key: 'HOME', path: '/', component: <Page.Home />},
  {key: 'DASHBOARD', path: '/dashboard', component: <Page.Dashboard />},
  /*---AUTH---------*/
  {key: 'SIGN_IN', path: '/auth/sign-in', component: <Page.SignIn />},
  {key: 'SIGN_OUT', path: '/auth/sign-out', component: <Page.SignOut />},
  /*---장치마스터---------*/
  {key: 'FACILITIES', path: '/device-master/facilities', component: <Page.Facilities />},
  {key: 'DEVICES', path: '/device-master/devices', component: <Page.Devices />},
  {key: 'MODULES', path: '/device-master/modules', component: <Page.Modules />},
  /*---사이트관리---------*/
  {key: 'SITE_LIST', path: '/site/list', component: <Page.SiteList />},
  {key: 'SITE_ADD', path: '/site/add', component: <Page.SiteAdd />},
  {key: 'SITE_VIEW', path: `/site/view/:id`, component: <Page.SiteAdd />},
  /*---ETC---------*/
  {key: 'GUIDE', path: '/guide', component: <Page.Guide />}
];

// MENU
// ----------------------------------------
export const MENU = RoutesConfig.reduce((acc: MenuType, current: RoutesType) => {
  acc[current.key] = current.path;
  return acc;
}, {});

// GNB
// ----------------------------------------
export const GNB_MENU: GnbMenuType[] = MENU && [
  {
    name: '장치 마스터',
    path: `${MENU.FACILITIES}`,
    children: [
      {name: '설비 목록', path: MENU.FACILITIES},
      {name: '장비 목록', path: MENU.DEVICES},
      {name: '모듈 목록', path: MENU.MODULES}
    ]
  },
  {
    name: '사이트 관리',
    path: MENU.SITE_LIST,
    children: [
      {name: '사이트 목록', path: MENU.SITE_LIST},
      {name: '사이트 등록', path: MENU.SITE_ADD, visible: false}
    ]
  }
];
