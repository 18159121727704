/**
 * @name Contents
 * @description params.id에 따라 react-query enabled 설정하여 리스트,view페이지분기한다.
 */
import {useEffect} from 'react';
import {useContext} from 'lib';
import {App} from 'template';
import {Panel} from 'components';
import {Header, Navigation} from './common';
import {useSiteAddStore, useSiteViewQuery, useSystemStore} from 'pages/site-manage/model';
// contents
import SiteInfo from './01.site-info';
import DeviceSetting from './02.device-settings';
import SystemDiagram from './03.system-diagram';

const Contents: React.FC = () => {
  const {query, params} = useContext();
  const {tabSelected, setTabSelected, setEditMode, setSiteId} = useSiteAddStore();
  const {setBank, setSiteBankNo} = useSystemStore();
  const {isLoading: viewLoading, data: viewData} = useSiteViewQuery(params?.id);

  // http://localhost:3000/site/add?editMode=EDIT 형태로 올경우 편집모드
  useEffect(() => {
    if (query?.editMode === 'EDIT') setEditMode(query?.editMode);
    else if (query?.editMode === 'VIEW') setEditMode(query?.editMode);
    return () => {};
  }, [query?.editMode]);

  // http://localhost:3000/site/view/TEST?tabSelected=2 형태로 올경우 탭세팅
  useEffect(() => {
    query?.tabSelected ? setTabSelected(Number(query?.tabSelected)) : setTabSelected(0);
    setSiteId(params?.id);
    return () => {
      /**=========[사이트상세,편집종료일때 초기화] */
      setSiteBankNo(null);
      setSiteId(null);
      setBank(null);
    };
  }, [params?.id]);

  const setContents = () => {
    switch (tabSelected) {
      case 0: //------------------------------------- 사이트정보
        return !viewLoading && <SiteInfo data={viewData} />;
      case 1: //------------------------------------- 사이트장치설정
        return <DeviceSetting />;
      case 2: //------------------------------------- 계통구성도
        return <SystemDiagram />;
      default: //------------------------------------ 기본값
        return null;
    }
  };
  return (
    <App>
      {/* 헤더 */}
      <Panel padding="0 0 16px 24px" header={<Header />}>
        {/* 사이트정보,사이트장치설정,계통구성도 */}
        <Navigation />
        {/* 메인 */}
        {setContents()}
      </Panel>
    </App>
  );
};
export default Contents;
