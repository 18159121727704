/**
 * @name Row
 * @description
 */
import React from 'react';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode //-------------------Children
  style?: React.CSSProperties //----------------- StyleSheet
  [key: string]: any
};
export default function Row({children, style, ...props}: Props) {
  return (
    <Element style={style} {...props}>
      {children}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
/********************************************************
[사용법]
  <H1 label="H1 텍스트를 입력합니다." />
  <H1 style={{fontWeight: 'normal', fontSize: 46, color: 'gold'}}>H1 텍스트를 입력합니다.</H1>
*********************************************************/
