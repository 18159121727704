/**
 * @name Pager
 * @description
 */
import {Events, Flex, Image, Text} from 'lib';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  arrow_next,
  arrow_next_disabled,
  arrow_next_hover,
  arrow_prev,
  arrow_prev_disabled,
  arrow_prev_hover
} from 'assets/svg';
// contents
import {Store, _event} from '../../index';

type ArrowProps = 'default' | 'hover' | 'disabled';

export default function Contents() {
  // hooks
  const {board} = Store();
  const [prev, setPrev] = useState<ArrowProps>(board?.meta?.prevPage ? 'default' : 'disabled');
  const [next, setNext] = useState<ArrowProps>(board?.meta?.hasNextPage ? 'default' : 'disabled');
  //*--------------------------------------------------*
  useEffect(() => {
    setPrev(board?.meta?.prevPage ? 'default' : 'disabled');
    setNext(board?.meta?.hasNextPage ? 'default' : 'disabled');
  }, [board?.meta?.prevPage, board?.meta?.hasNextPage]);
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex.Center>
        {/* 페이저 */}
        <Flex.Center style={{height: 40, marginLeft: 4, marginRight: 4}}>
          <Text mode="Btn_M" label={`${board?.meta?.totalDocs}개 중`} style={{marginRight: 12}} />
          <Text mode="Btn_M" label={`${board?.meta?.minIdx} - ${board?.meta?.maxIdx}`} />
        </Flex.Center>
        <Flex.Center>
          {/* 이전 */}
          <button
            onClick={() => {
              if (prev === 'disabled') return;
              Events.emit(_event.FETCH, {page: board?.meta?.prevPage});
            }}
            className="prev"
            onMouseLeave={() => prev !== 'disabled' && setPrev('default')}
            onMouseEnter={() => prev !== 'disabled' && setPrev('hover')}>
            <Image
              src={prev === 'disabled' ? arrow_prev_disabled : prev === 'hover' ? arrow_prev_hover : arrow_prev}
              width={24}
              height={24}
            />
          </button>
          {/* 다음 */}
          <button
            onClick={() => {
              if (next === 'disabled') return;
              Events.emit(_event.FETCH, {page: board?.meta?.nextPage});
            }}
            className="next"
            onMouseLeave={() => next !== 'disabled' && setNext('default')}
            onMouseEnter={() => next !== 'disabled' && setNext('hover')}>
            <Image
              src={next === 'disabled' ? arrow_next_disabled : next === 'hover' ? arrow_next_hover : arrow_next}
              width={24}
              height={24}
            />
          </button>
        </Flex.Center>
      </Flex.Center>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  position: absolute;

  /**-------본문--*/
  button.prev,
  button.next {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
`;
