/**
 * @name Popup
 * @todo
 */
import {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'template';
import {Events} from 'lib';
import {layout} from 'styles';
import {_event, _popup} from '../../index';

export default function Contents() {
  // hooks
  const [visible, setVisible] = useState(false);
  //*--------------------------------------------------*
  // 레이어팝업실행
  const onSetPopup = useCallback((mode: string) => {
    setContents(mode);
    setVisible(true);
  }, []);
  // 레이어팝업닫기
  const onRemovePopup = useCallback(() => {
    setVisible(false);
  }, []);
  // 컨텐츠분기
  const setContents = (mode?: string) => {
    switch (mode) {
      case _popup.REGISTER_SUCCESS: //-----------------------------등록성공
        return (
          <div>
            <span>test</span>
          </div>
        );
    }
  };
  //*--------------------------------------------------*
  useEffect(() => {
    Events.addListener(_event.SET_POPUP, onSetPopup);
    Events.addListener(_event.REMOVE_POPUP, onRemovePopup);
    return () => {
      Events.removeListener(_event.SET_POPUP, onSetPopup);
      Events.removeListener(_event.REMOVE_POPUP, onRemovePopup);
    };
  }, [onSetPopup, onRemovePopup]);
  //*--------------------------------------------------*
  return (
    <Content>
      <Modal visible={visible} setVisible={setVisible} modalClose>
        <div className={layout.modal}>
          <div className={'modal'}>{setContents()}</div>
        </div>
      </Modal>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  .modal {
    position: fixed;
    top: 130px;
    left: 71px;
    width: 100px;
    height: 100px;
    background-color: red;
  }
`;
