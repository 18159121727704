/**
 * @name 그리드아이템>설비
 * @description
 */
import styled from 'styled-components';
import {siteBanksSiteFacilitiesProps} from 'pages/site-manage/model/type.system';
// contents
import TitleList from './item-title-list';
import {useAddFacilitiesMutation, useDeleteFacilitiesMutation} from 'pages/site-manage/hooks/useDeviceQuery';
import {useQueryClient} from '@tanstack/react-query';

type Props = {
  isOpen?: boolean;
  data: siteBanksSiteFacilitiesProps;
  parentNo: number | undefined;
  isChild?: boolean;
};
const Contents = ({data, parentNo, isOpen, isChild}: Props): JSX.Element => {
  const queryClient = useQueryClient();
  // 커스텀 훅 사용
  const {mutate: mutateAddFacilities} = useAddFacilitiesMutation();
  const {mutate: mutateDeleteFacilities} = useDeleteFacilitiesMutation();

  return (
    <Content>
      {/* <span style={{position: 'absolute', left: 30, top: 0, zIndex: 10, fontSize: 14, color: 'blue'}}>
        [{parentNo}]
      </span> */}
      <TitleList
        title={data.name}
        isChild={isChild}
        isOpen={isOpen}
        type={'facility'}
        onClick={(type) => {
          if (type === 'add') {
            mutateAddFacilities({siteFacilityNo: data?.siteFacilityNo, siteDeviceNo: parentNo});
          } else if (type === 'close') {
            mutateDeleteFacilities({siteFacilityNo: data?.siteFacilityNo, siteDeviceNo: parentNo});
          }
          queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_facilities']});
          queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_devices']});
          queryClient.invalidateQueries({queryKey: ['grid_devices']});
        }}
      />
      {/* {debug(data)} */}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  /* height: 48px; */
  /* item */
`;
