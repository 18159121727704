/**
 * @name 설비,장비,모듈 추가버튼
 * @description
 */
import {plus_blue, plus_white} from 'assets/svg';
import {Button, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  disabled?: boolean;
  style?: CSS;
  label?: string;
  onClick?: (payload?: any) => void;
};
export default function Contents({disabled, label, onClick}: Props): JSX.Element {
  return (
    <Content>
      <Button.Image
        src={disabled ? plus_blue : plus_white}
        disabled={disabled}
        width={24}
        height={24}
        onClick={() => {
          !!onClick && onClick();
        }}>
        <Text style={{marginLeft: 4}} mode="Btn_M" label={label || '설비 추가'} />
      </Button.Image>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.span`
  color: ${Color.blue30};

  &:hover {
    color: ${Color.blue40};
  }
  &:active {
    color: ${Color.blue20};
  }
  &.disabled {
    color: ${Color.gray70};
    pointer-events: none;
  }
`;
