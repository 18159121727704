/**
 * @name 타이틀+(체크박스)
 * @description
 */
import styled from 'styled-components';
import {Flex, Text, useContext} from 'lib';
import {Color} from 'styles';
import {useQuery} from '@tanstack/react-query';
import {fetchData} from 'api';
import {API} from 'config';
import {IconCheckbox, IconCheckboxChecked} from 'assets/icon';
import {useState} from 'react';
import {useSiteAddStore} from 'pages/site-manage/model';

type BusinessType = {
  success: boolean;
  error: null;
  data: {key: string; value: string}[];
};
type Props = {
  value?: string[];
  onChange?: (e: any) => void;
};
const Contents = ({value, onChange}: Props): JSX.Element => {
  const {editMode} = useSiteAddStore();

  // query
  const {isLoading, data: businessType} = useQuery<BusinessType>({
    queryKey: ['business-type'],
    queryFn: () => fetchData(`${API}/sites/business-type`)
  });
  // 체크 상태를 관리하는 상태 배열, 초기 상태를 value로 설정하거나 빈 배열로 설정합니다.
  const [checkedKeys, setCheckedKeys] = useState<string[]>(value || []);

  const handleCheckboxChange = (key: string) => {
    setCheckedKeys((prev = []) => {
      const newCheckedKeys = prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key];
      if (onChange) {
        onChange({name: 'businessType', value: newCheckedKeys});
      }
      return newCheckedKeys;
    });
  };
  return (
    <Content>
      <Text style={{width: 178, marginRight: 16}} label={'사업유형'} color={Color.gray50} mode="Title_MB" />
      {/* 체크박스 */}
      {editMode === 'EDIT' ? (
        <div className="component">
          {businessType?.data?.map(({key, value}) => (
            <button className="checkbox" key={key} onClick={() => handleCheckboxChange(key)}>
              {checkedKeys.includes(key) ? <IconCheckboxChecked /> : <IconCheckbox />}
              <span>{value}</span>
            </button>
          ))}
        </div>
      ) : (
        // 텍스트나열
        <Flex style={{width: 446}} gap={15}>
          {checkedKeys.map((value, index) => (
            <Text mode={'Body_LM'} key={`${value}_${index}`} color={Color.gray40}>
              {value}
            </Text>
          ))}
        </Flex>
      )}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  padding: 8px 0;
  /* height: 56px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* component */
  .component {
    display: grid;
    width: 446px;
    grid-template-columns: repeat(3, 1fr); /* 4개의 열, 각 열의 크기는 동일하게 1fr */
    gap: 20px; /* 항목 간의 간격을 20px로 설정 */
    /* width: 100%; */
    button.checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        margin-left: 8px;
      }
    }
  }
`;
