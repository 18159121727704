/**
 * @name 타이틀+(파일업로드)
 * @description
 */
import styled from 'styled-components';
import {addComma, Flex, Image, SVG, Text, useContext} from 'lib';
import {Color} from 'styles';
import {button_file_upload, default_upload} from 'assets/svg';
import {useRef, useState} from 'react';
import {IconClose} from 'assets/icon';
import {useSiteAddStore} from 'pages/site-manage/model';

type Props = {
  value?: {
    filePath: string;
    fileName: string;
    fileSize: string;
  };
  onChange?: (e: any) => void;
};
const Contents = ({value, onChange}: Props): JSX.Element => {
  const {editMode} = useSiteAddStore();
  const [ciFile, setCiFile] = useState<{
    filePath: string;
    fileName: string;
    fileSize: string;
  } | null>(value || null);
  const fileInputRef = useRef(null) as any; //파일 업로드를 위한 input ref 생성

  // 이미지초기화
  const imageInit = () => {
    setCiFile(null);
    onChange && onChange({name: 'imgDelete', value: true});
  };
  // 파일이벤트핸들러
  const handleFileChange = (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    setCiFile({
      filePath: URL.createObjectURL(file),
      fileName: file.name,
      fileSize: file.size
    });
    // setPreviewUrl(URL.createObjectURL(file));
    onChange && onChange({name: 'file', value: file});
  };
  return (
    <Content>
      <Text style={{width: 178, marginRight: 16}} label={'CI 업로드'} color={Color.gray50} mode="Title_MB"></Text>
      {/*======================== ciFile존재할때(이미지존재할때) */}
      {ciFile && (
        <Flex.Row alignItems="center">
          <Image src={ciFile.filePath} style={{width: 80, height: 80}} alt="file_upload" />
          <Text style={{marginLeft: 10}} mode={'Body_MM'} label={ciFile.fileName} color={Color.gray30} />
          <Text
            style={{marginLeft: 10}}
            mode={'Body_MM'}
            label={addComma(ciFile.fileSize) + 'kb'}
            color={Color.gray30}
          />
          {editMode === 'EDIT' && (
            <button
              style={{padding: 10}}
              onClick={() => {
                imageInit();
              }}>
              <IconClose width="24" height="24" fill={Color.gray40} />
            </button>
          )}
        </Flex.Row>
      )}

      {/*======================== 업로드컴포넌트 */}
      {!ciFile && editMode === 'EDIT' ? (
        <div className="component">
          <Image style={{marginBottom: 8}} src={button_file_upload} alt="file_upload" />
          <p style={{marginBottom: 4}}>
            <Text label="파일을 업로드하세요." color={Color.gray40} mode={'Btn_L'} />
          </p>
          <p style={{marginBottom: 8}}>
            <Text
              label="파일형식은 jpg, png만 업로드 가능합니다. 적정 이미지 사이즈 - 72x72 px"
              mode={'Caption_MR'}
              color={Color.gray40}
            />
          </p>
          <button
            className="upload"
            onClick={(event) => {
              event.preventDefault();
              fileInputRef?.current.click();
            }}>
            <Text mode="Body_SM" label="파일 업로드" color={Color.white} />
          </button>
        </div>
      ) : (
        <div>
          {/*======================== viewMode (이미지없을때) */}
          {!ciFile?.filePath && <Image src={default_upload} width={44} height={44} />}
        </div>
      )}
      <input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  padding: 8px 0;
  flex-direction: row;
  align-items: center;
  /* component */
  .component {
    display: flex;
    padding: 30px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    border: 1px dashed ${Color.gray70};
    border-radius: 8px;

    button.upload {
      width: 74px;
      height: 28px;
      border-radius: 4px;
      background-color: ${Color.blue30};
      /* Light/Gray/Gray - SM */
      box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
    }
  }
`;
