/**
 * @name App
 * @description
 */
import {useContext, useRoutes} from 'lib';
import {useAppDispatch} from 'model';
import {useEffect} from 'react';
import styled from 'styled-components';
import {layout} from 'styles';
import {AppType} from 'types';
// components
import {ToastPopup} from 'pages/common';
import Footer from './footer';
import Gnb from './gnb/index';
import Top from './top';
// static
import {signIn} from 'pages/common';
import Content from './static/content';
import Debug from './static/debug';
import {log} from 'console';
import {MENU} from 'config';

function App({children, padding, style}: AppType) {
  // hooks
  const {navigate} = useRoutes();
  const {googleToken} = useContext();
  const dispatch = useAppDispatch();
  //*--------------------------------------------------*
  // refreshLogin
  async function refreshLogin() {
    const login = await signIn({
      dispatch: dispatch,
      callback: async () => {}
    });
    if (!login?.success) {
      navigate(MENU.SIGN_IN);
    }
  }
  // useEffect
  useEffect(() => {
    if (!googleToken) refreshLogin();
  }, [googleToken]);
  //*--------------------------------------------------*
  return (
    <Contents style={style} $padding={padding} className={layout.contents}>
      {/* gnb */}
      <aside className={layout.gnb}>
        <Gnb />
      </aside>
      {/* container */}
      <article className={layout.container}>
        {/* top */}
        <div className={layout.top}>
          <Top />
        </div>
        {/* main */}
        <main className={`children ${layout.children}`}>{children}</main>
        {/* 토스트팝업 */}
        <ToastPopup />
        {/* footer */}
        <footer>
          <Footer />
        </footer>
      </article>
    </Contents>
  );
}
//*--------------------------------------------------*
App.Debug = Debug;
App.Content = Content;
export default App;
//*--------------------------------------------------*
const Contents = styled.main<AppType>`
  /* 컨텐츠 */
  display: flex;
  /* width: calc(100vw); */
  /* overflow-y: hidden; */
  flex-direction: row;
  /* GNB */
  > aside {
  }

  /* article */
  > article > main.children {
    /* overflow-x: scroll; */
    width: calc(100vw - 268px);
    box-sizing: border-box;
    padding: ${({padding}) =>
      typeof padding === 'number' ? `${padding}px` : padding?.replace(/[^0-9\s]/g, '').trim() || '24px 24px 0'};
    /* padding: ${({padding}) => padding || '24px'}; */
    /* height: var(--CONTAINER_HEIGHT); */ /* 패널형태 */
  }
`;
/********************************************************
[사용법]
<App padding={'100px 20px'} padding={0}>
...
  <App.Debug data={{title: 'TEST'}} />
</App>
*********************************************************/
