/**
 * @name Container
 */
import {useEffect} from 'react';
import {Events, useAxios} from 'lib';
import {API, LIST_MAX} from 'config';
import {useAppDispatch} from 'model';
import {_action, _event} from '.';
// contents
import Presenter from './presenter';

type FetchPayloadProps = {
  page?: number;
  size?: number;
};
export default function Container() {
  // hooks
  const dispatch = useAppDispatch();
  const {get, put, post} = useAxios();
  //*--------------------------------------------------*
  async function onInit() {
    Events.emit(_event.CANCEL);
    onFetch({page: 1});
  }
  // 조회
  async function onFetch(payload?: FetchPayloadProps) {
    Events.emit(_event.CANCEL);
    //
    const _payload = Object.assign({}, payload, {size: LIST_MAX});
    const res = await get(`${API}/facilities`, _payload);
    if (res?.success) {
      dispatch(_action.setBoard({page: payload?.page, data: res?.data, meta: res?.meta}));
      // 최근ID저장
      if (payload?.page === 1) dispatch(_action.setLatestId(res?.data[0]?.facilityId));
    }
  }
  // 추가
  async function onAddSubmit(payload?: any) {
    const res = await post(`${API}/facilities`, payload);
    if (res?.success) {
      onInit();
    }
  }
  // 수정
  async function onModifysubmit(payload?: any) {
    const res = await put(`${API}/facilities/${payload?.id}`, payload);
    if (res?.success) {
      onInit();
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit();
    // addListener
    Events.on(_event.FETCH, onFetch);
    Events.on(_event.ADD_SUBMIT, onAddSubmit);
    Events.on(_event.MODIFY_SUBMIT, onModifysubmit);
    return () => {
      // removeListener
      Events.off(_event.FETCH, onFetch);
      Events.off(_event.ADD_SUBMIT, onAddSubmit);
      Events.on(_event.MODIFY_SUBMIT, onModifysubmit);
    };
  }, []);

  return <Presenter />;
}
