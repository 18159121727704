/**
 * @name
 * @description 3x3 이면 row 3개, col 3개 각각 형태
 */
import {useEffect} from 'react';
import styled from 'styled-components';
import {Grid, makeArray, randomColor, RoundPanel, Loop, Flex} from 'lib';
import {Panel} from 'components';
import {Masonry} from 'template';
import {ListRenderItem} from 'types';

// contents

export default function Contents() {
  // hooks
  const widgets = makeArray(6, {title: 'widget'});
  const items = Array(7)
    .fill(0)
    .map((_, i) => ({
      content: `Item ${i + 1}`,
      height: Math.random() * 300 + 50, // Random height between 50 and 150
      backgroundColor: randomColor(),
    }));
  //*--------------------------------------------------*
  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000 * 8);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  //*--------------------------------------------------*
  return (
    <Content>
      <Masonry
        className="masonry"
        columngap={20}
        columncount={3}
        info={items}
        renderItem={({item, index}: ListRenderItem) => {
          return (
            <Panel
              style={{
                marginBottom: 20,
                height: item.height,
                breakInside: 'avoid', // 컨텐츠 짤림방지 필요!!
              }}>
              <Flex.Center>
                <p>준비중</p>
              </Flex.Center>
              {/* {item.content} */}
            </Panel>
          );
        }}
      />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  > .title {
    display: block;
    margin-left: 24px;
    margin-bottom: 16px;
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 600px) {
    .masonry {
      column-count: 1;
    }
  }
  @media all and (min-width: 600px) and (max-width: 1400px) {
    .masonry {
      column-count: 2;
    }
  }
  @media all and (min-width: 1200px) {
  }
`;
