/**
 * @name Presenter
 * @description
 */
import {App} from 'template';
import {Panel} from 'components';
// contents
import {Navigation, Header} from './common';
import {Main} from './contents';

export default function Presenter() {
  // hooks
  //*--------------------------------------------------*
  return (
    <App>
      {/* 헤더 */}
      <Panel header={<Header />}>
        {/* 필터,검색,네비 */}
        <Navigation />
        {/* 메인 */}
        <Main />
      </Panel>
    </App>
  );
}
