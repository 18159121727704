/**
 * @name Footer
 * @description
 */
import {Form} from 'lib';

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <aside style={{marginRight: 10}}>
      <Form.Search
        style={{width: 300}}
        onSearch={(str: string) => {
          alert(str);
        }}
        onChange={(payload: any) => {}}
      />
    </aside>
  );
}
