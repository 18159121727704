/**
 * @name 기본값
 * @description
 */
import {debug, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents = (): JSX.Element => {
  return (
    <Content>
      <Text mode="Title_MB" label="‘장치마스터’ " color={Color.gray40}>
        <Text mode="Body_LM" label="에서 장치를 선택해 주세요." color={Color.gray50} />
      </Text>
    </Content>
  );
};
export default Contents;

const Content = styled.section`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 350px;
`;
