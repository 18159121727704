/**
 * @name 꺽쇠아이템
 * @description
 */
import styled from 'styled-components';
import {IconIndent} from 'assets/icon';
// contents

// type
type Props = {
  gap?: number;
};

const Contents = ({gap}: Props): JSX.Element => {
  return (
    <Content gap={gap}>
      <IconIndent />
    </Content>
  );
};
export default Contents;

const Content = styled.div<Props>`
  position: absolute;
  top: 0px;
  left: ${({gap}) => (gap || 0) + 'px'};
`;
