/**
 * @name 그리드아이템>장비
 * @description
 */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {siteBanksSiteDeviceProps, siteBanksSiteUnregEquipsProps} from 'pages/site-manage/model/type.system';
// contents
import Module from './module';
import Facility from './facility';
import {useSiteAddStore, useSystemStore} from 'pages/site-manage/model';
import TitleList from './item-title-list';
import {
  useAddDeviceMutation,
  useDeleteDeviceMutation,
  useGridDeviceModule,
  useGridFacilities
} from '../../../../../../hooks/useDeviceQuery';
import {debug, Events} from 'lib';
import {useQueryClient} from '@tanstack/react-query';

type Props = {
  index: number;
  isOpen?: boolean;
  parentNo: number;
  deviceData?: siteBanksSiteDeviceProps;
  unEquipsdata?: siteBanksSiteUnregEquipsProps;
};
const Contents = ({unEquipsdata, parentNo, deviceData, index, isOpen}: Props): JSX.Element => {
  const [_isOpen, setIsOpen] = useState<boolean>(isOpen || false);
  const queryClient = useQueryClient();
  const [mode, setMode] = useState<string>(''); // [추가]모드[설비, 모듈
  const [siteDeviceNo, setSiteDeviceNo] = useState<number>(0); // 사이트장치번호
  const {siteBankNo, selectDeviceNo, setSelectDeviceNo} = useSystemStore(); // 사이트뱅크번호
  // query
  const {data: dataFacilities, refetch: refetchFacilities} = useGridFacilities(siteDeviceNo);
  const {data: dataModules} = useGridDeviceModule();
  // 커스텀 훅 사용
  const {mutate: mutateAddDevice} = useAddDeviceMutation();
  const {mutate: mutateDeleteDevice} = useDeleteDeviceMutation();

  useEffect(() => {
    if (siteDeviceNo !== undefined && siteDeviceNo !== 0) {
      queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_facilities']});
      queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_devices']});
      queryClient.invalidateQueries({queryKey: ['grid_devices']});
      refetchFacilities();
    }
    return () => {};
  }, [siteDeviceNo]);

  return (
    <Content>
      {/* <span style={{position: 'absolute', left: 0, top: 2, zIndex: 10, fontSize: 12, color: 'green'}}>
        [{parentNo + ''}]
      </span> */}
      <TitleList
        title={deviceData?.name || unEquipsdata?.name}
        isOpen={_isOpen}
        type={'device'}
        onClick={(type) => {
          setMode(type);
          if (type === 'add') {
            mutateAddDevice({siteDeviceNo: unEquipsdata?.siteDeviceNo, siteBankNo: siteBankNo});
          } else if (type === 'close') {
            // Events.emit('grid/item/remove', {
            //   type: 'device',
            //   func: () => {
            //     setIsOpen(false);
            //     mutateDeleteDevice({siteDeviceNo: deviceData?.siteDeviceNo});
            //   }
            // });
            mutateDeleteDevice({siteDeviceNo: deviceData?.siteDeviceNo});
          } else if (type === 'facility') {
            setSelectDeviceNo(index);
            deviceData?.siteDeviceNo && setSiteDeviceNo(deviceData?.siteDeviceNo);
            // console.log(siteDeviceNo);
            //## [추가] -> 설비추가
            // deviceData?.siteDeviceNo && setSiteDeviceNo(deviceData?.siteDeviceNo);
          } else if (type === 'module') {
            setSelectDeviceNo(index);
            deviceData?.siteDeviceNo && setSiteDeviceNo(deviceData?.siteDeviceNo);
            // refetchFacilities();
            //## [추가] -> 모듈추가
            // deviceData?.siteDeviceNo && setSiteDeviceNo(deviceData?.siteDeviceNo);
          }
          // queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_facilities']});
          // queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_devices']});
          // queryClient.invalidateQueries({queryKey: ['grid_devices']});
        }}
      />
      {/*====================================================*/}
      {/* 디바이스->모듈 =>설비연결 : children영역 */}
      {deviceData?.children &&
        deviceData?.children.map((item, index) => {
          return (
            <Module isChild={true} parentNo={parentNo} isOpen={isOpen} data={item} key={`${item.name}_${index}`} />
          );
        })}
      {/* 디바이스->설비연결 children영역 */}
      {deviceData?.siteFacilities &&
        deviceData?.siteFacilities.map((item, index) => {
          // if (deviceData?.siteFacilities.some((obj) => obj.name === item.name)) return null;
          return (
            <Facility isChild={true} parentNo={parentNo} isOpen={isOpen} data={item} key={`${item.name}_${index}`} />
          );
        })}
      {/*====== [+추가] 이후모듈 */}
      {dataModules &&
        dataModules?.length > 0 &&
        selectDeviceNo === index &&
        isOpen &&
        mode === 'module' &&
        dataModules?.map((item, index) => {
          //## 비교해서 중복을 제거필요.
          if (deviceData?.children.some((obj) => obj.name === item.name)) return null;
          return <Module parentNo={parentNo} data={item} key={`${item.name}_${index}`} isOpen={false} />;
        })}
      {/*====== [+추가] 이후설비 */}
      {dataFacilities &&
        dataFacilities?.length > 0 &&
        selectDeviceNo === index &&
        isOpen &&
        mode === 'facility' &&
        dataFacilities?.map((item, index) => {
          if (deviceData?.siteFacilities.some((obj) => obj.name === item.name)) return null;
          return (
            <div key={`${item.name}_${index}`}>
              <Facility parentNo={parentNo} data={item} key={`${item.name}_${index}`} isOpen={false} />
            </div>
          );
        })}
      {/*====================================================*/}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;
