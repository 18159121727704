/**
 * @name Header
 * @description
 */
import {back_button} from 'assets/svg';
import {MENU} from 'config';
import {Flex, Image, Text, useContext, useRoutes} from 'lib';
import {useSiteAddStore} from 'pages/site-manage/model';
import {Color} from 'styles';

export default function Contents() {
  // hooks
  const {editMode} = useSiteAddStore();
  const {navigate} = useRoutes();
  //*--------------------------------------------------*
  return (
    <Flex.SpaceBetween
      style={{height: 80, marginTop: 16, padding: '0 24px', borderBottom: `1px solid ${Color.blue100}`}}>
      {/* 타이틀 */}
      <button
        style={{display: 'flex', alignItems: 'center'}}
        onClick={() => {
          navigate(MENU.SITE_LIST);
        }}>
        <Image src={back_button} width={24} height={24} style={{marginRight: 20}} />
        <Text mode="Headline_MB" label={editMode === 'VIEW' ? '사이트 상세정보' : '사이트 등록'} color={Color.gray30} />
      </button>
    </Flex.SpaceBetween>
  );
}
