/**
 * @name Footer
 * @description
 */
import {Flex, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

export default function Contents() {
  return (
    <Content>
      <Flex.End width="100%">
        <Text color={Color.gray70} mode="Caption_MR">
          © 2023 ㈜크로커스 All rights reserved
        </Text>
      </Flex.End>
    </Content>
  );
}
const Content = styled.div`
  // styles
  display: flex;
  width: 100%;
  height: 48px;
  padding-right: 24px;
`;
