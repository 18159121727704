/**
 * @name GNB
 * @description
 */
import {useEffect} from 'react';
import {Color} from 'styles';
import {List, findMenuDepth} from 'lib';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from 'model';
import {gnbAction} from 'model/modules';
// contents
import Logo from './top';
import Logout from './logout';
import Depth1 from './depth1-menu';

export default function Gnb() {
  // hooks
  const dispatch = useAppDispatch();
  const {sitemap} = useAppSelector((state) => state.gnb);
  //*--------------------------------------------------*
  useEffect(() => {
    const result = findMenuDepth();
    dispatch(gnbAction.setNavi(result));
  }, [dispatch]);
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 사이트맵 */}
      <Logo />
      <div className="wrapper">
        <List info={sitemap} renderItem={({item, index}) => <Depth1 item={item} index={index} />} />
      </div>
      {/* 로그아웃 */}
      <Logout />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /*-- 사이트맵 --*/
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  /*-- wrapper --*/
  .wrapper {
    padding: 20px 16px;
  }
`;
