/**
 * @name Const
 * @description
 */

// 세션스토리지
export const SESSION_STORAGE = {
  TOKEN: 'CEBS_GOOGLE_TOKEN',
};

/********************************************************


*********************************************************/
