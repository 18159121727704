/**
 * @name Content
 * @description
 */
import {Events, Form, Image, useObject} from 'lib';
// contents
import {CancelButton, SaveButton} from 'pages/common';
import {Store, _event} from '..';
import {useEffect} from 'react';
import styled from 'styled-components';

export default function Contents() {
  // hooks
  const [info, setInfo] = useObject({});
  const {latest_id} = Store();
  const [error, setError] = useObject({
    status: 'normal',
    message: '',
    cause: []
  });
  const onError = (error: any) => {
    setError(Object.assign({}, error, {status: 'error'}));
  };
  useEffect(() => {
    // addListener
    Events.on(_event.ERROR_SUBMIT, onError);
    return () => {
      // removeListener
      Events.off(_event.ERROR_SUBMIT, onError);
    };
  }, []);

  //*--------------------------------------------------*
  return (
    <Tr className={error?.status === 'error' ? 'error' : ''}>
      <td />
      <td>
        <Form.Input
          status={error?.status}
          error_mesage={error?.message}
          name="name"
          value={info?.name}
          placeholder="설비ID을 입력하세요."
          maxLength={20}
          style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({name, value}) => {
            const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            if (regx.test(value)) {
              setInfo({[name]: info?.name});
              return;
            }
            setInfo({[name]: value});
          }}
        />
      </td>
      <td>
        <Form.Input
          name="korName"
          value={info?.korName}
          placeholder="설비명을 입력하세요."
          maxLength={20}
          style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({name, value}) => setInfo({[name]: value})}
        />
      </td>
      <td></td>
      <td style={{textAlign: 'left'}}>
        <SaveButton
          disabled={!info?.name || !info?.korName}
          style={{marginRight: 8}}
          onClick={() => {
            Events.emit(_event.ADD_SUBMIT, info);
          }}
        />
        <CancelButton
          style={{marginRight: 8}}
          onClick={() => {
            Events.emit(_event.CANCEL);
          }}
        />
      </td>
    </Tr>
  );
}
//*--------------------------------------------------*
const Tr = styled.tr`
  td {
    vertical-align: middle;
  }
  &.error {
    td {
      padding-top: 8px;
      padding-bottom: 8px;
      vertical-align: top;
    }
  }
`;
