/**
 * @name 로그인(이메일,비밀번호)
 * @description
 */
import {Button, Events, Image, Text} from 'lib';
import {Color} from 'styles';
import {Content} from 'template';
import {google_signin, logo_acelo} from 'assets/svg';
// contents
import {_event} from '..';

export default function Contents() {
  return (
    <Content align="center">
      <div
        style={{marginBottom: 40}}
        onClick={() => {
          Events.emit(_event.DASHBOARD);
        }}>
        <Image src={logo_acelo} width={84} height={20} />
      </div>
      <Button.Image
        onClick={() => {
          Events.emit(_event.GOOGLE_SIGN_IN);
        }}
        src={google_signin}
        width={139}
        height={22}
        style={{
          width: 307,
          height: 42,
          margin: `0 auto 40px`,
          border: `1px solid ${Color.gray70}`,
          backgroundColor: Color.white,
          boxShadow: 'none',
        }}
      />
      <p>
        <Text mode="Body_SM" color={Color.gray50}>
          로그인에 문제가 있다면 <a href="mailto:cs@crocusenergy.com">cs@crocusenergy.com</a>으로 문의하세요.
        </Text>
      </p>
    </Content>
  );
}
