/**
 * @name 장치구성요소(장비,모듈,설비)
 * @description
 */
import styled from 'styled-components';
// contents
import Default from './component-default';
import Equipments from './component-equipments';
import Module from './component-module';
import Facilities from './component-facilities';
import {useSiteAddStore} from 'pages/site-manage/model';

const Contents = (): JSX.Element => {
  const {itemSelected, deviceSelected} = useSiteAddStore();

  const setContents = (): JSX.Element => {
    // (장비,모듈,설비) 중에서 개별아이템을 선택해야 컴포넌트가 렌더링됨
    if (!itemSelected) return <Default />;

    switch (deviceSelected) {
      case 0:
        return <Equipments />;
      case 1:
        return <Module />;
      case 2:
        return <Facilities />;
      default:
        return <Default />;
    }
  };
  return (
    <Content>
      {/* <div>{deviceSelected + ''}</div> */}
      {setContents()}
    </Content>
  );
};
export default Contents;

const Content = styled.section`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 24px;
`;
