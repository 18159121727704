/**
 * @name 사이트계통구성도장치
 * @description
 */
import {useState} from 'react';
import styled from 'styled-components';
import {Item, Title} from '.';
import {Color} from 'styles';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {systemEquipmentProps, systemFacilitiesProps} from 'pages/site-manage/model/type.device';
import {API} from 'config';
import {fetchData} from 'api';
import {useSiteAddStore} from 'pages/site-manage/model';
import {debug, Events, useAxios} from 'lib';
// contents
import {Confirm} from './';
type EquipmentProps = {
  success: boolean;
  error: null | {};
  data: systemEquipmentProps[];
};
type FacilitiesProps = {
  success: boolean;
  error: null | {};
  data: systemFacilitiesProps[];
};
const Contents = (): JSX.Element => {
  // hooks
  const [type, setType] = useState<'' | 'equipment' | 'module' | 'facilities'>('');
  const {del} = useAxios();
  const [visible, setVisible] = useState<boolean>(false);
  // const [siteDeviceNo, setSiteDeviceNo] = useState<number>(0);
  const {siteDeviceNo, setSiteDeviceNo} = useSiteAddStore();

  // query
  const queryClient = useQueryClient();
  const {siteId} = useSiteAddStore();
  const {isLoading: equipmentLoading, data: equipmentData} = useQuery<EquipmentProps>({
    queryKey: ['equipment'],
    queryFn: () => fetchData(`${API}/site/devices`, {params: {siteId: siteId, type: 'equipment'}, method: 'GET'})
  });
  const {isLoading: moduleLoading, data: moduleData} = useQuery<EquipmentProps>({
    queryKey: ['module'],
    queryFn: () => fetchData(`${API}/site/devices`, {params: {siteId: siteId, type: 'module'}, method: 'GET'})
  });
  const {isLoading: facilitiesLoading, data: facilitiesData} = useQuery<FacilitiesProps>({
    queryKey: ['facilities'],
    queryFn: () => fetchData(`${API}/site/facilities`, {params: {siteId: siteId}, method: 'GET'})
  });
  const {mutate: devicesMutate} = useMutation({
    mutationFn: async () => {
      const res = await del(`${API}/site/devices/${siteDeviceNo}/?siteId=${siteId}`);
      if (res?.success) return res?.data;
      throw new Error(res?.error?.message);
    },
    onSuccess: () => {
      // 개별로 query를 무효화한다.
      queryClient.invalidateQueries({queryKey: ['equipment']});
      queryClient.invalidateQueries({queryKey: ['module']});
      queryClient.invalidateQueries({queryKey: ['facilities']});
    },
    onError: (error) => {
      Events.emit('toast', error.message);
    }
  });
  const {mutate: facilitiesMutate} = useMutation({
    mutationFn: async () => {
      const res = await del(`${API}/site/facilities/${siteDeviceNo}/?siteId=${siteId}`);
      if (res?.success) return res?.data;
      throw new Error(res?.error?.message);
    },
    onSuccess: () => {
      // 개별로 query를 무효화한다.
      queryClient.invalidateQueries({queryKey: ['equipment']});
      queryClient.invalidateQueries({queryKey: ['module']});
      queryClient.invalidateQueries({queryKey: ['facilities']});
    },
    onError: (error) => {
      Events.emit('toast', error.message);
    }
  });
  return (
    <Content>
      {/* {debug(siteId)} */}
      {/* 장비 */}
      <Title title="장비" color={Color.green40} />
      {!equipmentLoading &&
        equipmentData?.data?.map((item, index) => (
          <Item
            onSelected={() => {
              setType('equipment');
              setSiteDeviceNo(item.siteDeviceNo);
            }}
            onDelete={() => {
              setType('equipment');
              setSiteDeviceNo(item.siteDeviceNo);
              setVisible(true);
            }}
            title={item.name}
            key={`${item.siteDeviceId}_${index}`}
          />
        ))}
      {/* 모듈 */}
      <Title title="모듈" color={Color.yellow40} />
      {!moduleLoading &&
        moduleData?.data?.map((item, index) => (
          <Item
            onSelected={() => {
              setType('module');
              setSiteDeviceNo(item.siteDeviceNo);
            }}
            onDelete={() => {
              setType('module');
              setSiteDeviceNo(item.siteDeviceNo);
              setVisible(true);
            }}
            title={item.name}
            key={`${item.siteDeviceId}_${index}`}
          />
        ))}
      {/* 설비 */}
      <Title title="설비" color={Color.blue40} />
      {!facilitiesLoading &&
        facilitiesData?.data?.map((item, index) => (
          <Item
            onSelected={() => {
              setType('facilities');
              setSiteDeviceNo(item.siteFacilityNo);
            }}
            onDelete={() => {
              setType('facilities');
              setSiteDeviceNo(item.siteFacilityNo);
              setVisible(true);
            }}
            title={item.name}
            key={`${item._id}_${index}`}
          />
        ))}
      {/* 삭제알림팝업 */}
      {visible && (
        <Confirm
          onCancel={() => {
            setVisible(false);
            setType('');
          }}
          onSubmit={() => {
            setVisible(false);
            type === 'facilities' ? facilitiesMutate() : devicesMutate();
          }}
        />
      )}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;
