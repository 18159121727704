/**
 * @name Card
 * @description
 */
import styled from 'styled-components';
import {CSS} from 'types';

type Props = {
  children?: React.ReactNode
  padding?: number //------------------------ 여백
  width?: number //-------------------------- 넓이
  height?: number //-------------------------- 넓이
  style?: CSS //----------------------------- 아이콘스타일
};
export default function Card({children, style, width, height, padding}: Props) {
  const _width = width && {width: width};
  const _height = height && {height: height};
  // ! 여백은 0이 될수있으므로, undefined처리가 필요하다.
  const _padding = padding !== undefined ? {padding: padding} : {padding: 16};
  const _style = Object.assign({}, _width, _height, _padding, !!style && style);
  //*--------------------------------------------------*
  return <Content style={_style}>{children}</Content>;
}
//*--------------------------------------------------*
const Content = styled.div`
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow: hidden;
  /* min-width: 275px; */
`;
/********************************************************
[사용법]
<Card padding={0} style={{width: 100, marginRight: 10, background: randomColor()}}>
    test  
</Card>
<Card width={180} style={{marginRight: 10, background: randomColor()}}>
    test
</Card>
*********************************************************/
