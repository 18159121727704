/**
 * @name GlobalNavigation
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GNB_MENU} from 'config';

export const sliceName = 'gnb'; //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  sitemap: {name: string; path: string}[]
  navi: {
    depth1: number | null
    depth2: number | null
  }
  [key: string]: any
};
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  navi: {depth1: null, depth2: null},
  sitemap: GNB_MENU,
};
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 네비게이션초기화
    setInit(state: StateType) {
      state.navi = Object.assign(state.navi, {depth1: null, depth2: null});
    },
    // 메뉴관리
    setMenu(state: StateType, action: PayloadAction<any>) {
      state.menu = action.payload;
    },
    // 네비게이션변경
    setNavi(state: StateType, action: PayloadAction<any>) {
      state.navi = Object.assign(state.navi, action.payload);
      // 초기화
      // state.location = state.location.slice(0, 1)
    },
  },
});
//*--------------------------------------------------*
// export
export const EVENT = {
  // submit
};
export default slice.reducer;
export const nameSpace = sliceName;
export const actions = slice.actions;
