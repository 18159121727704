/**
 * @name Content
 * @description
 */
import {check_white, close_white} from 'assets/svg';
import {Button, Flex, Image, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

type Props = {
  message?: string;
  onChange?: (payload: any) => void;
};
export default function Contents({message, onChange}: Props) {
  // hooks

  return (
    <Content>
      <Flex.SpaceBetween alignItems="center">
        <Flex.Center>
          <Image src={check_white} width={24} height={24} style={{marginRight: 8}} />
          <Text
            style={{whiteSpace: 'pre-wrap'}}
            mode="Caption_LB"
            color={Color.white}
            label={message || '성공적으로 저장되었습니다!'}
          />
        </Flex.Center>
        <Button.Image
          themes="none"
          src={close_white}
          width={24}
          height={24}
          onClick={() => {
            onChange?.({type: 'close', value: false});
          }}
        />
      </Flex.SpaceBetween>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  padding: 13px 16px;
  min-width: 440px;
  /* height: 48px; */
  border-radius: 8px;
  background-color: ${Color.gray30};
  /**-------본문--*/
`;
