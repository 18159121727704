import {SVGProps} from '../type';

// 체크박스
export const IconCheckbox: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke={fill || '#B2B2B2'} />
    </svg>
  );
};

// 체크박스
export const IconCheckboxChecked: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width={width || '18'} height={height || '18'} rx="4" fill="#4073F4" />
      <path
        d="M4.91699 8.9987L7.83366 11.9154L13.667 6.08203"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
