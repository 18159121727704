/**
 * @name Profile
 * @description
 */
import {Image, useContext} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

export default function Contents() {
  // hooks
  const {user} = useContext();
  //*--------------------------------------------------*
  return (
    <Content>
      <Image src={user?.photo} width={36} height={36} />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${Color.gray70};
  background-color: ${Color.yellow50};
  /**-------텍스트--*/
`;
