/**
 * @name Contents
 * @description
 */
import styled from 'styled-components';
import {Color} from 'styles';
import {fetchData} from 'api';
import {API} from 'config';
import {useQuery} from '@tanstack/react-query';
// contents
import {Panel, Device, System, Component, DeviceTab} from './contents';
import {deviceMasterProps} from 'pages/site-manage/model/type.device';
import {useSiteAddStore} from 'pages/site-manage/model';

const Contents: React.FC = () => {
  // query
  const {deviceSelected} = useSiteAddStore();
  const {isLoading: masterLoading, data: masterData} = useQuery<deviceMasterProps>({
    queryKey: ['devices/master', deviceSelected],
    queryFn: () => fetchData(`${API}/devices/master`, {method: 'GET'})
  });

  return (
    <Content>
      {/* 장치마스터 */}
      <Panel title="장치마스터">
        {/* 장비,모듈,설비 */}
        <DeviceTab />
        {!masterLoading && masterData?.data && <Device data={masterData} />}
      </Panel>
      {/* 장치구성요소 */}
      <Panel
        title="장치 구성 요소"
        style={{width: 400, borderLeft: `1px solid ${Color.gray80}`, borderRight: `1px solid ${Color.gray80}`}}>
        <Component />
      </Panel>
      {/* 사이트계통구성도장치 */}
      <Panel title="사이트 계통 구성도 장치" style={{width: 400}}>
        <System />
      </Panel>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 1200px;
  height: calc(100vh - 350px);
  margin: 0 auto;
  border: 1px solid ${Color.gray80};
`;
