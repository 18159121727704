/**
 * @name Container
 */
import {useEffect} from 'react';
import Presenter from './presenter';

export default function Container() {
  // hooks

  //*--------------------------------------------------*
  async function onInit() {}
  //*--------------------------------------------------*
  useEffect(() => {
    onInit();
    // addListener
    return () => {
      // removeListener
    };
  }, []);

  return <Presenter />;
}
