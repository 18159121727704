/**
 * @name 로그인(비쥬얼)
 * @description
 */
import {signin_img} from 'assets/images';
import {Image} from 'lib';
import {Content} from 'template';

export default function Contents() {
  // hooks

  //*--------------------------------------------------*
  return (
    <Content>
      <Image src={signin_img} width={540} height={540} alt="signin_img" />
    </Content>
  );
}
