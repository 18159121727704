/**
 * @name Content
 * @description
 */
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Events, Flex, Table, Text} from 'lib';
import {Color} from 'styles';
// contents
import {Store, _event} from '..';
import {MainAddItem, MainItem} from '.';

export default function Contents() {
  // hooks
  const [isAdd, setIsAdd] = useState(false);
  const {board} = Store();
  //*--------------------------------------------------*
  const onCancel = () => {
    setIsAdd(false);
  };
  const onAdd = () => {
    setIsAdd(true);
  };
  useEffect(() => {
    Events.addListener(_event.ADD, onAdd);
    Events.addListener(_event.CANCEL, onCancel);
    return () => {
      Events.removeListener(_event.ADD, onAdd);
      Events.removeListener(_event.CANCEL, onCancel);
    };
  }, [board]);
  //*--------------------------------------------------*
  return (
    <Content>
      <Table>
        {/* 테이블헤더 */}
        <Table.Header>
          <tr>
            <th style={{width: 88}}>
              <Text color={Color.gray30} mode="Body_SR" label="No" />
            </th>
            <th style={{width: 400}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="설비명" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            <th style={{width: 400}}>
              <Flex.Center>
                <Text color={Color.gray30} mode="Body_SR" label="설명" />
                <Text color={Color.red30} mode="Body_SR" label="*" />
              </Flex.Center>
            </th>
            <th style={{width: 'auto'}} />
            <th style={{width: 90}}></th>
          </tr>
        </Table.Header>
        {/* 테이블바디 */}
        <Table.Body>
          {/* 추가(add) */}
          {isAdd && <MainAddItem />}
          {board?.data.map((item: any, index: number) => (
            <MainItem key={`${item}_${index}`} item={item} index={index} />
          ))}
        </Table.Body>
      </Table>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
`;
