/**
 * @name Footer
 * @description 페이지네이션
 */
import {Flex, Loop} from 'lib';
import {useState} from 'react';
import styled from 'styled-components';
import {COLOR} from 'styles';

export default function Contents() {
  // hooks
  const [page, setPage] = useState(1);
  const info = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 타이틀 */}
      <Flex>
        {/* 이전 */}
        <button className="first arrow">
          <span>이전</span>
        </button>
        <span className="jump">...</span>
        <Loop
          info={info}
          renderItem={({item, index}) => {
            return (
              <button
                className={`item ${page === index ? 'active' : ''}`}
                onClick={() => {
                  setPage(index);
                }}>
                {item}
              </button>
            );
          }}
        />
        {/* 다음 */}
        <span className="jump">...</span>
        <button className="last arrow">
          <span>다음</span>
        </button>
      </Flex>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  display: flex;
  margin: 20px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /**-------텍스트...--*/
  span.jump {
    display: flex;
    width: 44px;
    height: 40px;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
  }
  /**-------이전,다음--*/
  button.arrow {
    display: flex;
    width: 80px;
    height: 40px;
    margin: 0 2px;
    border-radius: 8px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: ${COLOR.BLACK};
    background-color: ${COLOR.WHITE};
    span {
      display: inline-block;
      margin: 0 4px;
    }
  }
  /**-------item--*/
  button.item {
    display: flex;
    width: 44px;
    height: 40px;
    margin: 0 2px;
    border-radius: 8px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: ${COLOR.BLACK};
    background-color: ${COLOR.WHITE};
    &:hover {
      color: ${COLOR.WHITE};
      background-color: ${COLOR.BLUE3};
    }
    &.active {
      color: ${COLOR.WHITE};
      background-color: ${COLOR.BLUE3};
    }
  }
`;
