/**
 * @name 장비목록
 * @description
 */

import {useAppSelector} from 'model';
import {actions, EVENT} from 'model/modules/devices';
export {default} from './container';
//*--------------------------------------------------*
/**
 * @name config
 * @description Redux Action, Mode, Event, Popup
 */
export const Store = () => useAppSelector(state => state.devices);
export const _action = actions;
export const _event = EVENT;

/********************************************************

*********************************************************/
