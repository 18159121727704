/**
 * @name 토스트팝업
 * @description
 */
import React, {useEffect, useState} from 'react';
import {Events, useToast} from 'lib';
// contents
import Item from './item';

type Props = {
  message?: string
  onChange?: (payload: any) => void
};
export default function Contents({message, onChange}: Props) {
  // hooks
  const [msg, setMsg] = useState<string>(message || '성공적으로 저장되었습니다.');
  const {toast, show, hide} = useToast({
    children: (
      <Item
        message={msg}
        onChange={({type}) => {
          hide();
        }}
      />
    ),
  });

  // 토스트팝업
  const onToast = (msg: string) => {
    setMsg(msg);
    show(msg);
  };
  //*--------------------------------------------------*
  useEffect(() => {
    Events.addListener('toast', onToast);
    return () => {
      Events.removeListener('toast', onToast);
    };
  }, []);
  return toast();
  //*--------------------------------------------------*
}
