/**
 * @name useAxios
 * @description
 */
import axios from 'axios';
import {useState} from 'react';
import {SESSION_STORAGE} from 'lib/const';
import {log} from 'lib/util';

export const useAxios = (_url?: string, _options = {}) => {
  const [result, setResult] = useState<string>(''); // *--------------------- result
  const [loading, setLoading] = useState<boolean | null>(null); // *--------- Loading
  // *---------------- response
  async function response(_config: any) {
    setLoading(true);
    const config = Object.assign(
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        timeout: 1000 * 10,
        async: true,
        crossDomain: true,
        withCredentials: false,
        validateStatus: function (status: any) {
          return status >= 200 && status < 500; // 400번대 에러도 무시
        }
      },
      _config
    );
    // 로그인일때 header에 토큰을 넣지 않는다.
    // if (config?.url.indexOf('auth/login') === -1) {
    //   const token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN)
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    // }
    const token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const res = await axios(config);
    setLoading(false);
    //*----REST성공
    if (res?.status === 200 || res?.status === 201 || res?.status === 400) {
      setResult(res?.data);
      return res?.data;
    } else {
      return res?.data;
    }
  }
  // *---------------- Error 실행
  function errorFunc(error: any) {
    setLoading(false);
    // #개발할때 주석제거필요.
    // errorMsg(error)
  }
  // *-------------------------------------- FETCH
  async function ajax(url?: string | null, params?: {}) {
    const config = {method: 'GET', url: url || _url, ...params};
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- GET
  async function get(url?: string | null, params?: {}) {
    const config = {method: 'GET', url: url || _url, params: Object.assign({}, params)};
    // const config = {method: 'GET', url: url || _url, params: Object.assign(_options, params)}
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- POST
  async function post(url?: string | null, data?: {} | any) {
    const config = {method: 'POST', url: url || _url, data: Object.assign(_options, data)};
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- FORM (multipart/form-data)
  async function form(url?: string | null, data?: {} | any, method: string = 'POST') {
    let config;
    const _form = new FormData() as any;
    for (const [key, value] of Object.entries(data)) {
      const _key: string = key;
      const _value: any = value;
      _form.append(_key, _value);
      // _form.append(_key, _value)
      // _form.append(_key, typeof _value === 'object' ? JSON.stringify(_value) : _value)stringify
    }
    config = {
      method: method || 'POST',
      url: url || _url,
      headers: {
        'Content-Type': 'multipart/form-data'
        // Accept: 'application/json',
      },
      data: _form
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- PUT
  async function put(url?: string | null, data?: {}) {
    const config = {method: 'PUT', url: url || _url, data: Object.assign(_options, data)};
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- PUT
  async function patch(url?: string | null, data?: {}) {
    const config = {method: 'PATCH', url: url || _url, data: Object.assign(_options, data)};
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- DELETE
  async function del(url?: string | null, data?: {}) {
    const config = {method: 'DELETE', url: url || _url, data: Object.assign(_options, data)};
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  //-----------------------------------------------
  return {result, form, ajax, get, post, patch, del, put, loading};
};

/**
 * @description Ajax
 * @param
 */

export const ajax = async (url: string, payload?: any) => {
  const config = Object.assign(
    {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      timeout: 1000 * 10,
      async: true,
      crossDomain: true,
      withCredentials: false,
      method: payload?.method ?? 'GET'
    },
    {data: payload?.data}
  );
  const token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios(url, config);
  //*----REST성공
  if (res?.status === 200 || res?.status === 201) {
    return res?.data;
  }
  //*----REST실패
  else {
    return res?.data;
  }
};
/**
 * @description 에러발생시코드
 * @param error
 */
export const errorMsg = (params: any) => {
  log(`====Error====`, 'padding:5px 10px;font-size:14px;background:#111;color:red;');
  // log(params?.config?.url, 'color: #888;font-size:12px;font-size:10px;')
  log(params?.config?.url, `font-size:10px; color:#888;`);
  log(params?.config);
  // console.group()
  // console.log(params?.config)
  // console.log(params)
  // console.groupEnd()
  // log(params)
};
/********************************************************
[사용법]
  const { get, loading } = useFetch(`${USER}/community/join/board`, { offset: 0 });
  //*------- fetch
  async function fetch() {
    const res = await get()
    const res1 = await get(`${USER}/community/join/board/some`, { offset: 20 })
  }
  // useEffect
  useEffect(() => { fetch(); }, [])
  post, delete, put 일때 success, fail 체크는 
  if (res?.result === 'fail') {  }
  //-----------------------------------------------
  const { loading, ajax } = useAxios(url)
  async function fetch() {
    const res = await ajax(url,
      {
        params: { query: '컴퓨터' },
        headers: {
          'X-Naver-Client-Id': NAVER_CLIENT_ID,
          'X-Naver-Client-Secret': NAVER_CLIENT_SECRET
        }
      }
    )
    setFetch(res)
  }


// axios
export async function 함수이름() {
  const res = await ajax(`${API}/commons/countries`, {})
  return res
  // const res = await ajax(`${API}/countries`, payload)
}

*********************************************************/
