/**
 * @name 계통구성도
 * @description
 */
import {useContext} from 'lib';
import styled from 'styled-components';
import {fetchData} from 'api';
import {API} from 'config';
import {useQuery} from '@tanstack/react-query';
// contents
import {Diagram, Grid} from './contents';
import {useSiteAddStore, useSystemStore} from 'pages/site-manage/model';
import {siteBanksItemProps, siteBanksProps} from 'pages/site-manage/model/type.system';

const Contents: React.FC = () => {
  const {params} = useContext();
  const {setSiteId} = useSiteAddStore();
  const {setBank, siteBankNo, setSiteBankNo} = useSystemStore();
  const {isLoading} = useQuery<Promise<siteBanksItemProps[]>>({
    queryKey: ['sites_banks'],
    queryFn: async () => {
      const res: siteBanksProps = await fetchData(`${API}/site/banks?siteId=${params.id}`, {method: 'GET'});
      if (res?.success) {
        //@todo Bank가 여러개일때구현필요.
        setSiteBankNo(res.data[0].siteBankNo);
        setSiteId(res.data[0].siteId);
        setBank([...res.data]);
        return res.data;
      }
      return [];
    }
  });

  return (
    <Content>
      {/* 다이어그램 */}
      {!isLoading && siteBankNo && <Diagram />}
      {/* 그리드 */}
      {!isLoading && siteBankNo && <Grid />}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  /* width: 1200px; */
  height: calc(100vh - 350px);
  margin: 0 auto;
  /* padding-right: 24px; */
`;
