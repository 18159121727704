/**
 * @name Header
 * @description
 */
import {plus_white} from 'assets/svg';
import {Button, Events, Flex, Text} from 'lib';
import {_event} from '../../index';
import React from 'react';
import {Color} from 'styles';

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <div>
      <Flex.SpaceBetween
        style={{height: 80, marginTop: 16, padding: '0 24px', borderBottom: `1px solid ${Color.blue100}`}}>
        {/* 타이틀 */}
        <React.Fragment>
          <Text mode="Headline_MB" label="장비 목록" color={Color.gray30} />
          {/* <span style={{fontSize: 10}}>{googleToken + ''}</span> */}
        </React.Fragment>
        {/* 버튼 */}
        <Button.Image
          src={plus_white}
          width={24}
          height={24}
          onClick={() => {
            Events.emit(_event.ADD);
          }}>
          <Text style={{marginLeft: 4}} mode="Btn_M" label="장비 추가" />
        </Button.Image>
      </Flex.SpaceBetween>
    </div>
  );
}
