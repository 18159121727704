/**
 * @name 추가/삭제버튼
 * @description
 */
import ReactDOM from 'react-dom';
import {useState} from 'react';
import {Flex, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {IconClose, IconPlus} from 'assets/icon';
import Remove from './item-remove';

// type
export type ModeType = 'device' | 'module' | 'facility' | 'add' | 'close';
type Props = {
  mode?: ModeType;
  onClick: (mode: ModeType) => void;
  isOpen?: boolean;
};

const Contents = ({mode, onClick, isOpen}: Props): JSX.Element => {
  const [isAdd, setIsAdd] = useState(isOpen);
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Content>
      {/* 추가 */}
      {isAdd ? (
        // 삭제,모듈,설비추가
        <Flex.End className="btns">
          <button
            onClick={() => {
              // setIsAdd(false);
              // onClick('close');
              setVisible(true);
            }}>
            <IconClose />
          </button>
          {/* 모듈추가 */}
          {mode === 'device' && (
            <button
              onClick={() => {
                onClick('module');
              }}
              style={{background: Color.yellow40}}>
              <IconPlus />
            </button>
          )}
          {/* facility추가 */}
          {(mode === 'device' || mode === 'module') && (
            <button
              onClick={() => {
                onClick('facility');
              }}
              style={{background: Color.blue40}}>
              <IconPlus />
            </button>
          )}
        </Flex.End>
      ) : (
        // [추가] 버튼
        <button
          onClick={() => {
            onClick('add');
            setIsAdd(true);
          }}>
          <Text mode="Btn_M" label={'추가'} color={Color.blue30} />
        </button>
      )}
      {visible &&
        ReactDOM.createPortal(
          <Remove
            onCancel={() => {
              setVisible(false);
            }}
            onSubmit={() => {
              setIsAdd(false);
              onClick('close');
            }}
          />,
          document.getElementById('modal-root')!
        )}
    </Content>
  );
};
export default Contents;

const Content = styled.nav`
  /* 버튼그룹들 */
  .btns {
    button {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      border-radius: 4px;
    }
  }
`;
