/**
 * @name 네비게이션
 * @description
 */
import {MENU} from 'config';
import {debug, Flex, Text, useContext, useRoutes} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
// contents
import {useSiteAddStore} from 'pages/site-manage/model';
import {useEffect} from 'react';

export default function Contents(): JSX.Element {
  // hooks
  const {editMode, setEditMode, tabSelected, setTabSelected} = useSiteAddStore();
  const {navigate} = useRoutes();
  const {query} = useContext();

  useEffect(() => {
    if (query?.mode === 'view') setEditMode('VIEW');
    if (query?.mode === 'edit') setEditMode('EDIT');
  }, [query?.mode]);
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex.Center className="wrap">
        <button
          className={tabSelected === 0 ? 'on' : ''}
          onClick={() => {
            setTabSelected(0);
            navigate(MENU.SITE_INFO);
          }}>
          <Text mode="Caption_LB" label="사이트 정보" />
        </button>
        <button
          className={`${editMode} ${tabSelected === 1 ? 'on' : ''}`}
          onClick={() => {
            setTabSelected(1);
            navigate(MENU.DEVICE_SETTING);
          }}>
          <Text mode="Caption_LB" label="사이트 장치 설정" />
        </button>
        <button
          className={`${editMode} ${tabSelected === 2 ? 'on' : ''}`}
          onClick={() => {
            setTabSelected(2);
            navigate(MENU.SYSTEM_DIAGRAM);
          }}>
          <Text mode="Caption_LB" label="계통구성도" />
        </button>
      </Flex.Center>
      {/* {debug(editMode)} */}
    </Content>
  );
}
const Content = styled.div`
  .wrap {
    width: 638px;
    margin: 24px auto;
    /* 버튼 */
    button {
      position: relative;
      box-sizing: border-box;
      width: 230px;
      height: 38px;
      color: ${Color.gray40};
      transition: box-shadow 0.2s; /* 부드러운 전환 효과 */
      border-bottom: 1px solid transparent; /* 투명한 테두리를 추가하여 높이 유지 */
      box-shadow: 0 1px 0 0 ${Color.gray60}; /* 기본 상태의 "테두리"를 box-shadow로 구현 */
      &.on {
        color: ${Color.blue30};
        box-shadow: 0 2px 0 0 ${Color.blue30}; /* 활성 상태의 "테두리"를 box-shadow로 구현 */
        /* border-bottom: 2px solid ${Color.blue30}; */
      }
      /* 비활성화처리 */
      &.view {
        color: ${Color.gray60};
      }
    }
  }
`;
