/**
 * @name Table
 * @description 버튼
 */
import styled from 'styled-components';
import {ThemesTable} from 'styles';
import {TableProps} from 'types';
// contents
import TableHeader from './table-header';
import TableFooter from './table-footer';
import TableBody from './table-tbody';

function Table({style, children, ...props}: TableProps) {
  const types = () => {
    let _type;
    if (props?.themes === 'red') _type = ThemesTable.red;
    return `${ThemesTable.defaultTable} ${_type}`;
  };
  return (
    <Element
      className={`${types()}`}
      $color={props?.color}
      $border={props?.border}
      $backgroundcolor={Object.assign({}, props?.theme, props?.backgroundcolor)}
      /// theme 값이 있으면 기본테마 + 커스텀테마
      style={Object.assign({}, props?.theme, style)}
      {...props}>
      {children || 'Tables'}
    </Element>
  );
}

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Footer = TableFooter;

export default Table;

//*--------------------------------------------------*
const Element = styled.table<TableProps>`
  /* width: 100%; */
  table-layout: fixed;
  /* table-layout: auto; */
  /* <!--common--> */
`;
/********************************************************
[사용법]

  const info = [
    {col_0: 1, col_1: '장비', col_2: '알럿 내용 텍스트, 알럿 내용 텍스트 ', col_3: '2023.07.23 / 14:50'},
    {col_0: 2, col_1: '설비', col_2: '모터 01가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
    {col_0: 3, col_1: '모듈', col_2: '모터 02가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
    {col_0: 4, col_1: '에너지효율', col_2: '모터 02가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
  ]
  //*--------------------------------------------------*
  return (
    <Content>
      <Table>
        <Table.Header>
          <tr>
            <th style={{width: 60}}>번호</th>
            <th style={{width: 100}}>알림유형</th>
            <th style={{width: 'auto'}}>내용</th>
            <th style={{width: 200}}>Date/Time</th>
          </tr>
        </Table.Header>
        <Table.Body hovercolor={'#eee'}>
          {info.map((item, index) => {
            return (
              <tr key={`${item}_${index}`}>
                <td className="c">{item?.col_0}</td>
                <td>{item?.col_1}</td>
                <td>{item?.col_2}</td>
                <td>{item?.col_3}</td>
              </tr>
            )
          })}
        </Table.Body>
        <Table.Footer>
          <tr>
            <td colSpan={4}>
              <Flex.SpaceBetween>
                <button>1</button>
                <button>2</button>
                <button>3</button>
              </Flex.SpaceBetween>
            </td>
          </tr>
        </Table.Footer>
      </Table>
*********************************************************/
