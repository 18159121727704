/**
 * @name TableFoot
 * @description 버튼
 */
import styled from 'styled-components';
import {TableProps} from 'types';
// contents

function TableFooter({style, children, border, props}: TableProps) {
  return (
    <Element {...props} style={style} border={border}>
      {children || (
        <tr>
          <td>Table Footer</td>
        </tr>
      )}
    </Element>
  );
}

export default TableFooter;

//*--------------------------------------------------*
const Element = styled.tfoot<TableProps>`
  color: ${({color}) => color};
  > tr {
    border: ${({border}) => border};
  }
`;
/********************************************************
[사용법]
  
*********************************************************/
