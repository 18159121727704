/**
 * @name Logout
 */

import {Color} from 'styles';
import {useState} from 'react';
import Content from 'template/content';
import {Flex, Image, Text, useRoutes} from 'lib';
import {gnb_logout, gnb_logout_hover} from 'assets/svg';
import {MENU} from 'config';

export default function Logout() {
  // hooks
  const {navigate} = useRoutes();
  const [isHover, setIsHover] = useState(false);
  //*--------------------------------------------------*
  return (
    <Content
      style={{
        position: 'absolute',
        left: 16,
        bottom: 40,
      }}>
      <button
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          navigate(MENU.SIGN_OUT);
        }}>
        <Flex.Center>
          <Image src={isHover ? gnb_logout_hover : gnb_logout} width={24} height={24} style={{marginLeft: 8, marginRight: 8}} />
          <Text style={{marginLeft: 8}} mode="Btn_M" label="로그아웃" color={isHover ? Color.gray10 : Color.gray40} />
        </Flex.Center>
      </button>
    </Content>
  );
}
