/**
 * @name Form>Input
 * @description
 */

import {exclamation_red} from 'assets/svg';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Color} from 'styles/color';
import {FormProps} from 'types';
import Image from '../image/image';
import Flex from '../layout/flex';
import Text from '../text/text';
// contents

function FormInput({only, status, maxLength, defaultValue, value, placeholder, ...props}: FormProps) {
  // hooks
  const [isFocused, setIsFocused] = useState(false);
  //*--------------------------------------------------*
  return (
    <span style={{width: '100%'}}>
      <Input
        {...props}
        status={status}
        maxLength={maxLength}
        defaultValue={defaultValue}
        value={value || ''}
        placeholder={isFocused ? '' : placeholder}
        className="form-input"
        onFocus={() => setIsFocused(true)} // get focused
        onBlur={() => setIsFocused(false)} // lose focused
        onChange={(event: any) => {
          // console.log(props?.onChange?.(event));
          // props?.onChange?.(event);
          // !!onChange && onChange(event);
          const {name, value} = event.target;
          props?.onChange?.({event, name, value});
          // !!onChange && onChange({event, name, value});
        }}
      />
      {/* 에러일때메시지 */}
      {status === 'error' ? (
        <Flex.Center style={{marginTop: 4, marginBottom: 4}}>
          <Image style={{width: 14, height: 14, marginRight: 4}} src={exclamation_red} />
          <Text mode={'Caption_MB'} label={props?.error_mesage} color={Color.red30} />
        </Flex.Center>
      ) : (
        ''
      )}
    </span>
  );
}
export default FormInput;
//*--------------------------------------------------*
const Input = styled.input<FormProps>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid ${(props) => (props?.status === 'error' ? Color.red30 : Color.gray70)}; // Change border color based on iserror prop
  border-radius: 8px;
  color: ${Color.gray30};
  outline: 0;
  align-items: center;
  flex-shrink: 0;
  /**-------플레이스홀더-- */
  &::placeholder {
    color: ${Color.gray50};
  }
  &:hover {
    border-color: ${Color.blue30};
  }
  /**-------포커스인-- */
  &:focus {
    border-color: ${(props) => (props?.iserror ? Color.red30 : Color.blue30)};
    outline: 0;
  }
`;

/********************************************************
[사용법]
  
  const [info, setInfo] = useObject({power: 'default'})

  <Form.Input name="power" placeholder={info?.power} onChange={({name, value}) => setInfo({[name]: value})} />

*********************************************************/
