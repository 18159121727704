/**
 * @name Checkbox
 * @description 이미지버튼, hover 시 이미지 변경
 */

import React, {CSSProperties, useEffect, useState} from 'react';
import styled from 'styled-components';
import {green_checked, green_checked_on} from 'assets/images';
import {uuid} from 'lib/util';

type Props = {
  src?: React.ImgHTMLAttributes<HTMLImageElement>['src']
  checked_src?: React.ImgHTMLAttributes<HTMLImageElement>['src']
  checked: boolean //--------------------------- Checked 여부
  width?: string | number //----------------------이미지넓이
  height?: string | number //---------------------이미지높이
  className?: string //-------------------------- 클래스네임
  style?: CSSProperties //----------------------- 스타일시트
  onClick?: (bool: boolean) => void //------------------------ onClick 이벤트
  onChange?: (bool: boolean) => void //------------------------ onClick 이벤트
};
export default function Checkbox({src, checked, checked_src, className, width, height, style, onClick, onChange}: Props) {
  // hooks
  const [isChecked, setIsChecked] = useState(checked); //---- hover 여부 상태 추가
  const _style = Object.assign({}, !!style && style) as CSSProperties;

  useEffect(() => {
    checked !== undefined && setIsChecked(checked);
  }, [checked]);
  //*--------------------------------------------------*
  return (
    <Element
      style={_style}
      className={className}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        onClick !== undefined && onClick(isChecked);
        onChange !== undefined && onChange(isChecked);
        setIsChecked(!isChecked);
      }}>
      {/* 
        @alt 속성값 없으면 랜덤으로 생성
       */}
      <img
        style={{width: width || '18px', height: height || '18px'}}
        src={isChecked ? green_checked_on || checked_src : green_checked || src} //---- 체크드이미지
        alt={uuid(4)}
      />
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.button`
  font-size: 0;
`;

/********************************************************
[사용법]
  const [isAll, setIsAll] = React.useState(false)
  <Checkbox
    checked={isAll}
    onChange={() => {
      setIsAll(!isAll)
    }}
  />
  <Button
    onClick={() => {
      setIsAll(!isAll)
    }}
  />
  {isAll + ''}
*********************************************************/
