/**
 * @name Icon
 * @description 레티나,디스플레이대응 2배수이미지
 */
import styled from 'styled-components';

type Props = {
  width?: string | number
  height?: string | number
  margin?: string
  padding?: string
  src?: React.ImgHTMLAttributes<any>['src']
  [key: string]: any
};
export default function Icon({style, src, width, height, margin, padding}: Props) {
  return <Element src={src} width={width} height={height} margin={margin} padding={padding} style={style} />;
}
//*--------------------------------------------------*
const Element = styled.img<Props>`
  width: ${({width}) => (typeof width === 'number' ? width + 'px' : width) || '24px'};
  height: ${({height}) => (typeof height === 'number' ? height + 'px' : height) || '24px'};
  margin: ${({margin}) => margin || '0'};
  padding: ${({padding}) => padding || '0'};
`;

/********************************************************
[사용법]

  <Icon src={logo} width={'40px'} height={100} />

*********************************************************/
