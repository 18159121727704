/**
 * @name GNB
 */
import {useState} from 'react';
import {gnbAction} from 'model/modules';
import {GnbMenuType} from 'types';
import {Image, Row, Text, useRoutes} from 'lib';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from 'model';
import {Color} from 'styles';
import {gnb0, gnb0_active, gnb0_hover, gnb1, gnb1_active, gnb1_hover} from 'assets/svg';
// contents
import Depth2 from './depth2-menu';

type Props = {
  item: GnbMenuType;
  index: number;
};
export default function GnbDepth1Menu({item, index}: Props) {
  // hooks
  const {navi} = useAppSelector((state) => state.gnb);
  const {navigate} = useRoutes();
  const dispatch = useAppDispatch();
  const isActive = navi?.depth1 === index ? true : false;
  const isChildren = !!item?.children && item?.children.length > 0 ? true : false;
  // 버튼 hover 상태를 위한 state
  const [isHover, setIsHover] = useState(false);
  const [isDown, setIsDown] = useState(false);
  const icon = [gnb0, gnb1];
  const icon_hover = [gnb0_hover, gnb1_hover];
  const icon_active = [gnb0_active, gnb1_active];
  //*--------------------------------------------------*
  return (
    <Content>
      <button
        className={`item ${isActive && 'active'}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onMouseDown={() => {
          setIsDown(true);
        }}
        onMouseUp={() => {
          setIsDown(false);
        }}
        onClick={() => {
          dispatch(gnbAction.setNavi({depth1: index, depth2: -1}));
          navigate(item?.path);
          // !isChildren && navigate(item?.path);
        }}>
        <Row>
          {/*-- 아이콘 --*/}
          {!isHover && <Image src={icon[index]} width={24} height={24} style={{margin: 8}} />}
          {isHover && (
            <Image src={isDown ? icon_active[index] : icon_hover[index]} style={{margin: 8}} width={24} height={24} />
          )}
          <Text style={{marginLeft: 8}} mode="Btn_L" label={item?.name} />
        </Row>
      </button>
      {/*-- 2depth메뉴 --*/}
      {isChildren && <Depth2 item={item} depth1={index} index={index} />}
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  margin-bottom: 12px;
  /*-- nav (1depth메뉴) --*/
  > button.item {
    display: block;
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 8px;
    color: ${Color.gray40};
    &:hover {
      color: ${Color.gray20};
      background-color: ${Color.blue100};
    }
    &:active {
      color: ${Color.blue30};
      background-color: ${Color.blue90};
    }
    /* 활성화 */
    &.active {
    }
  }
`;
