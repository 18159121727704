/**
 * @name Array
 * @description 배열 관련 유틸리티
 */

import {GNB_MENU} from 'config';

type MenuItem = {
  name: string
  path: string
  children?: MenuItem[]
};
type GnbMenuType = MenuItem[];
type DepthInfo = {
  depth1: number
  depth2: number | null
};
/*========================================================
[사용법]

* 메뉴의 깊이를 찾아주는 함수 (활성화)
const result = findMenuDepth(MENU.SIGN_IN, GNB_MENU) // 예상 결과: { depth1: 1, depth2: 4 }
* 간단하게 사용법
const result = findMenuDepth()
console.log(result) // 예상 결과: { depth1: 1, depth2: 4 }
=======================================================*/
export const findMenuDepth = (path: string = typeof window !== 'undefined' ? window.location.pathname : '', menuItems: GnbMenuType = GNB_MENU): DepthInfo | null => {
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];
    // 자식 메뉴가 있는 경우
    if (menuItem.children) {
      for (let j = 0; j < menuItem.children.length; j++) {
        const childItem = menuItem.children[j];
        // 하위 메뉴에서 찾았을 경우
        if (childItem.path === path) return {depth1: i, depth2: j};
      }
    }
    // 자식 메뉴가 없는 경우 (최상위 메뉴)
    if (menuItem.path === path) return {depth1: i, depth2: null};
  }
  return null; // 경로에 해당하는 메뉴 항목을 찾지 못한 경우
};
