/**
 * @name 네비게이션
 * @description
 */
import {Flex, useWindowSize} from 'lib';
// contents
import Select from './select';
import Search from './search';
import Pager from './pager';

export default function Contents() {
  // hooks
  const {width} = useWindowSize();
  //*--------------------------------------------------*
  return (
    <Flex.SpaceBetween style={{height: 40, marginTop: 20, marginBottom: 20}}>
      {/* DropDown */}
      {/* <Select /> */}
      <span />
      <Flex.End alignItems="center">
        {/* 검색영역 */}
        <Search />
        {/* 이전,다음 */}
        <Pager />
      </Flex.End>
    </Flex.SpaceBetween>
  );
}

// <Flex.SpaceBetween style={{position: 'relative', marginTop: 20, marginBottom: 20, height: 80}}>
//   {/* DropDown */}
//   {/* <Select /> */}
//   <span />
//   <Flex.End alignItems="center">
//     {/* 검색영역 */}
//     <Search />
//     {/* 이전,다음 */}
//     <Pager />
//   </Flex.End>
// </Flex.SpaceBetween>;
