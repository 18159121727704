/**
 * @name Content.
 * @description
 */
import {Events, Flex, Form, useObject} from 'lib';
// contents
import {CancelButton, CapacityUnit, CsvMapFile, DataType, SaveButton} from 'pages/common';
import {Store, _event} from '..';
import {useEffect} from 'react';
import styled from 'styled-components';

export default function Contents() {
  // hooks
  const [info, setInfo] = useObject({});
  const [error, setError] = useObject({
    status: 'normal',
    message: '',
    cause: []
  });
  const onError = (error: any) => {
    setError(Object.assign({}, error, {status: 'error'}));
  };
  useEffect(() => {
    // addListener
    Events.on(_event.ERROR_SUBMIT, onError);
    return () => {
      // removeListener
      Events.off(_event.ERROR_SUBMIT, onError);
    };
  }, []);
  //*--------------------------------------------------*
  return (
    <Tr className={error?.status === 'error' ? 'error' : ''}>
      {/* <td>{latest_id + 1}</td> */}
      <td />
      <td>
        {/* 이름 */}
        <Form.Input
          // status={error?.status}
          // error_mesage={error?.message}
          maxLength={20}
          name="name"
          value={info?.name}
          placeholder="장비ID을 입력하세요."
          style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({name, value}) => {
            const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            if (regx.test(value)) {
              setInfo({[name]: info?.name});
              return;
            }
            setInfo({[name]: value});
          }}
        />
      </td>
      <td>
        {/* 국문 */}
        <Form.Input
          maxLength={20}
          placeholder="장비명을 입력하세요."
          name="korName"
          value={info?.korName}
          style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({name, value}) => setInfo({[name]: value})}
        />
      </td>
      <td>
        {/* 데이터타입 */}
        <DataType
          name="dataType"
          onChange={({name, value}) => {
            setInfo({[name]: value});
          }}
        />
      </td>
      {/* 모델번호 */}
      <td>
        <Form.Input
          maxLength={30}
          placeholder="모델 번호을 입력하세요."
          name="modelId"
          value={info?.modelId}
          style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
          onChange={({name, value}) => setInfo({[name]: value})}
        />
      </td>
      {/* 최대용량 */}
      <td>
        <Flex.Center>
          <Form.Input
            maxLength={10}
            name="maximumCapacity"
            value={info?.maximumCapacity}
            style={{width: 100, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
            onChange={({name, value}) => {
              const filteredValue = value.replace(/[^0-9]/g, '');
              setInfo({[name]: filteredValue});
            }}
          />
          <CapacityUnit
            name="maximumCapacityUnit"
            onChange={({name, value}) => {
              setInfo({[name]: value});
            }}
          />
        </Flex.Center>
      </td>
      {/* 정격용량 */}
      <td>
        <Flex.Center>
          <Form.Input
            maxLength={10}
            name="ratedCapacity"
            value={info?.ratedCapacity}
            style={{width: 100, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}
            onChange={({name, value}) => {
              const filteredValue = value.replace(/[^0-9]/g, '');
              setInfo({[name]: filteredValue});
            }}
          />
          <CapacityUnit name="ratedCapacityUnit" onChange={({name, value}) => setInfo({[name]: value})} />
        </Flex.Center>
      </td>
      <td>
        <CsvMapFile error={error} info={info} type="map" mode="ADD" onFile={(file) => setInfo({['file']: file})} />
      </td>
      <td>
        <CsvMapFile info={{}} type="statusMap" mode="ADD" disabled={true} />
      </td>
      {/* auto */}
      <td></td>
      <td style={{textAlign: 'left'}}>
        {/* {info?.name + ''}
        {info?.korName + ''}
        {info?.file + ''} */}
        <SaveButton
          style={{marginRight: 8}}
          disabled={!info?.name || !info?.korName || !info?.file}
          onClick={() => {
            // 유효성체크
            //---------------------
            Events.emit(_event.ADD_SUBMIT, {
              ...info,
              type: 'equipment',
              standalone: true
            });
          }}
        />
        <CancelButton style={{marginRight: 8}} onClick={() => Events.emit(_event.CANCEL)} />
      </td>
    </Tr>
  );
}
//*--------------------------------------------------*
const Tr = styled.tr`
  td {
    vertical-align: middle;
  }
  &.error {
    td {
      padding-top: 8px;
      padding-bottom: 8px;
      vertical-align: top;
    }
  }
`;
