/**
 * @name Contents
 * @API https://api-cebs-dev.acelogrid.com/docs#/%EC%82%AC%EC%9D%B4%ED%8A%B8%20API
 * @description
 */
import {useState} from 'react';
import {debug, useContext} from 'lib';
import styled from 'styled-components';
import {useSiteBanksQuery} from 'pages/site-manage/model';
// contents
import Device from './list/device';

type Props = {
  siteId: string;
};
const Contents: React.FC = () => {
  // query
  const {isLoading: banksLoading, data: banksData} = useSiteBanksQuery();
  return (
    <Content>
      {/*==== 계통구성도 완료된,장비 */}
      {!banksLoading &&
        banksData &&
        banksData?.siteDevices.map((item, index) => {
          return (
            <Device
              key={`${item.name}_${index}`}
              parentNo={item?.siteDeviceNo}
              index={index}
              deviceData={item}
              isOpen={true}
            />
          );
        })}
      {/*==== 계통구성도 완료되지않은,장비 */}
      {!banksLoading &&
        banksData &&
        banksData?.unregEquips.map((item, index) => {
          return (
            <Device
              key={`${item.name}_${index}`}
              parentNo={item?.siteDeviceNo}
              index={index}
              unEquipsdata={item}
              isOpen={false}
            />
          );
        })}
    </Content>
  );
};
export default Contents;

const Content = styled.section`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;
  height: calc(100vh - 390px);
`;
