/**
 * @name Header
 * @description
 */
import {plus_white} from 'assets/svg';
import {MENU} from 'config';
import {Button, Events, Flex, Text, useRoutes} from 'lib';
import {useSiteAddStore} from 'pages/site-manage/model';
import React from 'react';
import {Color} from 'styles';

export default function Contents() {
  // hooks
  const {setEditMode} = useSiteAddStore();
  const {navigate} = useRoutes();
  //*--------------------------------------------------*
  return (
    <Flex.SpaceBetween
      style={{height: 80, marginTop: 16, padding: '0 24px', borderBottom: `1px solid ${Color.blue100}`}}>
      {/* 타이틀 */}
      <React.Fragment>
        <Text mode="Headline_MB" label="사이트 목록" color={Color.gray30} />
        {/* <span style={{fontSize: 10}}>{googleToken + ''}</span> */}
      </React.Fragment>
      {/* 버튼 */}
      <Button.Image
        src={plus_white}
        width={24}
        height={24}
        onClick={() => {
          setEditMode('EDIT');
          navigate(`${MENU.SITE_ADD}?editMode=EDIT`);
        }}>
        <Text style={{marginLeft: 4}} mode="Btn_M" label="사이트 추가" />
      </Button.Image>
    </Flex.SpaceBetween>
  );
}
