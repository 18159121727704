/**
 * @name 맵파일아이콘(장치마스터)
 * @description
 */
import React, {useEffect, useRef, useState} from 'react';
import {Button, Events, Flex, Image, Text, useAxios, useClickOutside} from 'lib';
import {exclamation_red, file_csv, file_csv_disabled, more, more_disabled} from 'assets/svg';
import {API} from 'config';
import axios from 'axios';
import {Color} from 'styles';

// contents
import Popup from './popup';
type Props = {
  info: any;
  type: 'map' | 'statusMap';
  mode?: 'EDIT' | 'LIST' | 'ADD';
  disabled?: boolean;
  onChange?: (event: any) => void;
  onFile?: (file: any) => void;
  error?: {
    status: 'error' | 'normal';
    message: string;
    cause: string[];
  };
};
export default function Contents({info, type, disabled, onChange, onFile, mode, error}: Props) {
  // hooks
  const fileInputRef = useRef(null) as any; //파일 업로드를 위한 input ref 생성
  const [status, setStatus] = useState(false);
  const [download_link, setDownload_link] = useState('');
  const {del} = useAxios();
  const targetRef = useRef(null) as any;
  const [isOpen, setIsOpen] = useState(false);
  //*--------------------------------------------------*
  useEffect(() => {
    if (type === 'map') typeof info?.mapUploadPath === 'string' ? setStatus(true) : setStatus(false);
    if (type === 'map' && typeof info?.mapUploadPath === 'string') setDownload_link(info?.mapUploadPath);
    if (type === 'statusMap') typeof info?.statusMapUploadPath === 'string' ? setStatus(true) : setStatus(false);
    if (type === 'statusMap' && typeof info?.statusMapUploadPath === 'string')
      setDownload_link(info?.statusMapUploadPath);
  }, [info]);
  //*--------------------------------------------------*
  // 파일 선택 핸들러
  const handleFileChange = async (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    let data = new FormData();

    console.log(info?.name);
    // data.append('name', info.name);
    if (!info?.name) {
      Events.emit('toast', '장비명을 입력하세요.');
      return;
    }
    data.append('name', info?.name);
    data.append('mapType', type);
    data.append('file', file);
    // 장비 및 모듈추가
    // if (onFile) {
    //   !!onFile && onFile(file);
    //   return;
    // }

    const res = await axios.post(`${API}/devices/map/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      validateStatus: function (status: any) {
        return status >= 200 && status < 500; // 400번대 에러도 무시
      }
    });
    if (res?.data?.success) {
      setStatus(true);
      !!onFile && onFile(file);
      Events.emit('toast', res?.data?.data?.message);
    } else {
      //
      Events.emit('toast', res?.data?.error?.message);
      if (fileInputRef.current) fileInputRef.current.value = '';
      file = null;
      data = new FormData();
    }
  };
  // 이벤트 핸들러
  async function eventHandler(modeType: string) {
    if (!status && modeType !== 'upload') return; // 비활성상태
    ///-------------------------------------------- 파일보기
    if (modeType === 'view') {
      await axios
        .get(`${API}/devices/map/file`, {
          params: {
            name: info?.name,
            mapType: type,
            fileType: 'csv'
          },
          responseType: 'blob'
        })
        .then((res: any) => {
          const url = window.URL.createObjectURL(new Blob([res.data], {type: res.headers['content-type']}));
          window.open(url, '_blacnk'); // 새 창에서 URL을 열기
        });
    }
    ///-------------------------------------------- 파일업로드
    else if (modeType === 'upload') {
      fileInputRef?.current.click();
    }
    ///-------------------------------------------- 파일다운로드
    else if (modeType === 'download') {
      window.location.href = download_link;
    }
    ///-------------------------------------------- 파일삭제
    else if (modeType === 'delete') {
      await del(`${API}/devices/map/file`, {
        name: info?.name,
        mapType: type
      });
      setStatus(false);
    }
    //---
    setIsOpen(false);
  }
  // 레이어다른영역 클릭시닫힘
  useClickOutside(targetRef, () => setIsOpen(false));
  //*--------------------------------------------------*
  return (
    <div ref={targetRef} style={{position: 'relative'}}>
      <Image src={status ? file_csv : file_csv_disabled} style={{width: 30, height: 30}} />
      <Button.Image
        themes="none"
        onClick={() => !disabled && setIsOpen(true)}
        width={24}
        height={24}
        src={disabled ? more_disabled : more}
      />
      {isOpen && (
        <Popup onClick={(modeType: string) => eventHandler(modeType)} type={type} mode={mode} status={status} />
      )}
      {/* 파일업로드 */}
      <input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />
      {/* {error} */}
      {error?.status === 'error' && error?.cause[0] === 'map' && (
        <Flex.Center style={{marginBottom: 4}}>
          <Image style={{width: 14, height: 14, marginRight: 4}} src={exclamation_red} />
          <Text mode={'Caption_MB'} label={error?.message} color={Color.red30} />
        </Flex.Center>
      )}
    </div>
  );
}
