/**---------------------------------- @name API */
const LOCAL_API = 'https://api-cebs-local.acelogrid.com';
const DEV_API = 'https://api-cebs-dev.acelogrid.com';
const PROD_API = 'https://api-cebs.acelogrid.com';

export const LIST_MAX = 20; // 리스트 최대 갯수
export const API =
  process.env.NODE_ENV === 'development' ? LOCAL_API : process.env.REACT_APP_ENV_MODE === 'DEV' ? DEV_API : PROD_API;

/**---------------------------------- @name API_KEY */
export const GOOGLE_MAP_KEY = 'AIzaSyArzPXNGE4Xwx7mpmSkjIQNIbhHZv0yK_A';

/**---------------------------------- @name KAKAO_API_KEY */
export const KAKAO_REST_API = '9c97e8aaa10542d168be1260e03d063d';

/********************************************************
[사용법]
구글 크로커스계정

//----카카오
네이티브 앱 키	e88439cecc9147a5b5f18ea95a4cec3c
REST API 키	7a85d96399f6b2a83f1db466b5a3f76d
JavaScript 키	5db1144bcadeb9c51c24ad6316126d75
Admin 키	93b82a08a924699758be8228548b62e4

*********************************************************/
