/**
 * @name 계통구성도>다이어그램
 * @description
 */
import styled from 'styled-components';
import {useSiteBanksQuery} from 'pages/site-manage/model';
// contents
import Bank from './bank';
import Header from './header';
import Chart from './chart';

const Contents = (): JSX.Element => {
  const {isLoading: banksLoading, data: banksData} = useSiteBanksQuery();

  return (
    <Content>
      {/* 뱅크영역 */}
      <Bank />
      {/* 헤더영역 */}
      <Header />
      {/* 차트 */}
      <div className="charts">
        {!banksLoading &&
          banksData &&
          banksData.siteDevices.map((item, index) => {
            return <Chart key={`${item.name}_${index}`} data={item} />;
          })}
      </div>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1144px;
  height: calc(100vh - 390px);
  .charts {
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
