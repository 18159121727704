/**
 * @name Content
 * @description 화면구성,레이아웃,info값 외부데이타를 받는경우
 */

import {ContentType, CSS} from 'types';
// static
import Debug from './static/debug';

function Content({align, valign, children, style, props}: ContentType) {
  // styles
  const options: CSS = {
    // display: 'block',
    // width: '100%',
    textAlign: align,
    verticalAlign: valign,
    ...style,
  };
  //*--------------------------------------------------*
  return (
    <div {...props} className="content" style={options}>
      {children}
    </div>
  );
}
//*--------------------------------------------------*
Content.Debug = Debug;

export default Content;

/*========================================================
  [참고사항] 
  

=======================================================*/
