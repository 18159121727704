import {uuid} from 'lib';
import React, {useState} from 'react';
import Xarrow from 'react-xarrows';
import styled from 'styled-components';

interface Connection {
  start: string
  end: string
  id: string
}
export default function Contents() {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [tempStart, setTempStart] = useState<string>('');

  const handleClick = (id: string) => {
    if (!tempStart) {
      setTempStart(id);
    } else {
      const newConnection: Connection = {start: tempStart, end: id, id: `${tempStart}-${id}`};
      setConnections([...connections, newConnection]);
      setTempStart('');
    }
  };

  const handleDeleteConnection = (connId: string) => {
    setConnections(connections.filter(conn => conn.id !== connId));
  };

  return (
    <Content>
      <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
        {[...Array(6)].map((_, index) => {
          const btnId = `btn${index + 1}`;
          return (
            <button
              key={uuid()}
              id={btnId}
              className={`item ${tempStart === btnId ? 'start' : ''}`}
              style={{top: `${0 + index * 80}px`, left: `${200 + index * 160}px`}}
              onClick={() => handleClick(btnId)}>
              {btnId}
            </button>
          );
        })}
      </div>
      {connections.map(conn => (
        <div key={uuid()}>
          <Xarrow start={conn.start} end={conn.end} />
          <button onClick={() => handleDeleteConnection(conn.id)}>Delete {conn.id}</button>
        </div>
      ))}
    </Content>
  );
}

const Content = styled.div`
  position: relative;
  height: 600px;
  margin: 20px 0;
  padding: 20px;
  border: 1px dashed #111;
  .item {
    position: absolute;
    padding: 10px;
    border: 1px solid #000;
    margin: 10px;
    background-color: #f0f0f0; /* Optional: styling for buttons */
  }
  .item.start {
    background-color: #4caf50; /* Highlight the start button */
    color: white;
  }
`;
