/**
 * @name Header
 * @description
 */
import styled from 'styled-components';
import {useSiteAddStore} from 'pages/site-manage/model';
import {Text} from 'lib';
import {Color} from 'styles';

const Contents: React.FC = () => {
  const {siteId} = useSiteAddStore();

  return (
    <Content>
      <Text label={siteId || ''} mode="Btn_M" color={Color.gray30} />
    </Content>
  );
};
export default Contents;

const Content = styled.header`
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 2px solid ${Color.gray80};
  padding: 0 4px;
`;
