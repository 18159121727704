/**
 * @name Location
 */
import React from 'react';
import {Icon, useRoutes} from 'lib';
import {logo_acelo} from 'assets/svg';

export default function GnbTop() {
  // hooks
  const {navigate} = useRoutes();
  //*--------------------------------------------------*
  return (
    <React.Fragment>
      {/* 로고 */}
      <button
        style={{
          display: 'block',
          width: '100%',
          height: '40px',
          marginTop: '10px',
          marginBottom: '10px',
          padding: '0 26px',
          textAlign: 'left'
        }}
        onClick={() => {
          // navigate(MENU.DASHBOARD)
        }}>
        <Icon src={logo_acelo} width={'84px'} height={'20px'} />
      </button>
    </React.Fragment>
  );
}
