/**
 * @name Array
 * @description 배열생성
 */
export const makeArray = (length: number, obj: {}) => {
  const ary = Array.from({length: length}, () => {
    return obj;
  });
  return ary;
};

/**
 * @name Array
 * @description 배열 관련 유틸리티
 */
export const shuffle = (ary: any[]) => {
  for (let i = ary.length - 1; i > 0; i--) {
    return ary.sort(() => Math.random() - 0.5);
  }
};
/**
 *
 * @name 배열여부확인
 * @returns boolean
 */
export const isArray = (val: any) => {
  try {
    return Array.isArray(val);
  } catch (e) {
    return false;
  }
};
/**
 *
 * @name 배열에서요소제거
 * @example filterArray(select_model, {seq: item?.seq}) // 배열에서 요소 제거(오브젝트)
 * @example filterArray(payload?.modelSeqs, item?.seq) // 배열에서 요소 제거
 * @returns array
 */
export const filterArray = (ary: [], obj: {} | string | number) => {
  try {
    if (typeof obj === 'object') {
      return ary?.filter((ele: any) => ele[Object.keys(ele)[0]] !== Object.values(obj)[0]);
    } else return ary?.filter((ele: any) => ele !== obj);
  } catch (e) {
    console.log(e);
  }
};
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
