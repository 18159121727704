/**
 * @name Button
 * @description 버튼
 */
import styled from 'styled-components';
import {Color, ThemesButton} from 'styles';
import {ButtonProps} from 'types';
// contents
import TextButton from './text-button';
import ImageButton from './image-button';
import FullButton from './full-button';
import NavigateButton from './navigate-button';

function Button({style, children, ...props}: ButtonProps) {
  const types = () => {
    let _type;
    if (props?.themes === 'red') _type = ThemesButton.red;
    if (props?.themes === 'none') return {};
    return `${ThemesButton.defaultButton} ${_type}`;
  };
  /// 사이즈
  const size = () => {
    // 큰사이즈
    if (props?.size === 'large') return ThemesButton.large;
    if (props?.size === 'normal') return ThemesButton.normal;
    if (props?.size === 'small') return ThemesButton.small;
    return {};
  };
  return (
    <Element
      className={`${types()} ${size()}`}
      // className={`${themes.defaultButton} ${props?.size === 'large' && themes?.large}`}
      // onClick={(event): React.MouseEvent<HTMLElement> => handleClick(event)}
      $color={props?.color}
      $disabled={props?.disabled}
      $border={props?.border}
      $borderradius={props?.borderradius}
      $backgroundcolor={Object.assign({}, props?.theme, props?.backgroundcolor)}
      // $disabledbackgroundcolor={props?.disabledbackgroundcolor}
      /// theme 값이 있으면 기본테마 + 커스텀테마
      style={Object.assign({}, props?.theme, style)}
      {...props}>
      {children || 'Button'}
    </Element>
  );
}

Button.Text = TextButton;
Button.Image = ImageButton;
Button.Full = FullButton;
Button.Navigate = NavigateButton;
export default Button;

//*--------------------------------------------------*
const Element = styled.button<ButtonProps>`
  color: ${({color}) => color};
  padding: ${({padding}) => padding};
  background-color: ${({backgroundcolor}) => backgroundcolor};
  border: ${({border}) => border};
  border-radius: ${({borderradius}) => borderradius};
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  cursor: pointer;

  &:hover {
    color: ${({hovercolor}) => hovercolor};
    background-color: ${({hoverbackgroundcolor}) => hoverbackgroundcolor};
  }
  &:active {
  }
  &:disabled {
    cursor: not-allowed;
    color: ${Color.blue90};
    background-color: ${Color.blue70};
  }
  &:disabled:hover,
  &:disabled:active {
    cursor: not-allowed;
    filter: none;
  }
`;
/********************************************************
[사용법]
  <Button
    padding="10px 20px"
    borderradius="10px"
    color={'#FFF'}
    hovercolor="#111"
    backgroundcolor="#ff0000"
    hoverbackgroundcolor={'#ffff00'}
    // hoverColor="red"
    onClick={event => {
      console.log(event)
    }}
  />

  <Button
    size="small"
    themes="red"
    onClick={event => {
      console.log(event)
    }}
  />
*********************************************************/
