/**
 * @name Top
 */
import React, {useEffect, useState} from 'react';
import {Color} from 'styles';
import {Button, Flex, Image, Text, findMenuDepth} from 'lib';
import {top_sitemap, top_sitemap_arrow} from 'assets/svg';
import {GNB_MENU} from 'config';
// contents
import Profile from './profile';

export default function Top() {
  // hooks
  const [location, setLocation] = useState<any>(null);
  //*--------------------------------------------------*
  useEffect(() => {
    const depth = findMenuDepth() as any;
    if (GNB_MENU && !!depth) {
      const temp = {
        depth1: GNB_MENU?.[depth?.depth1].name,
        depth2: GNB_MENU?.[depth?.depth1]?.children?.[depth?.depth2].name,
      } as any;
      setLocation(temp);
    }
  }, []);
  //*--------------------------------------------------*
  return (
    <Flex.SpaceBetween width="100%" alignItems="center" style={{height: 60, marginRight: 24, borderBottom: `1px solid ${Color.blue90}`}}>
      <Flex.Row>
        <Button.Image themes="none" src={top_sitemap} width={24} height={24} color={Color.gray30} style={{marginLeft: 20, marginRight: 8}} />
        <Text mode="Btn_M" label={location?.depth1 || 'HOME'} />
        {!!location && (
          <React.Fragment>
            <Image src={top_sitemap_arrow} width={24} height={24} color={Color.gray30} style={{marginLeft: 4, marginRight: 4}} />
            <Text mode="Btn_M" label={location?.depth2} />
          </React.Fragment>
        )}
      </Flex.Row>
      {/* 프로필 */}
      <Profile />
    </Flex.SpaceBetween>
  );
}
