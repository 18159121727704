/**
 * @name DropDown
 * @description
 */
import styled from 'styled-components';
import React, {useEffect, useRef, useState} from 'react';
import {Icon, Image, List, Text, useClickOutside, uuid} from 'lib';
import {CSS, ListRenderItem} from 'types';
import {COLOR, Color} from 'styles';
import {arrow_down, check_blue} from 'assets/svg';

type ItemProps = {
  label?: string
  value: any
};
type Props = {
  info: ItemProps[] //----------------------------------------------- 정보
  disabled?: boolean //---------------------------------------------- 비활성화
  onChange?: (item: ItemProps) => void //---------------------------- onChange변화값
  style?: CSS //----------------------------------------------------- inputStyleSheet
  layerStyle?: CSS //------------------------------------------------ 레이어팝업 StyleSheet
  visible?: boolean //----------------------------------------------- 레이어팝업 show/hide 외부에서컨트롤
  renterItem?: (item: ListRenderItem) => React.ReactNode //----------- 렌더링 아이템
  selected?: number //------------------------------------------------ 선택된아이템
  // [key: string]: any
};
export default function DropDown({info, selected, disabled, visible = false, onChange, renterItem, style, layerStyle}: Props) {
  // hooks
  const eleRef = useRef(null);
  const [_selected, setSelected] = useState(selected ?? 0);
  const [isBool, setIsBool] = useState(false);
  // outside click
  useClickOutside(eleRef, () => {
    setIsBool(false);
  });
  //*--------------------------------------------------*
  useEffect(() => {
    if (visible === undefined) return;
    setIsBool(visible);
  }, [visible]);
  //*--------------------------------------------------*
  const renderItem = ({item, index}: ListRenderItem) => {
    return (
      <button
        key={uuid()}
        className={`cursor item ${_selected === index ? 'active' : ''}`}
        onClick={() => {
          !!onChange && onChange(item);
          setSelected(index);
          setIsBool(false);
        }}>
        <Text mode="Body_LM" label={item.label} />
        {_selected === index && <Icon className="checked" src={check_blue} />}
      </button>
    );
  };
  //*--------------------------------------------------*
  return (
    <Element ref={eleRef}>
      <button
        disabled={disabled}
        className="selected"
        style={style ?? {}}
        onClick={() => {
          setIsBool(!isBool);
        }}>
        <Text mode="Body_MR" label={info[_selected].label} color={Color.gray20} />
        <Image src={isBool ? arrow_down : arrow_down} width={24} height={24} style={{marginLeft: 8}} />
      </button>
      {/* 레이어팝업 */}
      {isBool && (
        <article className="popup" style={layerStyle ?? {}}>
          <List info={info} renderItem={renterItem ?? renderItem} />
        </article>
      )}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  padding-left: 4px;
  padding-right: 12px;
  /* background-color: red; */
  button.selected {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    /* flex-wrap: nowrap; */
    /* width: %; */
    height: 40px;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 4px; */
    /* padding-right: 12px; */
    background-color: ${Color.white};
    &:disabled {
      border-color: ${COLOR.GRAY4};
      background-color: ${COLOR.GRAY6};
    }
  }
  /* <!--레이어팝업--> */
  .popup {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    width: 100%;
    min-width: 182px;
    padding: 8px;
    box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    background-color: ${Color.white};
  }
  /* <!--아이템--> */
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    padding: 0 16px;
    color: ${Color.gray30};
    &:hover {
      color: ${Color.blue30} !important;
    }
    &.active {
      color: ${Color.blue30};
    }
  }
`;
/********************************************************
[사용법]

  const ui_info = [
    {label: '라벨1', value: 'num1'},
    {label: '라벨2', value: 'num2'},
    {label: '라벨3', value: 'num3'},
  ]
        <DropDown
          style={{marginBottom: 30}}
          info={ui_info}
          onChange={data => {
            console.log(data)
          }}
          // layerStyle={{ top: 50 }}
        />
*********************************************************/
