/**
 * @name Content
 * @description
 */

import {AppContentType} from 'types';

function Content({children, style}: AppContentType) {
  return (
    <div className="app-content" style={style}>
      {children}
    </div>
  );
}

export default Content;
