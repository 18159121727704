/**
 * @name TextButton
 * @description 버튼
 */
import Text from '../text/text';
import Button from './button';
import {ButtonProps} from 'types';

type Props = {
  label?: string;
};
type TextButtonProps = ButtonProps & Props;

export default function TextButton(props: TextButtonProps) {
  return (
    <Button {...props}>
      {/* <TextButton {...props} /> */}
      <Text {...props} />
      {/* <span>{props?.label || 'TextButton'}</span> */}
    </Button>
  );
}

/********************************************************
[사용법]
  <Button.Text
    disabled
    hoverColor="pink"
    fontSize="16px"
    label="Button.Text"
    onClick={event => {
      console.log(event)
    }}
  />
*********************************************************/
