/**
 * @name Flex
 * @param {string} flexDirection - 방향
 * @param {string} justifyContent - 왼,오른쪽정렬
 * @param {string} alignItems - 높이정렬
 * @param {string} children - 자식노드
 * @param {boolean} outline - border표시
 * @description
 */
import React from 'react';
import {Color} from 'styles';
import {CSS} from 'types';

type Props = {
  className?: string;
  width?: string | '100%';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  gap?: number;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  children?: React.ReactNode;
  outline?: boolean; //----------------------- 테두리
  style?: CSS; //----------------------------- 아이콘스타일
};
function Flex({
  style,
  width,
  gap,
  flexWrap,
  outline,
  alignItems,
  justifyContent,
  flexDirection,
  className,
  children
}: Props) {
  // 기본스타일
  const options = {
    display: 'flex',
    width: width || 'auto',
    gap: gap || 0,
    border: outline ? `1px solid ${Color.gray10}` : 'none',
    flexWrap: flexWrap || 'nowrap',
    flexDirection: flexDirection || 'row',
    justifyContent: justifyContent || 'flex-start',
    alignItems: alignItems || 'flex-start',
    ...style
  };
  //*--------------------------------------------------*
  return (
    <section className={className} style={options}>
      {children}
    </section>
  );
}
function Row(props: Props) {
  return <Flex flexDirection="row" alignItems="center" {...props} />;
}
function Column(props: Props) {
  return <Flex flexDirection="column" alignItems="center" {...props} />;
}
function SpaceBetween(props: Props) {
  return <Flex flexDirection="row" justifyContent="space-between" alignItems="center" {...props} />;
}
function Start(props: Props) {
  return <Flex justifyContent="flex-start" alignItems="center" {...props} />;
}
function Center(props: Props) {
  return <Flex justifyContent="center" alignItems="center" {...props} />;
}
function End(props: Props) {
  return <Flex justifyContent="flex-end" alignItems="center" {...props} />;
}
Flex.Row = Row;
Flex.Column = Column;
Flex.SpaceBetween = SpaceBetween;
Flex.Start = Start;
Flex.Center = Center;
Flex.End = End;
export default Flex;

/********************************************************
[사용법]

  <Flex outline flexDirection="row" justifyContent="flex-end" alignItems="center">
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, height: 300, background: 'blue'}}>test</div>
  </Flex>

  // ROW
  <Flex.Row alignItems="flex-start">
    <div style={{height: 100}}>111</div>
    <div style={{height: 50}}>222</div>
    <div>33</div>
  </Flex.Row>

  //Column
  <Flex.Column alignItems="flex-start">
    <div style={{height: 100}}>111</div>
    <div style={{height: 50}}>222</div>
  <div>33</div>
*********************************************************/
