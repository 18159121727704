/**
 * @name 사이트계통구성도장치(타이틀)
 * @description
 */
import {Flex, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

type Props = {
  title: string;
  color?: string;
};
const Contents = ({title, color}: Props): JSX.Element => {
  return (
    <Content>
      <Flex.Row>
        <div className="round" style={{backgroundColor: color}} />
        <Text label={title} />
      </Flex.Row>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 40px;
  border-top: 1px solid ${Color.gray70};
  background-color: ${Color.gray90};
  /* 라운드 */
  .round {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    border-radius: 50%;
    background: ${Color.green40};
  }
`;
