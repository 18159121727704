/**
 * @name Paragraph
 * @description Paragraph
 */
import styled from 'styled-components';
import {TextProps} from 'types';

export default function P({children, label, ...props}: TextProps) {
  return (
    <Element {...props}>
      {!!label && label}
      {children}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.1px;
`;
/********************************************************
[사용법]
  <H1 label="H1 텍스트를 입력합니다." />
  <H1 style={{fontWeight: 'normal', fontSize: 46, color: 'gold'}}>H1 텍스트를 입력합니다.</H1>
*********************************************************/
