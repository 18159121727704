/**
 * @name Container
 */
import {API, MENU} from 'config';
import {Events, useRoutes} from 'lib';
import {useCallback, useEffect} from 'react';
import {_event} from '.';
// contents
import Presenter from './presenter';

export default function Container() {
  // hooks
  const {navigate} = useRoutes();

  //*--------------------------------------------------*
  // 초기화
  const onInit = useCallback(async () => {
    // 토큰값으로 넘어올경우
  }, []);

  // 대시보드(테스트)
  const onDashboard = useCallback(async () => {
    navigate(MENU.DASHBOARD);
  }, [navigate]);

  // 구글로그인
  const onGoogle = useCallback(async () => {
    // window.location.replace(`${API}/auth/login/google`)
    window.location.href = `${API}/auth/login/google`;
    // window.location.href = `${API}/auth/login/google`
  }, []);

  //*--------------------------------------------------*
  useEffect(() => {
    // 초기화
    onInit();
    Events.addListener(_event.GOOGLE_SIGN_IN, onGoogle);
    Events.addListener(_event.DASHBOARD, onDashboard);
    return () => {
      Events.removeListener(_event.GOOGLE_SIGN_IN, onGoogle);
      Events.removeListener(_event.DASHBOARD, onDashboard);
    };
  }, [onGoogle, onDashboard, onInit]);

  return <Presenter />;
}
