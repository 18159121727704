/**
 * @name 정격용량단위목록
 * @description
 */
import {useEffect, useState} from 'react';
import {API} from 'config';
import {Form, useAxios} from 'lib';

type Props = {
  name?: string
  onChange?: (payload: any) => void
};
export default function Contents({name, onChange}: Props) {
  // hooks
  const {get} = useAxios();
  const [info, setInfo] = useState<{label: string; value: any}[] | null>(null);

  // 조회
  async function onInit() {
    const res = await get(`${API}/devices/capacity-unit`);
    if (res?.success) {
      const temp = res?.data.map((item: any) => ({
        label: item.key,
        value: item.value,
      }));
      setInfo(temp);
      onChange?.({name, label: res?.data[0].label, value: res?.data[0].value});
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit();
  }, []);
  //*--------------------------------------------------*
  return (
    !!info && (
      <Form.DropDown
        name={name || 'capacityUnit'}
        info={info}
        selected={0}
        onChange={({name, label, value}) => {
          onChange?.({name, label, value});
        }}
      />
    )
  );
}
