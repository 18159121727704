/**
 * @name 가이드
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
export const sliceName = 'guide'; //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  // 가장최근ID
  latest_id: number
  board: {
    page: number
    data: any
    meta: any
  } | null
  [key: string]: any
};
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  board: null,
  latest_id: 0,
};
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // setBoard
    setBoard(state: StateType, action: PayloadAction<any>) {
      state.board = action.payload;
    },
    // 가장최근ID설정
    setLatestId(state: StateType, action: PayloadAction<any>) {
      state.latest_id = action.payload;
    },
  },
});
//*--------------------------------------------------*
// export
export const EVENT = {
  // submit
  FETCH: 'GUIDE/FETCH',
  ADD_SUBMIT: 'GUIDE/ADD/SUBMIT',
  MODIFY_SUBMIT: 'GUIDE/MODIFY/SUBMIT',
  ADD: 'GUIDE/ADD',
  CANCEL: 'GUIDE/CANCEL',
  EDIT: 'GUIDE/EDIT',
};

export default slice.reducer;
export const nameSpace = sliceName;
export const actions = slice.actions;
