/**
 * @name 바로가기
 * @description
 */
import {Button, Text} from 'lib';
import {CSS} from 'types';

type Props = {
  disabled?: boolean;
  style?: CSS;
  label?: string;
  onClick?: (payload?: any) => void;
};
export default function Contents({disabled, label, onClick}: Props): JSX.Element {
  return (
    <Button
      style={{width: 61, height: 28, padding: 0}}
      disabled={disabled}
      onClick={() => {
        !!onClick && onClick();
      }}>
      <Text style={{marginLeft: 4}} mode="Body_SM" label={label || '바로가기'} />
    </Button>
  );
}
