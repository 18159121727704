/**
 * @name 장비,모듈,설비
 * @description
 */
import styled from 'styled-components';
import {Flex, Text} from 'lib';
import {Color} from 'styles';
import {Mark} from '../../../common';

const Contents: React.FC = () => {
  const data = [
    {title: '장비', type: 'device'},
    {title: '모듈', type: 'module'},
    {title: '설비', type: 'facility'}
  ];
  return (
    <Content>
      {data.map((item, index) => (
        <Flex.Row justifyContent="center" alignItems="center" className="category" key={`${item}_${index}`}>
          <Mark type={item.type} />
          <Text label={item.title} mode="Body_LM" color={Color.gray40} />
        </Flex.Row>
      ))}
    </Content>
  );
};
export default Contents;

const Content = styled.header`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  /* category */
  .category {
    flex: 1;
  }
`;
