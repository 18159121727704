/**
 * @name TextButton
 * @description 버튼
 */
import {useState} from 'react';
import {ButtonProps, CSS} from 'types';
// contents
import Button from './button';
import {logo} from 'assets/svg';
import {Color} from 'styles';

type Props = {
  isFit?: boolean; //------------------------------이미지가 버튼에 맞게 꽉차게 할지 여부
  width?: string | number; //----------------------이미지넓이
  height?: string | number; //---------------------이미지높이
  src?: React.ImgHTMLAttributes<HTMLImageElement>['src']; //-----------이미지속성
  hover_src?: React.ImgHTMLAttributes<HTMLImageElement>['src']; //---- hover 시 이미지 바꾸는 속성 추가
};
type ImageButtonProps = ButtonProps & Props;

export default function ImageButton(props: ImageButtonProps) {
  const [isHovered, setIsHovered] = useState(false); //---- hover 여부 상태 추가

  const disabledStyle: CSS = props?.disabled
    ? {
        backgroundColor: Color.blue90,
        color: Color.blue70
      }
    : {};
  return (
    <Button
      onMouseEnter={() => {
        if (props?.disabled) return;
        setIsHovered(true);
      }} //---------------------------------------- 마우스 오버 시 상태 변경
      onMouseLeave={() => {
        setIsHovered(false);
      }} //---------------------------------------- 마우스 벗어날 때 상태 변경
      style={{...disabledStyle, fontSize: 0}}
      {...props}>
      <img
        style={{width: props?.width, height: props?.height, objectFit: props?.isFit ? 'cover' : 'initial'}}
        src={isHovered && props?.hover_src ? props?.hover_src : props?.src || logo} //---- hover 시 이미지 변경
        alt={props?.alt || 'imagebutton'}
      />
      {props?.children}
    </Button>
  );
}

/********************************************************
[사용법]
  <Button.Image
    width={200}
    height={70}
    src="https://source.unsplash.com/random/?"
    hover_src=" https://source.unsplash.com/random/?seoul"
    onClick={event => {
      console.log(event)
    }}
  />
*********************************************************/
