/**
 * @name 사이트계통구성관리장비추가,삭제
 */
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {fetchData} from 'api';
import {API} from 'config';
import {ResponseType} from '../model/type';
import {useSiteAddStore, useSystemStore} from '../model';
import {siteBanksSiteFacilitiesProps, siteBanksSiteModuleProps} from '../model/type.system';

// [사이트API]
//---------------------------------------------------------------[장치(장비,모듈]
/// 사이트의 계통 구성 관리에서 장비를 조회 한다.
export const useGridDeviceModule = () => {
  const {siteId} = useSiteAddStore();
  return useQuery({
    queryKey: ['sites_siteId_grid_devices'],
    queryFn: async (): Promise<siteBanksSiteModuleProps[]> => {
      const res: ResponseType<siteBanksSiteModuleProps[]> = await fetchData(
        `${API}/sites/${siteId}/grid/devices?type=module`
      );
      return res.data;
    }
  });
};

// 사이트의 계통 구성도에서 장비를 추가한다.
export const useAddDeviceMutation = () => {
  const {siteId} = useSiteAddStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const res: ResponseType<any> = await fetchData(`${API}/sites/${siteId}/grid/devices`, {method: 'POST', data});
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['grid_devices']});
    }
  });
};

// 사이트의 계통 구성도에서 장비를 삭제(관계 삭제)한다.
export const useDeleteDeviceMutation = () => {
  const {siteId} = useSiteAddStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const res: ResponseType<any> = await fetchData(`${API}/sites/${siteId}/grid/devices`, {method: 'PUT', data});
      return res;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: ['grid_devices']});
    }
  });
};

// [사이트API]
//---------------------------------------------------------------[설비]

/// 사이트의 계통 구성 관리에서 설비를 조회 한다.
export const useGridFacilities = (siteDeviceNo: number) => {
  const {siteId} = useSiteAddStore();
  const {siteBankNo} = useSystemStore(); // 사이트뱅크번호

  // const {siteDeviceNo: selectDeviceNo} = useSiteAddStore(); // 사이트장치번호
  // const _siteDeviceNo = siteDeviceNo || selectDeviceNo;
  return useQuery({
    queryKey: ['sites_siteId_grid_facilities'],
    queryFn: async (): Promise<siteBanksSiteFacilitiesProps[]> => {
      const res: ResponseType<siteBanksSiteFacilitiesProps[]> = await fetchData(
        `${API}/sites/${siteId}/grid/facilities?siteBankNo=${siteBankNo}&siteDeviceNo=${siteDeviceNo}`
      );
      return res.data;
    },
    enabled: !!siteId && !!siteBankNo && !!siteDeviceNo && siteDeviceNo !== 0
  });
};
// 사이트의 계통 구성도에서 설비를 추가한다.
export const useAddFacilitiesMutation = () => {
  const {siteId} = useSiteAddStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const res: ResponseType<any> = await fetchData(`${API}/sites/${siteId}/grid/facilities`, {method: 'POST', data});
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['grid_devices']});
    }
  });
};
export const useDeleteFacilitiesMutation = () => {
  const {siteId} = useSiteAddStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const res: ResponseType<any> = await fetchData(`${API}/sites/${siteId}/grid/facilities`, {method: 'PUT', data});
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['grid_devices']});
    }
  });
};
