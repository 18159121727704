/**
 * @name useWindowSize
 * @description hooks기반 라우팅정보
 */

import {useState} from 'react';
import {useEventListener, useIsomorphicLayoutEffect} from 'usehooks-ts';

interface WindowSize {
  width: number
  height: number
}

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEventListener('resize', handleSize);
  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, []);

  return windowSize;
}
export default useWindowSize;
/********************************************************
import { useWindowSize } from 'usehooks-ts'

export default function Component() {
  const { width, height } = useWindowSize()

  return (
    <div>
      The current window dimensions are:{' '}
      <code>{JSON.stringify({ width, height })}</code>
    </div>
  )
}
*********************************************************/
