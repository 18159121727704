/**
 * @name 리스트없음-장비추가
 * @description
 */
import {Button, Flex, Text} from 'lib';
import {Color} from 'styles';
import {plus_white} from 'assets/svg';

type Props = {
  name?: string
  onClick?: (payload: any) => void
  colSpan?: number
};
export default function Contents({name, onClick, colSpan = 11}: Props) {
  // hooks
  return (
    <tr>
      <td colSpan={colSpan}>
        <Flex.Column style={{height: 500}} justifyContent="center" alignItems="center">
          <Text style={{marginBottom: 8}} color={Color.gray40} mode="Title_LB" label={`등록된 ${name}가 없습니다. `} />
          <Text style={{marginBottom: 32}} mode="Body_LM" color={Color.gray40} label={`아래 버튼을 눌러 ${name}를 등록해주세요.`} />
          {/* 버튼 */}
          <Button.Image
            src={plus_white}
            width={24}
            height={24}
            onClick={() => {
              !!onClick && onClick({name});
            }}>
            <Text style={{marginLeft: 4}} mode="Btn_M" label={`${name} 추가`} />
          </Button.Image>
        </Flex.Column>
      </td>
    </tr>
  );
}
