/**
 * @name 장치마스터
 * @description
 */
import {debug, Flex, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
import {deviceMasterProps, equipmentsProps, facilitiesProps, moduleProps} from 'pages/site-manage/model/type.device';
// contents
import {useSiteAddStore} from 'pages/site-manage/model';
import {useEffect, useState} from 'react';

type Props = {
  data: deviceMasterProps;
};
const Contents = ({data}: Props): JSX.Element => {
  // query
  const {deviceSelected, itemSelected, setItemSelected} = useSiteAddStore();
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    setSelected(-1);
  }, [deviceSelected]);
  // 선택된 아이템이 없으면 초기화
  useEffect(() => {
    if (itemSelected === null) setSelected(-1);
  }, [itemSelected]);

  return (
    <Content>
      <Flex.Column alignItems="flex-start">
        {deviceSelected === 0 &&
          data.data.equipments.map((item: equipmentsProps, index: number) => (
            <button
              onClick={() => {
                setItemSelected(item);
                setSelected(index);
              }}
              className={`item ${selected === index ? 'on' : ''}`}
              key={`${item.id}_${index}`}>
              <Text mode="Body_LM" label={item?.name} />
            </button>
          ))}
        {deviceSelected === 1 &&
          data.data.modules.map((item: moduleProps, index: number) => (
            <button
              onClick={() => {
                setItemSelected(item);
                setSelected(index);
              }}
              className={`item ${selected === index ? 'on' : ''}`}
              key={`${item.id}_${index}`}>
              <Text mode="Body_LM" label={item?.name} />
            </button>
          ))}
        {deviceSelected === 2 &&
          data.data.facilities.map((item: facilitiesProps, index: number) => (
            <button
              onClick={() => {
                setItemSelected(item);
                setSelected(index);
              }}
              className={`item ${selected === index ? 'on' : ''}`}
              key={`${item.facilityId}_${index}`}>
              <Text mode="Body_LM" label={item?.name} />
            </button>
          ))}
      </Flex.Column>
    </Content>
  );
};
export default Contents;

const Content = styled.section`
  overflow-x: hidden;
  overflow-y: auto;
  /* 버튼아이템 */
  button.item {
    display: block;
    text-align: left;
    width: 100%;
    padding: 8px 24px 8px;
    color: ${Color.gray30};
    &:hover,
    &.on {
      color: ${Color.blue10};
      background-color: ${Color.blue80};
    }
  }
`;
