/**
 * @name 타이틀
 * @description
 */
import styled from 'styled-components';
import {Text} from 'lib';
import {COLOR, Color} from 'styles';

type Props = {
  title: string;
};
const Contents = ({title}: Props): JSX.Element => {
  return (
    <Content>
      <Text label={title || '타이틀'} color={Color.blue50} mode="Title_MB" />
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: block;
  padding: 16px 0;
`;
