/**
 * @name 장치데이터타입명목록
 * @description
 */
import React, {useEffect, useState} from 'react';
import {Form, useAxios} from 'lib';
import {API} from 'config';

type Props = {
  name?: string
  onChange?: (payload: any) => void
};
export default function Contents({name, onChange}: Props) {
  // hooks
  const {get} = useAxios();
  const [info, setInfo] = useState<{label: string; value: any}[] | null>(null);
  // 조회
  async function onInit() {
    const res = await get(`${API}/devices/data-type`);
    if (res?.success) {
      const temp = res?.data.map((item: any) => ({
        label: item.key,
        value: item.value,
      }));
      setInfo(temp);
      onChange?.({name, label: res?.data[0].label, value: res?.data[0].value});
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit();
  }, []);
  //*--------------------------------------------------*
  return (
    !!info && (
      <Form.DropDown
        style={{width: 80, marginLeft: 'auto', marginRight: 'auto', alignItems: 'center'}}
        name={name || 'dataType'}
        selected={0}
        info={info}
        onChange={({name, label, value}) => {
          onChange?.({name, label, value});
          // setInfo({[name]: value})
        }}
      />
    )
  );
}
/********************************************************
  사용법
  
  Events.emit('toast', '메시지내용')
*********************************************************/
