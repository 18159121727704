/**
 * @name H4
 * @description H4
 */
import styled from 'styled-components';
import {TextProps} from 'types';

export default function H4({children, label, ...props}: TextProps) {
  return (
    <Element className="essential-text" {...props}>
      {!!label && label}
      {children}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.68px;
`;
