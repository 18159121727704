/**
 * @name LineButton
 * @description 버튼
 */
import {COLOR} from 'styles/color';
import React from 'react';
import styled from 'styled-components';
import {CSS} from 'types';
import Image from '../image/image';

type Props = {
  params?: {}
  children?: React.ReactNode
  icon?: any // 이미지 아이콘
  iconStyle?: CSS //----------------------------- 아이콘스타일
  color?: string //------------------------------ 텍스트색상
  bg?: string //--------------------------------- 배경색상
  radius?: number //----------------------------- 라운드처리
  borderColor?: string //------------------------ 테두리컬러
  className?: string //-------------------------- 클래스네임
  disabled?: boolean //-------------------------- 비활성화
  full?: boolean //------------------------------ full width
  label?: string //-------------------------------버튼명
  style?: CSS //--------------------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
};
export default function LineButton({params, icon, iconStyle, children, borderColor, radius, bg, color, full, className, disabled, label, style, onClick, ...props}: Props) {
  // hooks
  const _full = full && {width: '100%'};
  const _bg = bg && {backgroundColor: bg};
  const _color = color && {color: color};
  const _borderColor = borderColor && {borderColor: borderColor};
  const _radius = radius && {borderRadius: radius};
  const _style = Object.assign({}, _full, _radius, _borderColor, _color, _bg, !!style && style);
  const _iconStyle = Object.assign({}, {width: 20, height: 20, marginRight: 6}, !!iconStyle && iconStyle);
  //*--------------------------------------------------*
  return (
    <Element
      {...props}
      className={className}
      disabled={disabled}
      style={_style}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        !!onClick && onClick(Object.assign({}, params, event));
      }}>
      {children}
      {!!icon && <Image src={icon} style={_iconStyle} />}
      {!!label && label}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.button`
  /* display: inline-block; */
  display: flex;
  border: 1px solid ${COLOR.BLUE3};
  border-radius: 8px;
  padding: 8px 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${COLOR.BLUE3};
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -3.5%;
  background-color: ${COLOR.WHITE};
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
    /* background-color: ${COLOR.BLUE2}; */
  }
  &:active {
    filter: brightness(0.9);
    /* filter: contrast(150%); */
    /* background-color: ${COLOR.BLUE0}; */
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${COLOR.BLUE_GRAY8};
  }
`;
/********************************************************
[사용법]
 <LineButton
  style={{marginRight: 16}}
  label="장애코드 업로드 내역 보기"
  onClick={() => {
    alert('test')
  }}
/>
*********************************************************/
