/**
 * @name 사이트계통구성도장치(타이틀)
 * @description
 */
import {del_actived} from 'assets/svg';
import {Flex, Image, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';

type Props = {
  title: string;
  onSelected?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const Contents = ({title, onSelected, onDelete}: Props): JSX.Element => {
  return (
    <Content>
      <button
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          onSelected?.(event);
        }}
        className="item">
        <Text
          mode="Body_LM"
          label={title}
          style={{width: 300, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
        />
        <span
          className="deleteBtn"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            onDelete?.(event);
          }}>
          <Image src={del_actived} width={14} height={14} />
        </span>
      </button>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  button.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    padding: 8px 24px 8px;
    color: ${Color.gray30};
    &:hover {
      color: ${Color.blue10};
      background-color: ${Color.blue80};
    }
  }
  /* deleteBtn */
  .deleteBtn {
    padding: 10px;
    /* border: 1px solid ${Color.gray70}; */
  }
`;
