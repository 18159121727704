/**
 * @name Test
 * @description
 */
import styled from 'styled-components';
import {App} from 'template';
import {Code1} from './contents';
// contents

export default function Index() {
  //*--------------------------------------------------*
  return (
    <App padding={0}>
      <Content>
        {/* <Code2 /> */}
        <Code1 />
      </Content>
    </App>
  );
}
//*--------------------------------------------------*
const Content = styled.div``;
