/**
 * @name 그리드아이템>모듈
 * @description
 */
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {siteBanksSiteModuleProps} from 'pages/site-manage/model/type.system';
// contents
import Facility from './facility';
import TitleList from './item-title-list';
import {useAddDeviceMutation, useDeleteDeviceMutation, useGridFacilities} from 'pages/site-manage/hooks/useDeviceQuery';
import {useSystemStore} from 'pages/site-manage/model';
import {useQueryClient} from '@tanstack/react-query';

type Props = {
  parentNo: number;
  isOpen?: boolean;
  data: siteBanksSiteModuleProps;
  isChild?: boolean;
};
const Contents = ({data, parentNo, isOpen, isChild}: Props): JSX.Element => {
  const queryClient = useQueryClient();
  const {siteBankNo} = useSystemStore();
  const [mode, setMode] = useState<string>(''); // [추가]모드[설비, 모듈
  const [siteDeviceNo, setSiteDeviceNo] = useState<number>(0); // 사이트장치번호
  // query
  const {data: dataFacilities, refetch: refetchFacilities} = useGridFacilities(siteDeviceNo);
  // 커스텀 훅 사용
  const {mutate: mutateAddDevice} = useAddDeviceMutation();
  const {mutate: mutateDeleteDevice} = useDeleteDeviceMutation();

  useEffect(() => {
    if (siteDeviceNo !== undefined && siteDeviceNo !== 0) {
      queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_facilities']});
      queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_devices']});
      queryClient.invalidateQueries({queryKey: ['grid_devices']});
      refetchFacilities();
    }
    return () => {};
  }, [siteDeviceNo]);

  return (
    <Content>
      {/* <span style={{position: 'absolute', left: 20, top: 0, zIndex: 10, fontSize: 14, color: 'orange'}}>
        [{parentNo + '  /  ' + data?.siteDeviceNo}]
      </span> */}
      <TitleList
        title={data.name}
        isChild={isChild}
        isOpen={isOpen}
        type={'module'}
        onClick={(type) => {
          setMode(type);
          // queryClient.invalidateQueries({queryKey: ['grid_devices']});
          // queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_devices']});
          if (type === 'add') {
            mutateAddDevice({siteDeviceNo: data?.siteDeviceNo, siteBankNo: siteBankNo, parentNo: parentNo});
          } else if (type === 'close') {
            mutateDeleteDevice({siteDeviceNo: data?.siteDeviceNo});
          } else if (type === 'facility') {
            // console.log(data?.siteDeviceNo);
            setSiteDeviceNo(data?.siteDeviceNo);
            // refetchFacilities();
          }
          // queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_facilities']});
          // queryClient.invalidateQueries({queryKey: ['sites_siteId_grid_devices']});
          // queryClient.invalidateQueries({queryKey: ['grid_devices']});
        }}
      />
      {/*========= 계통구성도에 포함된 모듈내의 설비 */}
      {/* {debug(data?.siteFacilities)} */}
      {data?.siteFacilities?.map((item, index) => {
        return (
          <Facility
            isChild={true}
            parentNo={data?.siteDeviceNo}
            data={item}
            key={`Facility_${item.name}_${index}`}
            isOpen={true}
          />
        );
      })}
      {/* {siteDeviceNo + ''} */}
      {/*====== [+추가] 이후설비 */}
      {dataFacilities &&
        dataFacilities?.length > 0 &&
        // selectDeviceNo === index &&
        mode === 'facility' &&
        dataFacilities?.map((item, index) => {
          if (data?.siteFacilities.some((obj) => obj.name === item.name)) return null;
          return <Facility parentNo={siteDeviceNo} data={item} key={`${item.name}_${index}`} isOpen={false} />;
        })}
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  position: relative;
  padding-left: 24px;
`;
