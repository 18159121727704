/**
 * @name Container
 */
import {useCallback, useEffect} from 'react';
import {useAppDispatch} from 'model';
import {useAxios, useRoutes} from 'lib';
import {MENU} from 'config';
// contents
import Presenter from './presenter';
import {signOut} from 'pages/common';

export default function Container() {
  // hooks
  const {post} = useAxios();
  const dispatch = useAppDispatch();
  const {navigate} = useRoutes();
  //*--------------------------------------------------*
  // 로그아웃,초기화
  const onLogout = useCallback(async () => {
    await signOut();
    navigate(MENU.HOME);

    // const res = await post(`${API}/test`, {
    //   username: 'test',
    //   password: 'test',
    // })
    // if (res?.status === 'ok') {
    //   console.log(`%cLOGOUT `, `display:block;width:100%;padding:2px 10px;font-size:12px;background-color:blue;color:white;`)
    //   dispatch(gnbAction.setInit())
    //   navigate(MENU.SIGN_IN)
    // }

    // if (!res) {
    //   log('로그아웃')
    // }
  }, [dispatch, navigate, post]);

  //*--------------------------------------------------*
  useEffect(() => {
    // 초기화
    onLogout();
    return () => {};
  }, [onLogout]);

  return <Presenter />;
}
