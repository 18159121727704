/**
 * @name Input
 * @description 입력창
 */
import React from 'react';
import styled from 'styled-components';
import {COLOR} from 'styles';

type Props = {
  children?: React.ReactNode //--------------------Children
  label?: string //------------------------------- Text
  style?: React.CSSProperties //------------------ StyleSheet
  placeholder?: string //------------------------- placeholder
  onSubmit?: (str: string) => void //------------- onSubmit
  setValue?: (str: string) => void //------------- setValue
  onChange?: (event: string) => void //-------------- onChange Event
  [key: string]: any
};
export default function Textarea({children, onChange, setValue, onSubmit, label, ...props}: Props) {
  return (
    <Element
      {...props}
      onChange={(event: any) => {
        !!setValue && setValue(event.target.value);
        !!onChange && onChange(event.target.value);
      }}
      onKeyUp={(event: any) => {
        // ENTER키적용
        if (event.key === 'Enter') {
          !!onSubmit && onSubmit(event.currentTarget.value);
        }
      }}
    />
  );
}
//*--------------------------------------------------*
const Element = styled.textarea`
  /* input클래스적용시 기본스타일작동 */
  display: flex;
  width: 100%;
  min-width: 300px;
  min-height: 128px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${COLOR.GRAY4};
  outline: 0;
  /* 마우스호버 */
  &:hover {
    border-color: ${COLOR.GRAY1};
  }
  /* 포커스인 */
  &:focus {
    border-color: ${COLOR.BLUE3};
    outline: 0;
    outline-color: ${COLOR.BLUE3};
  }
  /* 비활성화 */
  &:disabled {
    color: ${COLOR.GRAY3};
    border-color: ${COLOR.GRAY6};
    background-color: ${COLOR.GRAY6};
  }
  /* 유효성검사 */
  &.invalid {
    border-color: ${COLOR.RED1} !important;
  }
  /* 플레이스홀더 */
  &::placeholder {
    color: ${COLOR.GRAY2};
  }
`;
/********************************************************
[사용법]
<Textarea
  style={{marginBottom: 30}}
  className="input"
  value={text}
  setValue={setText}
  placeholder="입력해주세요."
  onChange={str => {
    console.log(str)
  }}
/>
*********************************************************/
