/**
 * @name Switch
 * @description track,cursor
 */
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {COLOR} from 'styles';
import {useCallback} from 'react';
import {ClassName} from 'types';

type Props = {
  isBool: boolean
  setIsBool: (isBool: boolean) => void
  className?: ClassName //------------------------ ClassName
  style?: React.CSSProperties //------------------ StyleSheet
  //
  width?: string
  height?: string
};
// 트랙
type TrackProps = {
  width?: string
  height?: string
};
// 트랙
type CursorProps = {
  width?: string
  height?: string
};
export default function Switch({isBool, setIsBool, width, height, className, style}: Props) {
  // hooks
  const cursorGap = '1px';
  const toggleSwitch = useCallback(() => {
    setIsBool(!isBool);
  }, [isBool, setIsBool]);
  //*--------------------------------------------------*
  return (
    <Element onClick={toggleSwitch} className={className} style={style}>
      <Track className="track" width={width} height={height} animate={{backgroundColor: isBool ? COLOR.GREEN1 : COLOR.BLUE2}} />
      <Cursor
        className="cursor"
        height={height}
        animate={{
          left: isBool ? cursorGap : 'auto',
          right: isBool ? 'auto' : cursorGap,
        }}
      />
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.button`
  position: relative;
`;
const Track = styled(motion.div)<TrackProps>`
  position: relative;
  width: ${({width}) => width || '46px'};
  height: ${({height}) => height || '26px'};
  border: 0;
  border-radius: ${({width}) => width || '46px'};
  background-color: black;
`;
const Cursor = styled(motion.div)<CursorProps>`
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 1px;
  width: ${({height}) => (height ? `calc(${height} - 2px)` : '24px')};
  height: ${({height}) => (height ? `calc(${height} - 2px)` : '24px')};
  padding: 0;
  border-radius: 50%;
  background-color: #fff;
`;
/********************************************************
[사용법]
  <Switch style={{marginRight: 20}} width="100px" height="50px" isBool={isBool} setIsBool={setIsBool} />
  <Switch isBool={isBool} setIsBool={setIsBool} />
*********************************************************/
