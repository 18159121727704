/**
 * @name H3
 * @description H3
 */

import styled from 'styled-components';
import {TextProps} from 'types';

export default function H3({children, style, label, ...props}: TextProps) {
  return (
    <Element className="essential-text" {...props}>
      {!!label && label}
      {children}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.h3`
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.6px;
`;
