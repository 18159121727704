/**
 * @name 차트
 * @returns
 */
// query
import styled from 'styled-components';
import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import {siteBanksSiteDeviceProps} from 'pages/site-manage/model/type.system';
import {Color} from 'styles';

type Props = {
  data: siteBanksSiteDeviceProps;
};
interface DeviceProps {
  name: string;
  type: string;
  children?: DeviceProps[]; // 재귀적으로 자식을 포함할 수 있습니다.
  siteFacilities?: DeviceProps[]; // siteFacilities도 같은 구조를 가질 수 있다고 가정
}

interface Node {
  name: string;
  itemStyle: {
    color: string;
  };
  children?: Node[];
}

const Contents = ({data}: {data: DeviceProps}): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);
  const transformData = (data: DeviceProps, depth: number = 0) => {
    const colors = [Color.green40, Color.yellow40, Color.blue40]; // 깊이에 따른 색상

    let node: Node = {
      name: data.name,
      itemStyle: {
        color: colors[depth] || colors[colors.length - 1]
      },
      children: [] // 여기에서 children을 빈 배열로 초기화합니다.
    };

    // 자식 노드가 있을 경우 처리
    if (data.children && data.children.length > 0) {
      node.children = data.children.map((child) => transformData(child, depth + 1));
      // 모듈 유형의 노드에 대해서만 가상 노드 추가
      if (data.type === 'module') {
        // 가상 노드를 추가하여 모듈과 설비 사이의 시각적 간격을 만듭니다.
        node.children.push({
          name: '가상 노드', // 가상 노드의 이름, 필요에 따라 변경 가능
          itemStyle: {
            color: '#ff0000' // 가상 노드의 색상 설정
          }
        });
      }
    }

    // 설비 노드 처리
    if (data.siteFacilities && data.siteFacilities.length > 0) {
      const facilitiesAsChildren = data.siteFacilities.map((facility) => {
        let facilityNode = transformData(facility, depth + 1);
        facilityNode.itemStyle.color = Color.blue40; // 설비 노드의 색상을 파란색으로 지정
        return facilityNode;
      });
      node.children = [...(node.children || []), ...facilitiesAsChildren];
    }
    // 만약 모듈 아래에 설비가 없고, 모듈이 중간 계층에 위치해야 한다면, 다음과 같은 조건을 추가할 수 있습니다.
    // if (data.type === 'module' && node?.children?.length === 0) {
    //   node.children.push({
    //     name: '',
    //     itemStyle: {
    //       color: 'transparent'
    //     }
    //   });
    // }

    return node;
  };
  const _data = transformData(data); // 변환 함수를 사용하여 데이터 변환

  useEffect(() => {
    // console.log(data);
  }, []);
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      const option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            data: [_data],
            top: '10%',
            left: '16%',
            bottom: '1%',
            right: '19%',
            symbol: 'circle',
            symbolSize: 14,
            expandAndCollapse: false, // 노드 확장/축소 허용
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left',
              FontFace: 'Pretendard',
              fontSize: 14,
              color: Color.gray10,
              backgroundColor: Color.white
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            emphasis: {
              focus: 'descendant'
            },
            lineStyle: {
              //# 변경
              color: Color.gray60,
              width: 1, // 연결선의 너비
              type: 'solid' // 연결선의 유형 (solid: 실선)
            }
            // animationDuration: 550,
            // animationDurationUpdate: 750
          }
        ]
      };
      chart.setOption(option);

      return () => {
        chart.dispose(); // 컴포넌트가 unmount되면 ECharts 인스턴스를 해제합니다.
      };
    }
  }, [data]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      const option = {
        // 옵션 설정
      };
      chart.setOption(option);
      window.onresize = function () {
        chart.resize();
      };
      return () => {
        window.onresize = null;
        chart.dispose(); // 컴포넌트가 unmount되면 ECharts 인스턴스를 해제합니다.
      };
    }
  }, [data]);
  return (
    <Content>
      <div ref={chartRef} style={{width: '100%', height: '150px'}} />
    </Content>
  );
};

export default Contents;

const Content = styled.div``;
