/**
 * @name Contents
 * @API https://api-cebs-dev.acelogrid.com/docs#/%EC%82%AC%EC%9D%B4%ED%8A%B8%20API
 * @description
 */
import styled from 'styled-components';
// contents
import Header from './header';
import Main from './main';

const Contents: React.FC = () => {
  return (
    <Content>
      {/* 헤더영역 */}
      <Header />
      {/* 메인 */}
      <Main />
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 400px;
`;
