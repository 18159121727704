/**
 * @name Pager
 * @description
 */
import {Flex, Image, Text} from 'lib';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  arrow_next,
  arrow_next_disabled,
  arrow_next_hover,
  arrow_prev,
  arrow_prev_disabled,
  arrow_prev_hover
} from 'assets/svg';
import {SitesResponseType} from 'pages/site-manage/model/type';
import {useSiteListStore} from 'pages/site-manage/model';
// contents
type ArrowProps = 'default' | 'hover' | 'disabled';

const Contents = ({meta}: SitesResponseType): JSX.Element => {
  // hooks
  const {currentPage, setCurrentPage} = useSiteListStore((state) => state);
  const [prev, setPrev] = useState<ArrowProps>(meta?.hasPrevPage ? 'default' : 'disabled');
  const [next, setNext] = useState<ArrowProps>(meta?.hasNextPage ? 'default' : 'disabled');

  useEffect(() => {
    setPrev(meta?.hasPrevPage ? 'default' : 'disabled');
    setNext(meta?.hasNextPage ? 'default' : 'disabled');
  }, [currentPage]);
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex.Center>
        {/* 페이저 */}
        <Flex.Center style={{height: 40, marginLeft: 4, marginRight: 4}}>
          <Text mode="Btn_M" label={`${meta?.totalDocs}개 중`} style={{marginRight: 12}} />
          <Text mode="Btn_M" label={`${meta?.minIdx} - ${meta?.maxIdx}`} />
        </Flex.Center>
        <Flex.Center>
          {/* 이전 */}
          <button
            onClick={() => {
              if (prev === 'disabled') return;
              if (currentPage <= 1) return;
              setCurrentPage(currentPage - 1);
            }}
            className="prev"
            onMouseLeave={() => prev !== 'disabled' && setPrev('default')}
            onMouseEnter={() => prev !== 'disabled' && setPrev('hover')}>
            <Image
              src={prev === 'disabled' ? arrow_prev_disabled : prev === 'hover' ? arrow_prev_hover : arrow_prev}
              width={24}
              height={24}
            />
          </button>
          {/* 다음 */}
          <button
            onClick={() => {
              if (next === 'disabled') return;
              if (meta && currentPage >= meta?.totalPages) return;
              setCurrentPage(currentPage + 1);
            }}
            className="next"
            onMouseLeave={() => next !== 'disabled' && setNext('default')}
            onMouseEnter={() => next !== 'disabled' && setNext('hover')}>
            <Image
              src={next === 'disabled' ? arrow_next_disabled : next === 'hover' ? arrow_next_hover : arrow_next}
              width={24}
              height={24}
            />
          </button>
        </Flex.Center>
      </Flex.Center>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  position: absolute;
  /**-------본문--*/
  button.prev,
  button.next {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
`;
