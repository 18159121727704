/**
 * @name 콤마찍기
 * @returns boolean
 */
export const addComma = (num: any, fractionDigits: number = 2): string => {
  try {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: fractionDigits, // 최소 소수점 자릿수
      maximumFractionDigits: fractionDigits, // 최대 소수점 자릿수
    }).format(num);
  } catch (e) {
    return String(num);
  }
};
