/**
 * @name 장치구성요소,취소,추가
 * @description
 */
import styled from 'styled-components';
import {Flex, Text} from 'lib';
import {Color} from 'styles';
import {useSiteAddStore} from 'pages/site-manage/model';

type Props = {
  onSubmit: () => void;
};

const Contents = ({onSubmit}: Props): JSX.Element => {
  const {setItemSelected} = useSiteAddStore();

  return (
    <Content>
      <Flex className="control">
        <button
          style={{marginRight: 8}}
          className="cancel"
          onClick={() => {
            setItemSelected(null);
          }}>
          <Text label="취소" color={Color.blue30} />
        </button>
        <button
          style={{marginLeft: 8}}
          className={`submit`}
          onClick={() => {
            onSubmit?.();
          }}>
          <Text label="추가" color={Color.white} />
        </button>
      </Flex>
    </Content>
  );
};
export default Contents;

const Content = styled.section`
  /* control */
  .control {
    display: flex;
    padding: 24px 0;
    justify-content: flex-end;
    button {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 8px;
      &.cancel {
        border: 1px solid ${Color.blue30};
      }
      &.submit {
        background-color: ${Color.blue30};
      }
    }
  }
`;
