/**
 * @name index.tsx
 */
import ReactDOM from 'react-dom/client';
// redux,utils
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from './model';
// styles
import './styles/scss/root.scss';
import './styles/scss/init.scss';
import './styles/scss/font.scss';
import './styles/scss/text.scss';
import './styles/scss/common.scss';
import './styles/scss/ui.scss';
// contents
import App from './pages/app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
      {/*-- react-query devtools --*/}
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={true} />}
    </Provider>
  </QueryClientProvider>
);
reportWebVitals();
