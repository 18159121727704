/**
 * @name 파일보기/파일업로드/파일다운로드/파일삭제
 * @description
 */

import {Image, Text} from 'lib';
import {useState} from 'react';
import styled from 'styled-components';
import {Color} from 'styles';
import {
  csv_default,
  csv_disabled,
  csv_hover,
  del_default,
  del_disabled,
  del_hover,
  download_default,
  download_disabled,
  download_hover,
  upload_default,
  upload_disabled,
  upload_hover
} from 'assets/svg';
// contents

type Props = {
  mode?: 'EDIT' | 'LIST' | 'ADD';
  type?: 'map' | 'statusMap';
  status: boolean;
  onClick: (mode: string) => void;
};
export default function Contents({status, type, mode, onClick}: Props) {
  // hooks
  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 파일보기 */}
      {mode === 'LIST' && (
        <button
          onMouseEnter={() => setStatus1(true)}
          onMouseLeave={() => setStatus1(false)}
          className={`item`}
          onClick={() => {
            !!onClick && onClick('view');
          }}>
          <Image src={status1 ? csv_hover : csv_default} />
          <Text mode="Body_MR" label="파일보기" />
        </button>
      )}
      {/* 파일업로드 */}
      {(mode === 'EDIT' || mode === 'ADD') && (
        <button
          onMouseEnter={() => setStatus2(true)}
          onMouseLeave={() => setStatus2(false)}
          className={`item`}
          onClick={() => {
            !!onClick && onClick('upload');
          }}>
          <Image src={status2 ? upload_hover : upload_default} />
          <Text mode="Body_MR" label="파일 업로드" />
        </button>
      )}
      {/* 파일다운로드 */}
      {mode === 'LIST' && (
        <button
          onMouseEnter={() => setStatus3(true)}
          onMouseLeave={() => setStatus3(false)}
          onClick={() => {
            !!onClick && onClick('download');
          }}
          className={`item`}>
          <Image src={status3 ? download_hover : download_default} />
          <Text mode="Body_MR" label="파일 다운로드" />
        </button>
      )}
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  position: absolute;
  z-index: 10;
  top: 44px;
  width: 190px;
  /* height: 188px; */
  padding: 8px;
  border-radius: 6px;
  background: ${Color.white};
  /* drop_shadow */
  box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
  /**-------본문--*/
  button.item {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    border-radius: 6px;
    padding: 8px 16px;
    align-items: center;
    &.disabled {
      background: ${Color.white} !important;
      span {
        color: ${Color.gray70} !important;
      }
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    &:hover {
      background: ${Color.blue100};
      span {
        color: ${Color.blue40};
      }
    }
    &:active {
      background: ${Color.blue90};
      span {
        color: ${Color.blue30};
      }
    }
  }
`;
