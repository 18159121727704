/**
 * @name List
 * @description 리스트 나열
 */
import {uuid} from 'lib';
import React, {useState} from 'react';
import styled from 'styled-components';
import {COLOR} from 'styles';
import {CSS, ClassName, RenderItem} from 'types';

type Props = {
  className?: ClassName
  info?: any[]
  selected?: number
  onChange?: (obj: any) => void
  renderItem?: RenderItem
  style?: CSS
};
export default function Tab({className, selected, style, onChange, renderItem, info}: Props) {
  // const
  const _info = info ?? ['일', '주', '달', '년'];
  const _style = Object.assign({}, style);
  const [select, setSelect] = useState(selected ?? 0);
  //*--------------------------------------------------*
  const makeElement = <T extends string>(value?: T) => {
    return _info.map((item, index) => {
      return (
        <button
          className={`item ${select === index ? 'active' : ''}`}
          key={uuid()}
          onClick={() => {
            setSelect(index);
            !!onChange && onChange({item: item, index: index});
          }}>
          <label className="body4">{item}</label>
        </button>
      );
    });
  };
  //*--------------------------------------------------*
  return (
    <Element style={_style} className={className}>
      {/* renderItem없을경우디버그 */}
      {renderItem === undefined && makeElement()}
      {!!renderItem &&
        _info.map((item, index) => {
          return (
            // <React.Fragment key={uuid()}>{!!renderItem && renderItem({item: item, index: index})}</React.Fragment>
            <button
              key={uuid()}
              className={`${select === index ? 'active' : ''}`}
              onClick={() => {
                setSelect(index);
                !!onChange && onChange({item: item, index: index});
              }}>
              {item}
            </button>
          );
        })}
    </Element>
  );
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  flex-direction: row;
  /* renderItem없을경우기본디자인 */
  .item {
    position: relative;
    display: flex;
    flex: 1;
    /* margin-left: 1px;
    margin-right: 1px; */
    padding: 8px 0;
    align-items: center;
    justify-content: center;
    justify-content: 'center';
    background-color: ${COLOR.WHITE};
    border-bottom: 1px solid ${COLOR.GRAY5};
    label {
      color: ${COLOR.GRAY3};
    }
    &.active label {
      color: ${COLOR.BLUE3};
    }
    &.active:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-bottom: 2px solid ${COLOR.BLUE3};
    }
  }
`;
/********************************************************
[사용법]


  <Content>
    <Tab
      className="tab"
      info={['일', '주', '달', '년']}
      onChange={({item, index}) => {
        console.log([item, index])
      }}
      renderItem={({item, index}) => <span>{item}</span>}
    />
  </Content>


const Content = styled.div`
  .tab {
    width: 80%;
    margin: 0 auto;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 10px;
    overflow: hidden;
    > button {
      display: flex;
      flex: 1;
      padding: 10px 0;
      align-items: center;
      justify-content: center;
      background-color: ${COLOR.WHITE};
      border-left: 1px solid ${COLOR.GRAY4};
      color: ${COLOR.GRAY1};
      font-size: 14px;
      &:first-child {
        border-left: 0;
      }
      &.active {
        color: ${COLOR.WHITE};
        background-color: ${COLOR.BLUE3};
      }
    }
  }
`
*********************************************************/
