/**
 * @name 그리드아이템>타이틀리스트
 * @description
 */
import {useState} from 'react';
import {Flex, Text} from 'lib';
import styled from 'styled-components';
import {Color} from 'styles';
// contents
import {Mark} from '../../../../common';
import Add, {ModeType} from './item-add';
import Indent from './item-indent';

type Props = {
  type: 'device' | 'module' | 'facility' | 'add' | 'close';
  title?: string;
  isOpen?: boolean;
  isChild?: boolean; // 연결선(꺽쇠)
  onClick?: (mode: ModeType) => void;
};
const Contents = ({type, title, onClick, isChild, isOpen}: Props): JSX.Element => {
  // 최대 글자수 제한
  const MAX_LENGTH = 20;
  // 텍스트 길이가 최대 글자수를 초과하는 경우 말줄임 처리
  const ellipsizedTitle = title && title.length > MAX_LENGTH ? `${title.substring(0, MAX_LENGTH)}...` : title;

  return (
    <Content>
      <Flex.SpaceBetween className="item">
        {/* 텍스트영역 */}
        <Flex.Row alignItems="center" style={{height: 24}}>
          {/* {isOpen && <Indent gap={48} />} */}
          {type === 'module' && isChild && <Indent gap={-20} />}
          {type === 'facility' && isChild && <Indent gap={-20} />}
          <Mark type={type} />
          <Text className="text" mode="Body_LM" label={ellipsizedTitle || '타이틀'} color={Color.gray30} />
        </Flex.Row>
        {/* 추가|모듈,설비추가 */}
        <Add
          mode={type}
          isOpen={isOpen}
          onClick={(type) => {
            onClick && onClick(type);
          }}
        />
      </Flex.SpaceBetween>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 48px; */
  /* item */
  .item {
    position: relative;
    width: 100%;
    padding: 12px 0;
  }
  .text {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
