/**
 * @name 타이틀+(텍스트필드)
 * @description
 */
import styled from 'styled-components';
import {Form, Text} from 'lib';
import {Color} from 'styles';
import {useEffect, useState} from 'react';

type Props = {
  mode?: 'NOT_KOR' | 'NUMBER';
  value?: string;
  name?: string;
  title?: string;
  required?: boolean;
  placeholder?: string;
  onChange?: (e: any) => void;
};
const Contents = ({mode, title, onChange, required, value, placeholder, name}: Props): JSX.Element => {
  const [_value, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <Content>
      <Text style={{marginBottom: 8}} label={title || '타이틀'} color={Color.gray50} mode="Title_MB">
        {required && <Text style={{marginLeft: 3}} label={required ? '*' : ''} color={Color.red30} mode="Title_MB" />}
      </Text>
      <div className="component">
        {/* 컴포넌트없을때 기본Input */}
        <Form.Input
          name={name}
          style={{width: '100%'}}
          value={_value}
          placeholder={placeholder}
          onChange={({name, value}) => {
            //==== 한글막기
            if (mode === 'NOT_KOR') {
              const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
              if (regx.test(value)) return;
            }
            // 숫자만 입력 허용
            if (mode === 'NUMBER') {
              const regxNum = /^[0-9.:]*$/;
              if (!regxNum.test(value)) return;
            }

            setValue(value);
            onChange && onChange({name, value});
          }}
        />
      </div>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0;
  /* height: 56px; */
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* component */
  .component {
    display: flex;
    flex: 1;
    width: 100%;
    /* width: 100%; */
  }
`;
