/**
 * @name RoundedPanel
 * @description 라운드된패널
 */

import styled from 'styled-components';
import {COLOR} from 'styles';
import {TextProps} from 'types';

export default function RoundedPanel({children, ...props}: TextProps) {
  return <Element {...props}>{children}</Element>;
}
//*--------------------------------------------------*
const Element = styled.div`
  padding: 16px;
  border-radius: 4px;
  /* transition: all 0.3s cubic-bezier(0.8, 0.07, 0.31, 1); */
  /* font-family: Roboto, Helvetica, Arial, sans-serif; */
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: ${COLOR.WHITE};
`;
/********************************************************
[사용법]
  <RoundPanel style={{background: randomColor()}}>
    {index + 1} _ {item.title}
  </RoundPanel>
*********************************************************/
