/**
 * @name TotalCount
 * @description
 */
import styled from 'styled-components';
import {COLOR} from 'styles';
import {H1, H6, Row, addComma} from 'lib';
import {CSS} from 'types';

type Props = {
  value?: number | string | undefined | any //---------------------------- 카운트
  suffix?: string //--------------------------- 프리픽스
  comma?: boolean
  titleColor?: string
  style?: CSS
};
export default function TotalCount({style, value, suffix, comma, titleColor}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content style={style}>
      <Row style={{justifyContent: 'flex-end'}}>
        <H1 color={titleColor}>{comma ? addComma(value ?? '0') : value}</H1>
        <H6 style={{marginTop: 14}} color={COLOR.GRAY2}>
          {suffix ?? '원'}
        </H6>
      </Row>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    display: block;
    margin-right: 4px;
    padding-bottom: 10px;
    color: ${COLOR.BLUE3};
  }
`;

/**

  <TotalCount comma value={period?.calc_type?.total_charge_amount} />

 */
