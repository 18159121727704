/**
 * @name GNB
 */
import {Color} from 'styles';
import {gnbAction} from 'model/modules';
import {GnbMenuType} from 'types';
import {Image, List, Row, Text, useRoutes} from 'lib';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {useAppDispatch, useAppSelector} from 'model';
import React, {useState} from 'react';
import {gnb_item, gnb_item_active, gnb_item_hover} from 'assets/svg';
// contents

type Props = {
  item: GnbMenuType;
  depth1?: number;
  index: number;
};
export default function GnbDepth2Menu({item, depth1, index}: Props): JSX.Element {
  // hooks
  const dispatch = useAppDispatch();
  const {navigate} = useRoutes();
  const {navi} = useAppSelector((state) => state.gnb);
  const [isHover, setIsHover] = useState(false);

  //*--------------------------------------------------*
  return (
    <Content>
      <motion.div
        className="children"
        exit={{height: 0}}
        animate={{height: 'auto'}}
        transition={{duration: 0.18, ease: 'easeInOut'}}>
        <List
          info={item?.children}
          renderItem={({item, index}) => {
            const {name, path} = item;
            if (item?.visible === false) return null;
            return (
              <button
                className={`menu ${navi?.depth1 === depth1 && navi?.depth2 === index && 'active'}`}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                  dispatch(gnbAction.setNavi({depth2: index}));
                  navigate(path);
                }}>
                <Row className="wrap">
                  <span style={{width: 40, textAlign: 'left'}}>
                    {navi?.depth1 === depth1 && navi?.depth2 === index ? (
                      <Image src={gnb_item_active} width={24} height={24} />
                    ) : (
                      <React.Fragment>
                        {!isHover && <Image src={gnb_item} width={24} height={24} />}
                        {isHover && <Image src={gnb_item_hover} width={24} height={24} />}
                      </React.Fragment>
                    )}
                  </span>
                  <Text mode="Btn_L" label={item?.name} />
                </Row>
              </button>
            );
          }}
        />
      </motion.div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.section`
  /* 1depth,2depth메뉴 */
  /* margin: 0 16px; */
  margin-bottom: 4px;
  button {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    padding-left: 8px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    color: ${Color.gray40};
    &:hover {
      color: ${Color.gray20};
      background-color: ${Color.blue100};
    }
    &:active {
      color: ${Color.blue30};
      background-color: ${Color.blue90};
    }
    /* 활성화 */
    &.active {
      color: ${Color.blue30};
      background-color: ${Color.blue90};
    }
  }
  /* children */
  .children {
    overflow: hidden;
  }
`;
