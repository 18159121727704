/**
 * @name 테이블리스트
 * @description
 */
import React, {useEffect, useRef, useState} from 'react';
import {Events, Form, Text} from 'lib';
import {Color} from 'styles';
// contents
import {_event} from '..';

type Props = {
  item: any
  index: number
};
export default function Contents({item, index}: Props) {
  // hooks
  const nameRef = useRef(null) as any; // 장비명
  const descRef = useRef(null) as any; // 설명
  const [isModify, setIsModify] = useState(false);
  //*--------------------------------------------------*
  const onCancel = () => {
    setIsModify(false);
  };

  useEffect(() => {
    nameRef.current = item?.name;
    descRef.current = item?.description;
    // addListener
    Events.addListener(_event.CANCEL, onCancel);
    return () => {
      // removeListener
      Events.removeListener(_event.CANCEL, onCancel);
    };
  }, []);
  //*--------------------------------------------------*
  return (
    <React.Fragment key={`${item}_${index}`}>
      {isModify ? (
        <tr>
          <td>{item?.facilityId}</td>
          <td>
            <Form.Input
              placeholder="설비명을 입력하세요."
              defaultValue={item?.name}
              style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}
              onChange={({value}) => {
                nameRef.current = value;
              }}
            />
          </td>
          <td>
            <Form.Input
              placeholder="설명을 입력하세요."
              defaultValue={item?.description}
              style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}
              onChange={({value}) => {
                descRef.current = value;
              }}
            />
          </td>
          <td></td>
          <td>
            <button
              style={{marginRight: 8}}
              onClick={() => {
                Events.emit(_event.MODIFY_SUBMIT, {id: item?.facilityId, name: nameRef.current, description: descRef.current});
                setIsModify(false);
              }}>
              <Text color={Color.blue30} mode="Btn_M" label="저장" />
            </button>
            <button
              style={{marginRight: 8}}
              onClick={() => {
                setIsModify(false);
              }}>
              <Text color={Color.gray30} mode="Btn_M" label="취소" />
            </button>
          </td>
        </tr>
      ) : (
        <tr>
          <td>{item?.facilityId}</td>
          <td>{item?.name}</td>
          <td>{item?.description}</td>
          <td></td>
          <td>
            <button
              onClick={() => {
                setIsModify(true);
              }}>
              <Text mode="Btn_M" label="편집" color={Color.blue30} />
            </button>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
