/**
 * @name Auth
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
export const sliceName = 'auth';
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  googleToken: string | null
  isLogin: boolean | null
  info: {
    email: string
    id: string
    username: string
  } | null
  [key: string]: any
};
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  isLogin: false,
  googleToken: null,
  info: null,
};
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 로그인
    setLogin(state: StateType, action: PayloadAction<any>) {
      state.isLogin = action.payload;
    },
    // 구글로그인
    setGoogleToken(state: StateType, action: PayloadAction<any>) {
      state.googleToken = action.payload;
    },
    // 사용자정보
    setInfo(state: StateType, action: PayloadAction<any>) {
      state.info = action.payload;
    },
  },
});
//*--------------------------------------------------*
// export
export const EVENT = {
  // 구글로그인
  GOOGLE_SIGN_IN: 'auth/GOOGLE_SIGN_IN',
  DASHBOARD: 'auth/DASHBOARD',
};
// 레이어팝업이많을경우분기처리
export const POPUP = {};
export default slice.reducer;
export const nameSpace = sliceName;
export const actions = slice.actions;
