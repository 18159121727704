/**
 * @name 대시보드
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// 모드변경 _mode
export const MODE = {
  DEFAULT: 'DEFAULT',
  LIST: 'LIST',
};
// 팝업 _popup
export const POPUP = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS', //----------- 등록성공
};
// export
export const EVENT = {
  // 날짜,충전소선택
  SUBMIT_FILTER: 'SUBMIT_FILTER',
  MAP_SELECT_STATION: 'MAP_SELECT_STATION',
  // 팝업
  SET_POPUP: 'SET_POPUP',
  REMOVE_POPUP: 'REMOVE_POPUP',
};

//*--------------------------------------------------*
/** @defineType */
type StateType = {
  // 시작,종료날짜
  period: {
    calc_type: 'REALTIME' | 'TERMS' | 'STATIONS' | null
    tz_start_date: null | string
    tz_end_date: null | string
    tz_compare_start_date: null | string
    tz_compare_end_date: null | string
    diff: null | number
  }
  filter_stations: any[] | null //충전소선택
  map_stations: any[] //지도
  // 그래프
  graph: {
    type: number //총매출,종합정보
    [key: string]: any
  }
  // 위젯
  widget: {
    total: {} // 매출총액
    type: {} // 충전소타입별매출
    count: {} // 이용자수
    hours: {} // 최고매출시간
    uses: {} // 충전기이용횟수
    troubles: {} // 장애
    [key: string]: any
  }
  //---
  info: any[]
  rank: any[]
  [key: string]: any
};
//*--------------------------------------------------*
/** @initialState */
export const sliceName = 'dashboard'; //변경필요
const initialState: StateType = {
  period: {
    calc_type: 'REALTIME',
    tz_start_date: null,
    tz_end_date: null,
    tz_compare_start_date: null,
    tz_compare_end_date: null,
    diff: null,
  },
  filter_stations: null,
  map_stations: [],
  graph: {
    type: 0,
  },
  widget: {
    total: {},
    type: {},
    count: {},
    hours: {},
    uses: {},
    troubles: {},
  },
  info: [],
  rank: [],
};
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 충전소선택
    setFilterStations(state: StateType, action: PayloadAction<any>) {
      state.filter_stations = action.payload;
    },
    // 충전소지도정보사전취득
    setMapStations(state: StateType, action: PayloadAction<any>) {
      state.map_stations = action.payload;
    },
    // setGraph
    setGraph(state: StateType, action: PayloadAction<any>) {
      state.graph = Object.assign(state.graph, action.payload);
    },
    // 통계유형,시작날짜,종료날짜,충전소선택
    setPeriod(state: StateType, action: PayloadAction<any>) {
      state.period = Object.assign(state.period, action.payload);
    },
    // 충전소매출랭킹
    setRank(state: StateType, action: PayloadAction<any>) {
      state.rank = action.payload;
    },
    // 위젯업데이트
    setWidget(state: StateType, action: PayloadAction<any>) {
      state.widget = Object.assign(state.widget, action.payload);
    },
  },
});
//*--------------------------------------------------*
export default slice.reducer;
export const nameSpace = sliceName;
export const actions = slice.actions;
