/**
 * @name API관련
 * @description
 */
import axios from 'axios';
import {API} from 'config';
import {SESSION_STORAGE, log} from 'lib';
import {authAction} from 'model/modules';

/**
 * @name 로그인실행(모듈화)
 * @description
 */
export async function signIn({
  token,
  dispatch,
  get,
  callback
}: {
  get?: any;
  token?: string;
  dispatch: any;
  callback?: any;
}) {
  const _token = !token ? await sessionStorage.getItem(SESSION_STORAGE.TOKEN) : token;
  const res = await signInComplete({token: _token, dispatch: dispatch, get: get});
  !!callback && callback();
  return {success: res};
}

/**
 * @name 로그인성공이후로직
 * @description 세션스토리지에 로그인정보를 저장한다.
 */
export async function signInComplete(payload: any) {
  console.log(
    `%csessionStorage settings`,
    `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:tomato;color:black;`
  );
  // 토근세션스토리지저장
  !!payload?.token && (await sessionStorage.setItem(SESSION_STORAGE.TOKEN, payload?.token));
  // Token redux 저장
  await payload.dispatch(authAction.setGoogleToken(payload?.token));
  log(`${payload?.token}`, 'color:orange;font-size:10px;');
  // 프로필정보업데이트
  const res = await axios.get(`${API}/auth/info`, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${payload?.token}`
    },
    timeout: 1000 * 10,
    withCredentials: false,
    method: 'GET',
    validateStatus: function (status: any) {
      return status >= 200 && status < 500; // 400번대 에러도 무시
    }
  });
  if (res?.data?.success) {
    const data = res?.data?.data;
    payload.dispatch(authAction.setInfo(data));
    console.log(
      `%c${data?.username}`,
      `display:block;width:100%;padding:2;font-size:12px;background-color:olive;color:black;`
    );
    console.log(
      `%c${data?.email}`,
      `display:block;width:100%;paddign:2;font-size:12px;background-color:green;color:black;`
    );
    return true;
  } else {
    return false;
  }
}
/**
 * @name 로그아웃성공이후로직
 * @description 세션스토리지에 초기화한다.
 */
export async function signOut(payload?: any) {
  console.log(
    `%csessionStorage clear`,
    `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:tomato;color:black;`
  );
  await sessionStorage.clear();
  return true;
}
